import { useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger } from '../../Global/UI/Select';
import { Input } from "../../Global/UI/Input";
import { Label } from "../../Global/UI/Label";

const PatientDetails = ({ onChange }) => {
    const [patientDetails, setPatientDetails] = useState({
        name: '',
        dob: '',
        gender: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        const updatedDetails = {
            ...patientDetails,
            [name]: value,
        };

        setPatientDetails(updatedDetails);
        onChange(updatedDetails);
    };

    const handleGenderChange = (gender) => {
        const updatedDetails = {
            ...patientDetails,
            gender: gender,
        };

        setPatientDetails(updatedDetails);
        onChange(updatedDetails);
    };
    const handleMobilityChange = (mobility) => {
        const updatedDetails = {
            ...patientDetails,
            mobility: mobility,
        };

        setPatientDetails(updatedDetails);
        onChange(updatedDetails);
    };

    return (
        <div>
            <p className='font-bold text-2xl mb-4'>Patient Details</p>
            <div className='grid gap-4 grid-cols-1 md:grid-cols-3 mb-4'>
                <div>
                    <Label htmlFor="name" className="block font-bold mb-2">Patient Name:</Label>
                    <Input
                        value={patientDetails.name}
                        onChange={handleChange}
                        type="text"
                        id="name"
                        name="name"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="dob" className="block font-bold mb-2">Patient DOB:</Label>
                    <Input
                        type="date"
                        id="dob"
                        name="dob"
                        onChange={handleChange}
                        value={patientDetails.dob}
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="gender" className="block font-bold mb-2">Patient Gender:</Label>
                    <Select value={patientDetails.gender} onValueChange={handleGenderChange}>
                        <SelectTrigger>{patientDetails.gender ? patientDetails.gender : "Select Gender"}</SelectTrigger>
                        <SelectContent>
                            <SelectItem key="male" value="Male">
                                Male
                            </SelectItem>
                            <SelectItem key="female" value="Female">
                                Female
                            </SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </div>
    );
};

export default PatientDetails;
