import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Screens/Settings/DASHBOARD_SETTINGS';
import { Input } from '../../Global/UI/Input';
import { Button } from '../../Global/UI/Button';
import { Select, SelectContent, SelectItem, SelectTrigger } from '../../Global/UI/Select';
import { toast } from 'react-toastify';

const AddContacts = () => {
    const [contacts, setContacts] = useState([]);
    const [contactData, setContactData] = useState({ organisation: '', emailAddress: '', phoneNumber: '' });
    const [selectedContact, setSelectedContact] = useState(null);

    const handleLoadContacts = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/settings/repatriation/contacts`);
            if (response.data.success === true) {
                console.log('Contacts', response.data.data)
                setContacts(response.data.data);
                toast.success('Contacts have been successfully fetched!');
            } else {
                toast.error('Failed to load contacts!');
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleEditContact = async () => {
        if (!selectedContact) return;
        try {
            const response = await axios.put(`${BASE_URL}/api/settings/repatriation/contacts/${selectedContact._id}`, contactData);
            const data = response.data;

            if (data.success === true) {
                const updatedContacts = contacts.map(contact => 
                    contact._id === selectedContact._id ? data.data : contact
                );
                setContacts(updatedContacts);
                setContactData({ organisation: '', emailAddress: '', phoneNumber: '' });
                toast.success(`${selectedContact.organisation} has been successfully updated!`);
                setSelectedContact(null);
            } else {
                toast.error('Failed to edit contact!');
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleAddContact = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/settings/repatriation/add-contact`, contactData);
            if (response.data.success) {
                setContacts(response.data.data);
                setContactData({ organisation: '', emailAddress: '', phoneNumber: '' });
                toast.success('Contact has been successfully added!');
            } else {
                toast.error('Failed to add contact!');
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleDeleteContact = (id) => {
        axios.delete(`${BASE_URL}/api/settings/repatriation/contacts/${id}`)
            .then(() => {
                setContacts(contacts.filter(contact => contact._id !== id));
                if (selectedContact && selectedContact._id === id) {
                    setSelectedContact(null);
                    setContactData({ organisation: '', emailAddress: '', phoneNumber: '' });
                }
            })
            .catch(error => {
                console.error('There was an error deleting the contact!', error);
            });
    };

    const handleDeselectContact = () => {
        setSelectedContact(null);
        setContactData({ organisation: '', emailAddress: '', phoneNumber: '' });
    };

    useEffect(() => {
        handleLoadContacts();
    }, []);

    useEffect(() => {
        if (selectedContact) {
            setContactData({
                organisation: selectedContact.organisation,
                emailAddress: selectedContact.emailAddress,
                phoneNumber: selectedContact.phoneNumber
            });
        } else {
            setContactData({ organisation: '', emailAddress: '', phoneNumber: '' });
        }
    }, [selectedContact]);

    return (
        <div className='border rounded px-4 py-4'>
            <p className='font-semibold text-lg'>Manage Contacts</p>
            <div className='grid grid-cols-1 gap-2 py-2'>
                <Select value={selectedContact ? selectedContact._id : 'deselect'} onValueChange={(value) => {
                    if (value === 'deselect') {
                        handleDeselectContact();
                    } else {
                        setSelectedContact(contacts.find(contact => contact._id === value));
                    }
                }}>
                    <SelectTrigger>{selectedContact ? selectedContact.organisation : "Select Contact"}</SelectTrigger>
                    <SelectContent>
                        <SelectItem value="deselect">
                            Deselect
                        </SelectItem>
                        {contacts.map(contact => (
                            <SelectItem key={contact._id} value={contact._id}>
                                {contact.organisation}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <h2 className='font-thin'>{selectedContact ? "Edit Contact" : "Add New Contact"}</h2>
            <div className='grid grid-cols-1 gap-2 py-2'>
                <Input
                    type="text"
                    name="organisation"
                    value={contactData.organisation}
                    onChange={(e) => setContactData({ ...contactData, organisation: e.target.value })}
                    placeholder="Name"
                />
                <Input
                    type="text"
                    name="emailAddress"
                    value={contactData.emailAddress}
                    onChange={(e) => setContactData({ ...contactData, emailAddress: e.target.value })}
                    placeholder="Email"
                />
                <Input
                    type="text"
                    name="phoneNumber"
                    value={contactData.phoneNumber}
                    onChange={(e) => setContactData({ ...contactData, phoneNumber: e.target.value })}
                    placeholder="Phone"
                />
            </div>
            <div className='grid grid-cols-1 gap-2'>
                <Button className='w-full' onClick={selectedContact ? handleEditContact : handleAddContact}>
                    {selectedContact ? "Edit Contact" : "Add Contact"}
                </Button>
                {selectedContact && (
                    <Button className='w-full bg-red-400 hover:bg-red-300' onClick={() => handleDeleteContact(selectedContact._id)}>Delete Contact</Button>
                )}
            </div>
        </div>
    );
};

export default AddContacts;
