import DisplayDrivers from "../Components/Drivers/DisplayDrivers"

const DriversPage  = () => {
    return(
        <>
            <DisplayDrivers/>
        </>
    )
}

export default DriversPage