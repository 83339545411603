import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../../Screens/Settings/DASHBOARD_SETTINGS";

const ReferrerDetails = ({ onChange }) => {
    const [referrerDetails, setReferrerDetails] = useState({
        trustLocation: '',
        selectedHospital: '',
        selectedOperator: '',
        selectedDepartment: '',
        refName: '',
        phoneNumber: '',
    });
    const [locationData, setLocationData] = useState([]);

    useEffect(() => {
        const getLocations = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/settings/locations/trusts`);
                if (response.data.success) {
                    setLocationData(response.data.results);
                }
            } catch (error) {
                if (error.response) {
                    toast.error("An unknown error has occurred");
                } else if (error.request) {
                    toast.error("An unknown error has occurred");
                    console.error('No response received:', error.request);
                } else {
                    toast.error("An unknown error has occurred");
                    console.error('Error setting up the request:', error.message);
                }
            }
        };

        getLocations();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updates = { [name]: value };

        if (name === "selectedHospital") {
            const selectedTrust = locationData.find(trust => trust.locations.some(location => location.name === value));
            if (selectedTrust) {
                updates['trustLocation'] = selectedTrust.name;
            }
        } else if (name === "selectedOperator") {
            updates['refName'] = value;
        } else if (name === "selectedDepartment") {
            const selectedTrust = locationData.find(trust => trust.locations.some(location => location.name === referrerDetails.selectedHospital));
            const selectedLocation = selectedTrust?.locations.find(location => location.name === referrerDetails.selectedHospital);
            const selectedDepartment = selectedLocation?.departments.find(department => department.name === value);
            if (selectedDepartment) {
                updates['phoneNumber'] = selectedDepartment.mobileNumber;
            }
        }

        const updatedDetails = {
            ...referrerDetails,
            ...updates,
        };
        
        setReferrerDetails(updatedDetails);
        onChange(updatedDetails);
    };

    return (
        <div>
            <div className='mb-4'>
                <p className='font-bold text-2xl mb-4'>Referral Source</p>
                <label htmlFor="trustLocation" className="block font-bold mb-2">Hospital Trust:</label>
                <select
                    value={referrerDetails.trustLocation}
                    onChange={handleChange}
                    id="trustLocation"
                    name="trustLocation"
                    className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                >
                    <option value="">Select Trust</option>
                    {locationData && locationData.map((trust) => (
                        <option key={trust._id} value={trust.name}>
                            {trust.name}
                        </option>
                    ))}
                </select>
            </div>
            <p className='font-bold text-2xl mb-4'>Referrer Details</p>
            <div className='grid gap-4 grid-cols-1 md:grid-cols-3 mb-4'>
                <div>
                    <label htmlFor="selectedHospital" className="block font-bold mb-2">Referring Source:</label>
                    <select
                        value={referrerDetails.selectedHospital}
                        onChange={handleChange}
                        id="selectedHospital"
                        name="selectedHospital"
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    >
                        <option value="">Select Hospital</option>
                        {locationData && locationData.flatMap(trust => trust.locations.map(location => (
                            <option key={location._id} value={location.name}>
                                {location.name}
                            </option>
                        )))}
                    </select>
                </div>
                {referrerDetails.selectedHospital && (
                    <div>
                        <label htmlFor="selectedOperator" className="block font-bold mb-2">Referring Operator:</label>
                        <select
                            value={referrerDetails.selectedOperator}
                            onChange={handleChange}
                            id="selectedOperator"
                            name="selectedOperator"
                            className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                        >
                            <option value="">Select Operator</option>
                            {locationData.find(trust => trust.name === referrerDetails.trustLocation)?.locations.find(location => location.name === referrerDetails.selectedHospital)?.operators.map((operator) => (
                                <option key={operator._id} value={operator.name}>
                                    {operator.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {referrerDetails.selectedOperator && (
                    <div>
                        <label htmlFor="selectedDepartment" className="block font-bold mb-2">Department:</label>
                        <select
                            value={referrerDetails.selectedDepartment}
                            onChange={handleChange}
                            id="selectedDepartment"
                            name="selectedDepartment"
                            className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                        >
                            <option value="">Select Department</option>
                            {locationData.find(trust => trust.name === referrerDetails.trustLocation)?.locations.find(location => location.name === referrerDetails.selectedHospital)?.departments.map((department) => (
                                <option key={department._id} value={department.name}>
                                    {department.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <div>
                    <label htmlFor="refName" className="block font-bold mb-2">Referrer Name:</label>
                    <input
                        value={referrerDetails.refName}
                        onChange={handleChange}
                        type="text"
                        id="refName"
                        name="refName"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>

                <div>
                    <label htmlFor="phoneNumber" className="block font-bold mb-2">Referrer Phone Number:</label>
                    <input
                        value={referrerDetails.phoneNumber}
                        readOnly
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
            </div>
        </div>
    );
};

export default ReferrerDetails;
