import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Logo from '../../Assets/Images/JaceLOGO2.png';
import { useHistory } from 'react-router-dom';
import { Button, buttonVariants } from '../Global/UI/Button';
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
  DialogDescription,
} from '../Global/UI/Dialog';
import { Label } from '@radix-ui/react-label';
import { Input } from '../Global/UI/Input';
import { BASE_URL } from '../../Screens/Settings/DASHBOARD_SETTINGS';

const DisplayDrivers = () => {
  const [drivers, setDrivers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/drivers/getDrivers`);
        setDrivers(response.data.drivers);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching drivers:', error.message);
      }
    };

    fetchData();
  }, []);

  const handleViewDriver = (driver) => {
    history.push({
      pathname: `/drivers/${driver.driverId}`,
      state: { driver: driver },
    });
  };

  const handleCreateAccount = async () => {
    setError("");
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/api/drivers/register`, {
        username: username,
        password: password
      });
      if (response.data.username) {
        handleCloseDialog();
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Something went wrong. Please try again.");
    }
  }

  const handleAddDriver = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };


  const renderSkeleton = () => {
    return (
      <tr>
        <td className="py-3 text-sm" role="cell">
          <div className="flex items-center gap-2">
            <div className="h-[30px] w-[30px] rounded-full bg-gray-300 animate-pulse"></div>
            <div className="h-[18px] w-[100px] bg-gray-300 animate-pulse"></div>
          </div>
        </td>
        <td className="py-3 text-sm" role="cell">
          <div className="h-[18px] w-[50px] bg-gray-300 animate-pulse"></div>
        </td>
        <td className="py-3 text-sm" role="cell">
          <div className="h-[18px] w-[50px] bg-gray-300 animate-pulse"></div>
        </td>
        <td className="py-3 text-sm" role="cell">
          <div className="h-[32px] w-[100px] bg-gray-300 animate-pulse"></div>
        </td>
      </tr>
    );
  };

  return (
    <div className="w-full p-10">

      <Dialog open={isDialogOpen} onOpenChange={handleCloseDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add a Vehicle</DialogTitle>
            <DialogClose />
          </DialogHeader>
          <DialogDescription>
            Add a new vehicle to the system.
          </DialogDescription>
          <div className=" max-w-lg">
            <Label htmlFor="username" >Username:</Label>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              id="username"
              name="username"
            />
          </div>
          <div className="mb-4 max-w-lg">
            <Label htmlFor="username" >Password:</Label>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="text"
              id="password"
              name="password"
            />
          </div>
          <Button
            variant="default"
            size="lg"
            className={buttonVariants({ variant: "default", size: "lg" })}
            onClick={() => handleCreateAccount()}>
            {loading ? 'Submitting...' : 'Register Vehicle'}
          </Button>
        </DialogContent>
      </Dialog>


      <div className="flex items-center justify-between mb-4">
        <h2 className="text-3xl font-semibold">Drivers</h2>
        <Button
          variant="default"
          size="default"
          className={buttonVariants({ variant: "default", size: "default" })}
          onClick={() => handleAddDriver()}>
          Add a vehicle
        </Button>
      </div>
      <div className="w-full overflow-x-scroll md:overflow-x-hidden">
        <table role="table" className="w-full min-w-[500px] overflow-x-scroll">
          <thead>
            <tr role="row">
              <th role="columnheader" title="Name">
                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                  Name
                </div>
              </th>
              <th role="columnheader" title="Artworks">
                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                  Driver ID
                </div>
              </th>
              <th role="columnheader" title="Assigned Jobs">
                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                  Assigned Jobs
                </div>
              </th>
              <th role="columnheader" title="Actions">
                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                  Actions
                </div>
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup" className="px-4">
            {loading ? (
              Array.from({ length: 20 }).map((_, index) => renderSkeleton(index))
            ) : (
              drivers.map((driver, index) => (
                <tr key={index} role="row">
                  <td className="py-3 text-sm" role="cell">
                    <div className="flex items-center gap-2">
                      <div className="h-[30px] w-[30px] rounded-full">
                        <img
                          src={Logo}
                          className="h-full w-full rounded-full"
                          alt=""
                        />
                      </div>
                      <p className="text-md font-semibold text-gray-600">{`@${driver.username}`}</p>
                    </div>
                  </td>
                  <td className="py-3 text-sm" role="cell">
                    <p className="text-md font-semibold text-gray-600 ">{driver.driverId}</p>
                  </td>
                  <td className="py-3 text-sm" role="cell">
                    <p className="text-md font-semibold text-gray-600">{driver.assignedJobsCount}</p>
                  </td>
                  <td className="py-3 text-sm" role="cell">
                    <Button
                      variant="default"
                      size="default"
                      className={buttonVariants({ variant: "default", size: "default" })}
                      onClick={() => handleViewDriver(driver)}>
                      View Driver
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DisplayDrivers;
