import React from 'react'
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { BASE_URL } from '../Settings/DASHBOARD_SETTINGS';
import { Button } from '../../Components/Global/UI/Button';
import RiskAssessment from '../../Components/Add Job/Events/RiskAssessment';
import ReferrerDetails from '../../Components/Add Job/Events/ReffererDetails';
import StaffDetails from '../../Components/Add Job/Events/StaffDetails';
import EventDetails from '../../Components/Add Job/Events/EventDetails';

const AddEventsJob = () => {

    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const [jobDetails, setJobDetails] = React.useState({
        referrerDetails: {},
        eventDetails: {},
        riskAssessment: {},
        staffDetails: {},
    });

    const handleNavigation = () => {
        history.push('/');
    };

    const handleServiceRequest = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${BASE_URL}/api/events/create-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    referrerDetails: jobDetails.referrerDetails,
                    eventDetails: jobDetails.eventDetails,
                    staffDetails: jobDetails.staffDetails,
                    riskAssessment: jobDetails.riskAssessment,
                }),
            });

            if (!response.ok) {
                setLoading(false)
                throw new Error('Failed to submit service request');
            }

            const data = await response.json();

            if (data.success === true) {
                setLoading(false)
                toast.success(data.msg);
                handleNavigation()
            } else {
                setLoading(false)
                toast.error(data.msg);
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.msg);
            setError('Error submitting service request: ' + error.message);
        }
    };


    const handleReferrerDetailsChange = (data) => {
        console.log('Events Referrer Details:', data)
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            referrerDetails: data
        }));
    };

    const handleStaffDetailsChange = (data) => {
        console.log('Events Staff Details:', data)
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            staffDetails: data
        }));
    };
    
    const handleRiskAssessmentChange = (data) => {
        console.log('Events Risk Assessment Details:', data)
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            riskAssessment: data
        }));
    };

    const handleEventDetailsChange = (data) => {
        console.log('Events Details:', data)
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            eventDetails: data
        }));
    };
    return (
        <div className="px-6 my-8">
            <ReferrerDetails onChange={handleReferrerDetailsChange} />
            <EventDetails onChange={handleEventDetailsChange} />
            <RiskAssessment onChange={handleRiskAssessmentChange} />
            <StaffDetails onChange={handleStaffDetailsChange} riskAssessmentScore={jobDetails.riskAssessment.riskScore ? jobDetails.riskAssessment.riskScore : 0} />
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <Button
                onClick={() => handleServiceRequest()}
                disabled={loading}
                className='w-full py-3'
            >
                {loading ? 'Submitting...' : 'Submit Request'}
            </Button>
        </div>
    )
}

export default AddEventsJob