import AddRenalLocations from "./AddHospitalLocations"
import AddNIASRenalTrusts from "./AddTrustSettings"

const NIASRenalJobSettings = () => {
    return(
        <div className="py-6 grid grid-cols-3 justify-between gap-4">
            <AddNIASRenalTrusts/>
            <AddRenalLocations/>
        </div>
    )
}

export default NIASRenalJobSettings