import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '../../Components/Global/UI/Button';
import ReferrerDetails from '../../Components/Add Job/ReferrerDetails';
import PatientDetails from '../../Components/Add Job/PatientDetails';
import JourneyDetails from '../../Components/Add Job/JourneyDetails';
import SpecialRequirements from '../../Components/Add Job/SpecialRequirements';
import { toast } from 'react-toastify';
import { BASE_URL } from '../Settings/DASHBOARD_SETTINGS';
import { Textarea } from '../../Components/Global/UI/textarea';

const AddJourneyJob = () => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [jobDetails, setJobDetails] = useState({
        referrerDetails: {},
        patientDetails: {},  
        journeyDetails: {},
        specialRequirements: {},
        notes: '',
    });

    const handleNavigation = () => {
        history.push('/');
    };

    const handleServiceRequest = async () => {
        setLoading(true)
        try {

            if (!jobDetails.journeyDetails || !jobDetails.journeyDetails.journeys || 
                !jobDetails.journeyDetails.journeys[0] || !jobDetails.journeyDetails.journeys[0].dateTimePickup) {
                setLoading(false);
                toast.error('Please provide pickup date and time.');
                throw new Error('Please provide pickup date and time.');
            }


            const response = await fetch(`${BASE_URL}/api/Services/CreateRequest`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jobDetails),
            });
    
            if (!response.ok) {
                setLoading(false)
                throw new Error('Failed to submit service request');
            }

            const data = await response.json();    

            if (data.success === true) {
                setLoading(false)
                toast.success('Job has been successfully submitted!');
                history.push('/')
            } else {
                setLoading(false)
                toast.error('Failed to submit job: ' + data.message);
            }
        } catch (error) {
            setLoading(false);
            setError('Error submitting service request: ' + error.message);
        }
    };
    

    const handleReferrerDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            referrerDetails: data
        }));
    };
    
    const handlePatientDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            patientDetails: data
        }));
    };
    
    const handleJourneyDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            journeyDetails: data
        }));
    };
    
    const handleSpecialRequirementsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            specialRequirements: data
        }));
    };

    const handleNotesChange = (e) => {
        console.log(jobDetails.notes)
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            notes: e.target.value
        }));
    };

    return (
        <>
            <div className="px-6 my-8">
                <ReferrerDetails onChange={handleReferrerDetailsChange} />
                <PatientDetails onChange={handlePatientDetailsChange} />
                <JourneyDetails onChange={handleJourneyDetailsChange} />
                <SpecialRequirements onChange={handleSpecialRequirementsChange} />
                <div className='w-full my-10'>
                    <label className='font-bold text-lg' htmlFor="notes">Operator Notes:</label>
                    <Textarea
                        id="notes"
                        className="w-full mt-2 p-2 border rounded-md"
                        rows="4"
                        value={jobDetails.notes}
                        onChange={handleNotesChange}
                        placeholder="Enter any additional notes or observations..."
                    />
                </div>
                {error && <div className="text-red-500 mb-4">{error}</div>}
                <Button
                    onClick={() => handleServiceRequest()}
                    disabled={loading}
                    className='w-full py-3'
                >
                    {loading ? 'Submitting...' : 'Submit Request'}
                </Button>
            </div>
        </>
    );
};

export default AddJourneyJob;