import React, { useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger } from '../Global/UI/Select';

const ViewPatientDetails = ({ onChange , data }) => {
    const [patientDetails, setPatientDetails] = useState({
        name: data.patientDetails?.name || '',
        dob: data.patientDetails?.dob || '',
        age: '',
        gender: data.patientDetails?.gender || ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        const updatedDetails = {
            ...patientDetails,
            [name]: value,
        };

       setPatientDetails(updatedDetails);
       onChange(updatedDetails);
    };

    React.useEffect(() => {
        if (patientDetails.dob) {
            const dobDate = new Date(patientDetails.dob);
            const ageDiffMs = Date.now() - dobDate.getTime();
            const ageDate = new Date(ageDiffMs);
            const age = Math.abs(ageDate.getUTCFullYear() - 1970);
            setPatientDetails(prevDetails => ({
                ...prevDetails,
                age: age.toString()
            }));
        }
    }, [patientDetails.dob]);

    return (
        <div>
            <p className='font-bold text-2xl mb-4'>Patient Details</p>
            <div className='grid gap-4 grid-cols-1 md:grid-cols-3 mb-4'>
                <div>
                    <label htmlFor="name" className="block font-bold mb-2">Patient Name:</label>
                    <input
                        value={patientDetails.name}
                        onChange={handleChange}
                        type="text"
                        id="name"
                        name="name"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <label htmlFor="dob" className="block font-bold mb-2">{`Patient DOB: (${patientDetails.age})`}</label>
                    <input
                        type="date"
                        id="dob"
                        name="dob"
                        onChange={handleChange}
                        value={patientDetails.dob}
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <label htmlFor="gender" className="block font-bold mb-2">Patient Gender:</label>
                    <Select value={patientDetails.gender} onValueChange={handleChange}>
                        <SelectTrigger>{patientDetails.gender ? patientDetails.gender : "Select Gender"}</SelectTrigger>
                        <SelectContent>
                            <SelectItem key="male" value="Male">
                                Male
                            </SelectItem>
                            <SelectItem key="female" value="Female">
                                Female
                            </SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </div>
    );
};

export default ViewPatientDetails;
