import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Logo from '../../Assets/Images/JaceLOGO2.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../Screens/Settings/DASHBOARD_SETTINGS';

const DriverJobs = (selectedDriver) => {
    const [assignedJobs, setAssignedJobs] = useState(null);
    const [loading, setLoading] = useState(true);
    const driver = selectedDriver.driver;


    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/drivers/jobs/${driver.driverId}`);
            setAssignedJobs(response.data.assignedJobs);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching drivers:', error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, [driver.driverId]);

    const handleUnassignJob = async (jobID) => {
        try {
            const response = await axios.get(`${BASE_URL}/api/drivers/unassign-job/${driver.driverId}/${jobID}`);
            if (response.data.success) {
                toast.success('Job unassigned successfully');
                fetchData();
            } else {
                toast.error(`Job unassignment failed: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error unassigning job:', error.message);
            toast.error('Error unassigning job');
        }
    };

    const renderSkeleton = () => {
        return (
            <tr>
                <td className="py-3 text-sm" role="cell">
                    <div className="flex items-center gap-2">
                        <div className="h-[30px] w-[30px] rounded-full bg-gray-300 animate-pulse"></div>
                        <div className="h-[18px] w-[100px] bg-gray-300 animate-pulse"></div>
                    </div>
                </td>
                <td className="py-3 text-sm" role="cell">
                    <div className="h-[18px] w-[50px] bg-gray-300 animate-pulse"></div>
                </td>
                <td className="py-3 text-sm" role="cell">
                    <div className="h-[18px] w-[50px] bg-gray-300 animate-pulse"></div>
                </td>
                <td className="py-3 text-sm" role="cell">
                    <div className="h-[32px] w-[100px] bg-gray-300 animate-pulse"></div>
                </td>
                <td className="py-3 text-sm" role="cell">
                    <div className="h-[32px] w-[100px] bg-gray-300 animate-pulse"></div>
                </td>
                <td className="py-3 text-sm" role="cell">
                    <div className="h-[32px] w-[100px] bg-gray-300 animate-pulse"></div>
                </td>
                <td className="py-3 text-sm" role="cell">
                    <div className="h-[32px] w-[100px] bg-gray-300 animate-pulse"></div>
                </td>
            </tr>
        );
    };

    return (
        <div className="">
            <div className="w-full overflow-x-scroll px-4 md:overflow-x-hidden mt-4">
                <table role="table" className="w-full min-w-[500px] overflow-x-scroll">
                    <thead>
                        <tr role="row">
                            <th role="columnheader" title="Name">
                                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                                    Name
                                </div>
                            </th>
                            <th role="columnheader" title="Artworks">
                                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                                    DOB
                                </div>
                            </th>
                            <th role="columnheader" title="Assigned Jobs">
                                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                                    Reference
                                </div>
                            </th>
                            <th role="columnheader" title="Assigned Jobs">
                                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                                    Collection Time
                                </div>
                            </th>
                            <th role="columnheader" title="Assigned Jobs">
                                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                                    From
                                </div>
                            </th>
                            <th role="columnheader" title="Assigned Jobs">
                                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                                    To
                                </div>
                            </th>
                            <th role="columnheader" title="Actions">
                                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                                    Actions
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody role="rowgroup" className="px-4">
                        {loading ? (
                            Array.from({ length: 5 }).map((_, index) => renderSkeleton(index))
                        ) : (
                            assignedJobs.map((item, index) => (
                                <tr key={index} role="row">
                                    <td className="py-3 text-sm" role="cell">
                                        <div className="flex items-center gap-2">
                                            <div className="h-[30px] w-[30px] rounded-full">
                                                <img src={Logo} className="h-full w-full rounded-full" alt="" />
                                            </div>
                                            <p className="text-md font-semibold text-gray-600">{item.patientDetails.name}</p>
                                        </div>
                                    </td>
                                    <td className="py-3 text-sm" role="cell">
                                        <p className="text-md font-semibold text-gray-600 ">{new Date(item.patientDetails.dob).toLocaleDateString('en-GB')}</p>
                                    </td>
                                    <td className="py-3 text-sm" role="cell">
                                        <p className="text-md font-semibold text-gray-600">{item.referenceCode.toUpperCase()}</p>
                                    </td>
                                    <td className="py-3 text-sm" role="cell">
                                        <p className="text-md font-semibold text-gray-600">
                                            {new Date(item.journeyDetails.journeys[0].dateTimePickup).toLocaleString()}
                                        </p>
                                    </td>
                                    <td className="py-3 text-sm" role="cell">
                                        <p className="text-md font-semibold text-gray-600">
                                            {item.journeyDetails.journeys[0].pickupLocation === 'Other' ? item.journeyDetails.journeys[0].otherPickupLocation : item.journeyDetails.journeys[0].pickupLocation}
                                        </p>
                                    </td>
                                    <td className="py-3 text-sm" role="cell">
                                        <p className="text-md font-semibold text-gray-600">
                                            {item.journeyDetails.journeys[0].dropOffLocation === 'Other' ? item.journeyDetails.journeys[0].otherDropOffLocation : item.journeyDetails.journeys[0].dropOffLocation}
                                        </p>
                                    </td>
                                    <td className="py-3 text-sm" role="cell">
                                        <button
                                            onClick={() => handleUnassignJob(item._id)}
                                            className="px-4 py-2 bg-red-400 text-white rounded font-semibold"
                                        >
                                            Unassign Job
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DriverJobs;
