import React from 'react'
import { Label } from '../../../Global/UI/Label';
import { Select, SelectContent, SelectItem, SelectTrigger } from '../../../Global/UI/Select';
import riskAssessmentData from '../../../../Data/RiskAssessmentData';

const ViewRiskAssessment = ({ onChange, data }) => {
    const [riskAssessment, setRiskAssessment] = React.useState({
        eventType: data.eventType || '',
        eventVenueType: data.eventVenueType || '',
        standingOrSeated: data.standingOrSeated || '',
        audienceType: data.audienceType || '',
        eventPastHistory: data.eventPastHistory || '',
        expectedAttendance: data.expectedAttendance || '',
        eventWaitingTime: data.eventWaitingTime || '',
        timeOfYear: data.timeOfYear || '',
        proximityToHospital: data.proximityToHospital || '',
        hospitalEmergencryRoomSize: data.hospitalEmergencryRoomSize || '',
        additionalHazards: data.additionalHazards || [],
        onSiteFacilities: data.onSiteFacilities || ''
    });

    const [riskScore, setRiskScore] = React.useState(0);

    const calculateRiskScore = (assessment) => {
        let score = 0;
        score += riskAssessmentData.eventTypes.find(item => item.name === assessment.eventType)?.riskFactor || 0;
        score += riskAssessmentData.eventVenueType.find(item => item.name === assessment.eventVenueType)?.riskFactor || 0;
        score += riskAssessmentData.standingOrSeated.find(item => item.name === assessment.standingOrSeated)?.riskFactor || 0;
        score += riskAssessmentData.audienceType.find(item => item.name === assessment.audienceType)?.riskFactor || 0;
        score += riskAssessmentData.eventPastHistory.find(item => item.name === assessment.eventPastHistory)?.riskFactor || 0;
        score += riskAssessmentData.expectedAttendance.find(item => item.name === assessment.expectedAttendance)?.riskFactor || 0;
        score += riskAssessmentData.eventWaitingTime.find(item => item.name === assessment.eventWaitingTime)?.riskFactor || 0;
        score += riskAssessmentData.timeOfYear.find(item => item.name === assessment.timeOfYear)?.riskFactor || 0;
        score += riskAssessmentData.proximityToHospital.find(item => item.name === assessment.proximityToHospital)?.riskFactor || 0;
        score += riskAssessmentData.hospitalEmergencryRoomSize.find(item => item.name === assessment.hospitalEmergencryRoomSize)?.riskFactor || 0;
        score += riskAssessmentData.additionalHazards.reduce((total, item) => total + (assessment.additionalHazards.includes(item.name) ? item.riskFactor : 0), 0);
        score += riskAssessmentData.onSiteFacilities.find(item => item.name === assessment.onSiteFacilities)?.riskFactor || 0;
        return score;
    };

    const handleChange = (name, value) => {
        setRiskAssessment((prevAssessment) => {
            const updatedAssessment = { ...prevAssessment, [name]: value };
            const newRiskScore = calculateRiskScore(updatedAssessment);
            setRiskScore(newRiskScore);
            onChange({ ...updatedAssessment, riskScore: newRiskScore });
            return updatedAssessment;
        });
    };

    React.useEffect(() => {
        const initialRiskScore = calculateRiskScore(riskAssessment);
        setRiskScore(initialRiskScore);
        onChange({ ...riskAssessment, riskScore: initialRiskScore });
    }, [data]); 


    return (
        <div>
            <div className='mb-4'>
                <p className='font-bold text-2xl mb-4'>{`Risk Assessment - (${riskScore})`}</p>
                <div className='grid gap-4 grid-cols-1 md:grid-cols-3 mb-4'>
                    <div>
                        <Label htmlFor="trustLocation" className="block font-bold mb-2">Type Of Event:</Label>
                        <Select value={riskAssessment.eventType} onValueChange={(value) => handleChange('eventType', value)}>
                            <SelectTrigger>{riskAssessment.eventType ? riskAssessment.eventType : "Choose Item"}</SelectTrigger>
                            <SelectContent>
                                {riskAssessmentData.eventTypes.map(event => (
                                    <SelectItem key={event.name} value={event.name}>
                                        {event.name} - Risk Factor: {event.riskFactor}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor="name" className="block font-bold mb-2">Venue Of Event:</Label>
                        <Select value={riskAssessment.eventVenueType} onValueChange={(value) => handleChange('eventVenueType', value)}>
                            <SelectTrigger>{riskAssessment.eventVenueType ? riskAssessment.eventVenueType : "Choose Item"}</SelectTrigger>
                            <SelectContent>
                                {riskAssessmentData.eventVenueType.map(event => (
                                    <SelectItem key={event.name} value={event.name}>
                                        {event.name} - Risk Factor: {event.riskFactor}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor="name" className="block font-bold mb-2">Standing Or Seated:</Label>
                        <Select value={riskAssessment.standingOrSeated} onValueChange={(value) => handleChange('standingOrSeated', value)}>
                            <SelectTrigger>{riskAssessment.standingOrSeated ? riskAssessment.standingOrSeated : "Choose Item"}</SelectTrigger>
                            <SelectContent>
                                {riskAssessmentData.standingOrSeated.map(event => (
                                    <SelectItem key={event.name} value={event.name}>
                                        {event.name} - Risk Factor: {event.riskFactor}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor="name" className="block font-bold mb-2">Audience / Crowd Mix:</Label>
                        <Select value={riskAssessment.audienceType} onValueChange={(value) => handleChange('audienceType', value)}>
                            <SelectTrigger>{riskAssessment.audienceType ? riskAssessment.audienceType : "Choose Item"}</SelectTrigger>
                            <SelectContent>
                                {riskAssessmentData.audienceType.map(event => (
                                    <SelectItem key={event.name} value={event.name}>
                                        {event.name} - Risk Factor: {event.riskFactor}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor="name" className="block font-bold mb-2">Event Past History:</Label>
                        <Select value={riskAssessment.eventPastHistory} onValueChange={(value) => handleChange('eventPastHistory', value)}>
                            <SelectTrigger>{riskAssessment.eventPastHistory ? riskAssessment.eventPastHistory : "Choose Item"}</SelectTrigger>
                            <SelectContent>
                                {riskAssessmentData.eventPastHistory.map(event => (
                                    <SelectItem key={event.name} value={event.name}>
                                        {event.name} - Risk Factor: {event.riskFactor}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor="name" className="block font-bold mb-2">Expected Attendance:</Label>
                        <Select value={riskAssessment.expectedAttendance} onValueChange={(value) => handleChange('expectedAttendance', value)}>
                            <SelectTrigger>{riskAssessment.expectedAttendance ? riskAssessment.expectedAttendance : "Choose Item"}</SelectTrigger>
                            <SelectContent>
                                {riskAssessmentData.expectedAttendance.map(event => (
                                    <SelectItem key={event.name} value={event.name}>
                                        {event.name} - Risk Factor: {event.riskFactor}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor="name" className="block font-bold mb-2">Waiting Time:</Label>
                        <Select value={riskAssessment.eventWaitingTime} onValueChange={(value) => handleChange('eventWaitingTime', value)}>
                            <SelectTrigger>{riskAssessment.eventWaitingTime ? riskAssessment.eventWaitingTime : "Choose Item"}</SelectTrigger>
                            <SelectContent>
                                {riskAssessmentData.eventWaitingTime.map(event => (
                                    <SelectItem key={event.name} value={event.name}>
                                        {event.name} - Risk Factor: {event.riskFactor}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor="name" className="block font-bold mb-2">Time of Year:</Label>
                        <Select value={riskAssessment.timeOfYear} onValueChange={(value) => handleChange('timeOfYear', value)}>
                            <SelectTrigger>{riskAssessment.timeOfYear ? riskAssessment.timeOfYear : "Choose Item"}</SelectTrigger>
                            <SelectContent>
                                {riskAssessmentData.timeOfYear.map(event => (
                                    <SelectItem key={event.name} value={event.name}>
                                        {event.name} - Risk Factor: {event.riskFactor}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor="name" className="block font-bold mb-2">Proximity To A&E:</Label>
                        <Select value={riskAssessment.proximityToHospital} onValueChange={(value) => handleChange('proximityToHospital', value)}>
                            <SelectTrigger>{riskAssessment.proximityToHospital ? riskAssessment.proximityToHospital : "Choose Item"}</SelectTrigger>
                            <SelectContent>
                                {riskAssessmentData.proximityToHospital.map(event => (
                                    <SelectItem key={event.name} value={event.name}>
                                        {event.name} - Risk Factor: {event.riskFactor}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor="name" className="block font-bold mb-2">Size Of A&E Dept:</Label>
                        <Select value={riskAssessment.hospitalEmergencryRoomSize} onValueChange={(value) => handleChange('hospitalEmergencryRoomSize', value)}>
                            <SelectTrigger>{riskAssessment.hospitalEmergencryRoomSize ? riskAssessment.hospitalEmergencryRoomSize : "Choose Item"}</SelectTrigger>
                            <SelectContent>
                                {riskAssessmentData.hospitalEmergencryRoomSize.map(event => (
                                    <SelectItem key={event.name} value={event.name}>
                                        {event.name} - Risk Factor: {event.riskFactor}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor="name" className="block font-bold mb-2">Extra Hazards:</Label>
                        <Select value={riskAssessment.eventVenueType} onValueChange={(value) => handleChange('eventVenueType', value)}>
                            <SelectTrigger>{riskAssessment.eventVenueType ? riskAssessment.eventVenueType : "Event Venue"}</SelectTrigger>
                            <SelectContent>
                                {riskAssessmentData.eventVenueType.map(event => (
                                    <SelectItem key={event.name} value={event.name}>
                                        {event.name} - Risk Factor: {event.riskFactor}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor="name" className="block font-bold mb-2">On-site Facilities:</Label>
                        <Select value={riskAssessment.onSiteFacilities} onValueChange={(value) => handleChange('onSiteFacilities', value)}>
                            <SelectTrigger>{riskAssessment.onSiteFacilities ? riskAssessment.onSiteFacilities : "Choose Item"}</SelectTrigger>
                            <SelectContent>
                                {riskAssessmentData.onSiteFacilities.map(event => (
                                    <SelectItem key={event.name} value={event.name}>
                                        {event.name} - Risk Factor: {event.riskFactor}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewRiskAssessment;
