import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useHistory } from 'react-router-dom';
import { Label } from "../Components/Global/UI/Label";
import { Input } from "../Components/Global/UI/Input";
import { Button, buttonVariants } from "../Components/Global/UI/Button";
import { BASE_URL } from "../Screens/Settings/DASHBOARD_SETTINGS";


const AddAmbulance = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const navigateBack = () => {
        history.push({
            pathname: '/drivers',
        });
    }

    const handleCreateAccount = async () => {
        setError("");
        setLoading(true);
        try {
            const response = await axios.post(`${BASE_URL}/api/drivers/register`, {
                username: username,
                password: password
            });
            if (response.data.username) {
                toast.success('Vehicle has been successfully registered.');
                navigateBack();
            } else {
                toast.error('Could not add vehicle.');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError("Something went wrong. Please try again.");
        }
    };

    return (
        <div className="m-10">
            <p className="font-bold text-2xl">Add a vehicle</p>
            <div className="mb-4 mt-4 max-w-lg">
                <Label htmlFor="username" className="block font-bold mb-2">Username:</Label>
                <Input
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    type="text"
                    id="username"
                    name="username"
                />
            </div>
            <div className="mb-4 mt-4 max-w-lg">
                <Label htmlFor="password" className="block font-bold mb-2">Password:</Label>
                <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="text"
                    id="password"
                    name="password"
                />
            </div>



            {error && <div className="text-red-500 mb-4">{error}</div>}

            <Button
                variant="default"
                size="lg"
                className={buttonVariants({ variant: "default", size: "lg" })}
                onClick={() => handleCreateAccount}>
                {loading ? 'Submitting...' : 'Register Account'}
            </Button>
        </div>
    );
}

export default AddAmbulance;
