const riskAssessmentData = {
    "eventTypes": [
        {
            "name": "Village / Church / School Fete",
            "riskFactor": 1
        },
        {
            "name": "Classical Music Performance",
            "riskFactor": 2
        },
        {
            "name": "Agricultural / County Show",
            "riskFactor": 2
        },
        {
            "name": "State Occasions / VIP Visits",
            "riskFactor": 2
        },
        {
            "name": "Demo / March: Low Risk",
            "riskFactor": 2
        },
        {
            "name": "Public Exhibition",
            "riskFactor": 3
        },
        {
            "name": "Water Sports Activities",
            "riskFactor": 3
        },
        {
            "name": "Motorcycle Display",
            "riskFactor": 3
        },
        {
            "name": "Contact Sports (Football, Hockey)",
            "riskFactor": 3
        },
        {
            "name": "Aviation Event",
            "riskFactor": 3
        },
        {
            "name": "Athletics Events & Marathons",
            "riskFactor": 3
        },
        {
            "name": "Music Festival",
            "riskFactor": 3
        },
        {
            "name": "Horse Show / Riding Event",
            "riskFactor": 4
        },
        {
            "name": "Motor Sport / Power Boat Racing",
            "riskFactor": 4
        },
        {
            "name": "Bonfire / Pyrotechnic Display",
            "riskFactor": 4
        },
        {
            "name": "Pop / Rock Concert",
            "riskFactor": 5
        },
        {
            "name": "Demo / March: Medium Risk",
            "riskFactor": 5
        },
        {
            "name": "New Year Celebrations",
            "riskFactor": 7
        },
        {
            "name": "Demo / March: High Risk",
            "riskFactor": 7
        },
        {
            "name": "Dance Event",
            "riskFactor": 8
        },
        {
            "name": "Demo / March: Opposing Factions",
            "riskFactor": 9
        }
    ],
    "eventVenueType": [
        {
            "name": "Indoor",
            "riskFactor": 1
        },
        {
            "name": "Stadium",
            "riskFactor": 2
        },
        {
            "name": "Outdoor - confined location (Park)",
            "riskFactor": 2
        },
        {
            "name": "Other outdoor (e.g. Festival)",
            "riskFactor": 3
        },
        {
            "name": "Widespread location (e.g. Streets)",
            "riskFactor": 4
        },
        {
            "name": "Temporary Outdoor Structures",
            "riskFactor": 4
        },
        {
            "name": "Includes Overnight Camping",
            "riskFactor": 5
        }
    ],
    "standingOrSeated": [
        {
            "name": "Seated",
            "riskFactor": 1
        },
        {
            "name": "Mixed",
            "riskFactor": 2
        },
        {
            "name": "Standing",
            "riskFactor": 3
        }
    ],
    "audienceType": [
        {
            "name": "Full mix, in family groups",
            "riskFactor": 2
        },
        {
            "name": "Full mix, not in family groups",
            "riskFactor": 3
        },
        {
            "name": "Predominantly young adults",
            "riskFactor": 3
        },
        {
            "name": "Predominantly children and teenagers",
            "riskFactor": 4
        },
        {
            "name": "Predominantly elderly",
            "riskFactor": 4
        },
        {
            "name": "Full mix, rival factions",
            "riskFactor": 5
        }
    ],
    "eventPastHistory": [
        {
            "name": "Previous low casualty rate (<1%)",
            "riskFactor": -1
        },
        {
            "name": "Previous med casualty rate (1-2%)",
            "riskFactor": 1
        },
        {
            "name": "Previous high casualty rate (>2%)",
            "riskFactor": 2
        },
        {
            "name": "No data, unprecedented event",
            "riskFactor": 3
        },
    ],
    "expectedAttendance": [
        {
            "name": "< 1,000",
            "riskFactor": 1
        },
        {
            "name": "< 3,000",
            "riskFactor": 2
        },
        {
            "name": "< 5,000",
            "riskFactor": 8
        },
        {
            "name": "< 10,000",
            "riskFactor": 12
        },
        {
            "name": "< 20,000",
            "riskFactor": 16
        },
        {
            "name": "< 30,000",
            "riskFactor": 20
        },
        {
            "name": "< 40,000",
            "riskFactor": 24
        },
        {
            "name": "< 60,000",
            "riskFactor": 28
        },
        {
            "name": "< 80,000",
            "riskFactor": 34
        },
        {
            "name": "< 100,000",
            "riskFactor": 42
        },
        {
            "name": "< 200,000",
            "riskFactor": 50
        },
        {
            "name": "< 300,000",
            "riskFactor": 58
        },
    ],
    "eventWaitingTime": [
        {
            "name": "None",
            "riskFactor": 0
        },
        {
            "name": "< 4 Hours",
            "riskFactor": 1
        },
        {
            "name": "> 4 Hours",
            "riskFactor": 2
        },
        {
            "name": "> 12 Hours",
            "riskFactor": 3
        },
    ],
    "timeOfYear": [
        {
            "name": "Autumn",
            "riskFactor": 1
        },
        {
            "name": "Spring",
            "riskFactor": 1
        },
        {
            "name": "Summer",
            "riskFactor": 2
        },
        {
            "name": "Winter",
            "riskFactor": 2
        },
    ],
    "proximityToHospital": [
        {
            "name": "< 30 mins by road",
            "riskFactor": 0
        },
        {
            "name": "> 30 mins by road",
            "riskFactor": 2
        },
    ],
    "hospitalEmergencryRoomSize": [
        {
            "name": "Choice",
            "riskFactor": 1
        },
        {
            "name": "Large A&E",
            "riskFactor": 2
        },
        {
            "name": "Small A&E",
            "riskFactor": 3
        },
    ],
    "additionalHazards": [
        {
            "name": "Carnival",
            "riskFactor": 1
        },
        {
            "name": "Helicopters",
            "riskFactor": 1
        },
        {
            "name": "Motor Sport",
            "riskFactor": 1
        },
        {
            "name": "Parachute",
            "riskFactor": 1
        },
        {
            "name": "Street Theatre",
            "riskFactor": 1
        },
        {
            "name": "Climbing",
            "riskFactor": 1
        },
        {
            "name": "Abseiling",
            "riskFactor": 1
        }, 
    ],
    "onSiteFacilities": [
        {
            "name": "Suturing",
            "riskFactor": -2
        },
        {
            "name": "X-Ray",
            "riskFactor": -2
        },
        {
            "name": "Minor Surgery",
            "riskFactor": -2
        },
        {
            "name": "Plastering",
            "riskFactor": -2
        },
        {
            "name": "Psychiatric",
            "riskFactor": -2
        },
        {
            "name": "GP Facilities",
            "riskFactor": -2
        },
    ],
};

export default riskAssessmentData;
