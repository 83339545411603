import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DailyCleaningLog from "./CleaningLog";
import PomLogs from "./PomsLog";

const DisplayLogs = () => {

    const location = useLocation();
    const { log, driverId } = location.state || {};


    if (!log) {
        return <div>Loading...</div>;
    }

    switch (log.logTypeID) {
        case 0:
            return <DailyCleaningLog log={log} driverId={driverId} />;
        case 0:
            return <DailyCleaningLog log={log} driverId={driverId} />;
        case 2:
            return <PomLogs log={log} driverId={driverId} />;
    }
}

export default DisplayLogs;