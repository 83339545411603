import axios from 'axios';
import React from 'react'
import { useParams } from "react-router-dom";
import { BASE_URL } from '../../../../Screens/Settings/DASHBOARD_SETTINGS';
import { toast } from "react-toastify";
import ViewPatientDetails from './ViewPatientDetails';
import ViewJourneyDetails from './ViewJourneyDetails';
import ViewReferrerDetails from './ViewReferrerDetails';
import ViewSpecialRequirements from './ViewSpecialRequirements';
import ViewStaffDetails from './ViewStaffDetails';
import { Button, buttonVariants } from '../../../Global/UI/Button';
import { ThreeDots } from 'react-loader-spinner'
import { useHistory } from 'react-router-dom';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogClose, DialogDescription } from '../../../../Components/Global/UI/Dialog';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "../../../../Components/Global/UI/Command"
import { Popover, PopoverContent, PopoverTrigger } from "../../../../Components/Global/UI/Popover"
import { Check, ChevronsUpDown } from "lucide-react"
import { Input } from '../../../Global/UI/Input';
import { Label } from '../../../Global/UI/Label';

const ViewRepatriationDetails = () => {

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const { JobID } = useParams();
    const [jobDetails, setJobDetails] = React.useState({
        itemId: '',
        referenceCode: '',
        discount: '',
        referrerDetails: {},
        staffDetails: {},
        patientDetails: {},
        journeyDetails: {},
        specialRequirements: {},
    });
    const [loading, setLoading] = React.useState(true);
    const history = useHistory()
    const [selectedDriverUsername, setSelectedDriverUsername] = React.useState("");
    const [drivers, setDrivers] = React.useState(null);
    console.log('Job Details:' , jobDetails)
    const handleAssignJob = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/repatriation/assignJob/${selectedDriverUsername.toUpperCase()}/${jobDetails.itemId}`);
            if (response.data.success) {
                toast.success(`Sucessfully Assigned Job: ${jobDetails.itemId}.`);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                    console.error('Item not found:', error.response.data.message);
                }
            } else if (error.request) {
                toast.error("An unknown error has occured");
                console.error('No response received:', error.request);
            } else {
                toast.error("An unknown error has occured");
                console.error('Error setting up the request:', error.message);
            }
        }
    };

    const fetchDrivers = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/drivers/getDrivers`);
            if (response.data.success) {
                setDrivers(response.data.drivers);
            }
        } catch (error) {
            console.error('Error fetching drivers:', error.message);
            toast.error('Failed to fetch drivers');
        }
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleDiscountChange = (event) => {
        const { name, value } = event.target;
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            [name]: value
        }));
    };

    const handleJourneyDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            journeyDetails: data
        }));
    };

    const handleReferrerDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            referrerDetails: data
        }));
    };

    const handlePatientDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            patientDetails: data
        }));
    };

    const handleSpecialRequirementsChange = (data) => {
        console.log(data)
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            specialRequirements: data
        }));
    };

    const handleStaffDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            staffDetails: data
        }));
    };

    

    const handleJobDelete = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/repatriation/${jobDetails.itemId}/remove-request`);
            if (response.data.success) {
                toast.success(response.data.msg);
                history.push('/')
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.msg);
                    console.error('Validation errors:', error.response.data.msg);
                }
            } else if (error.request) {
                toast.error("An unknown error has occured");
                console.error('No response received:', error.request);
            } else {
                toast.error("An unknown error has occured");
                console.error('Error setting up the request:', error.msg);
            }
        }
    }

    const cancelJob = async (status) => {
        try {
            const response = await axios.get(`${BASE_URL}/api/repatriation/${jobDetails.itemId}/cancel-job`);
            if (response.data.success) {
                toast.success(response.data.msg);
                history.push('/')
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                    console.error('Validation errors:', error.response.data.errors);
                }
            } else if (error.request) {
                toast.error("An unknown error has occured");
                console.error('No response received:', error.request);
            } else {
                toast.error("An unknown error has occured");
                console.error('Error setting up the request:', error.message);
            }
        }
    }

    const handleUpdateJob = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/repatriation/${jobDetails.itemId}/updateRequest`, jobDetails);
            if (response.data.success) {
                toast.success(response.data.msg);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                    console.error('Item not found:', error.response.data.message);
                }
            } else if (error.request) {
                toast.error("An unknown error has occured");
                console.error('No response received:', error.request);
            } else {
                toast.error("An unknown error has occured");
                console.error('Error setting up the request:', error.message);
            }
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${BASE_URL}/api/repatriation/job/${JobID}`
            );
            console.log(response.data)
            if (response.data.success) {
                setLoading(false);
                console.log('Response Data:', response.data)
                setJobDetails({
                    itemId: response.data.job._id || '',
                    discount: response.data.job.discount || '0.00',
                    referenceCode: response.data.job.referenceCode || '',
                    referrerDetails: response.data.job.referrerDetails || {},
                    staffDetails: response.data.job.staffDetails || {},
                    patientDetails: response.data.job.patientDetails || {},
                    journeyDetails: response.data.job.journeyDetails || {},
                    specialRequirements: response.data.job.specialRequirements || {},
                });
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    setLoading(false);
                    toast.error(error.response.data.message);
                    console.error("Item not found:", error.response.data.message);
                }
            } else if (error.request) {
                setLoading(false);
                toast.error("An unknown error has occurred");
                console.error("No response received:", error.request);
            } else {
                setLoading(false);
                toast.error("An unknown error has occurred");
                console.error("Error setting up the request:", error.message);
            }
        }
    };
    React.useEffect(() => {
        fetchData();
        fetchDrivers();
    }, []);



    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <ThreeDots
                    height="80"
                    width="80"
                    color="#000000"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                />
            </div>
        );
    }

    return (
        <div className="full-overview m-10">

            <Dialog open={isDialogOpen} onOpenChange={handleCloseDialog}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Assign an ambulance</DialogTitle>
                        <DialogClose />
                    </DialogHeader>
                    <DialogDescription>
                        Assign an ambulance to this job.
                    </DialogDescription>
                    {drivers && (
                        <Popover open={open} onOpenChange={setOpen} >
                            <PopoverTrigger asChild>
                                <Button
                                    variant="outline"
                                    role="combobox"
                                    aria-expanded={open}
                                    className="w-auto justify-between"
                                >
                                    {selectedDriverUsername ? selectedDriverUsername.toUpperCase() : "Select Driver..."}
                                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-full p-0">
                                <Command>
                                    <CommandInput placeholder="Search Driver..." />
                                    <CommandEmpty>No Driver found.</CommandEmpty>
                                    <CommandGroup>
                                        {drivers.map((framework) => (
                                            <CommandItem
                                                key={framework.username}
                                                value={framework.username}
                                                onSelect={(currentValue) => {
                                                    const newSelectedDriver = currentValue === selectedDriverUsername ? "" : currentValue;
                                                    setSelectedDriverUsername(newSelectedDriver);
                                                    setOpen(false);
                                                }}
                                            >
                                                <Check
                                                    className={`mr-2 h-4 w-4 ${selectedDriverUsername.toUpperCase() === framework.username ? "opacity-100" : "opacity-0"}`}
                                                />
                                                {framework.username}
                                            </CommandItem>
                                        ))}
                                    </CommandGroup>
                                </Command>
                            </PopoverContent>
                        </Popover>
                    )}
                    <Button
                        variant="default"
                        size="lg"
                        className={buttonVariants({ variant: "default", size: "lg" })}
                        onClick={() => handleAssignJob()}>
                        Assign Job
                    </Button>
                </DialogContent>
            </Dialog>


            <div className='flex flex-row justify-between'>
                {console.log(jobDetails)}
                <h2 className='font-semibold text-3xl'>Booking Overview - {jobDetails.referenceCode.toUpperCase()}</h2>
                <div>
                    {jobDetails.jobtimestamps && (
                        <Button
                            variant="default"
                            size="lg"
                            className={`${buttonVariants({ variant: "default", size: "lg" })} mr-2`}
                        // onClick={() => handleLogClick()}
                        >
                            View Log
                        </Button>
                    )}

                    <Button
                        variant="default"
                        size="lg"
                        className={buttonVariants({ variant: "default", size: "lg" })}
                        onClick={() => handleUpdateJob()}
                    >
                        Save Changes
                    </Button>
                    <Button
                        variant="default"
                        size="lg"
                        className={`${buttonVariants({ variant: "default", size: "lg" })} ml-2`}
                        onClick={() => setIsDialogOpen(true)}
                    >
                        Assign Job
                    </Button>
                    <Button
                        variant="default"
                        size="lg"
                        className={`${buttonVariants({ variant: "default", size: "lg" })} ml-2`}
                        onClick={() => cancelJob()}
                    >
                        Cancel Job
                    </Button>
                    <Button
                        variant="default"
                        size="lg"
                        className={`${buttonVariants({ variant: "default", size: "lg" })} ml-2`}
                        onClick={() => handleJobDelete()}
                    >
                        Delete Job
                    </Button>
                </div>
            </div>

            <div className='mt-6'>
                <p className='font-bold text-2xl mb-4'>Pricing Details</p>
                <div className='grid gap-4 grid-cols-1 md:grid-cols-3 mb-4'>
                    <div>
                        <Label htmlFor="price" className="block font-bold mb-2">Price:</Label>
                        <Input
                            value={jobDetails.journeyDetails.price}
                            type="text"
                            id="price"
                            name="price"
                            className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                        />
                    </div>
                    <div>
                        <Label htmlFor="discount" className="block font-bold mb-2">Discount:</Label>
                        <Input
                            value={jobDetails.discount}
                            type="text"
                            id="discount"
                            name="discount"
                            className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                            onChange={handleDiscountChange}
                        />
                    </div>
                </div>
                <ViewReferrerDetails onChange={handleReferrerDetailsChange} data={jobDetails.referrerDetails} />
                <ViewStaffDetails onChange={handleStaffDetailsChange} data={jobDetails.staffDetails} />
                <ViewPatientDetails onChange={handlePatientDetailsChange} data={jobDetails.patientDetails} />
                <ViewJourneyDetails onChange={handleJourneyDetailsChange} data={jobDetails.journeyDetails} />
                <ViewSpecialRequirements onChange={handleSpecialRequirementsChange} data={jobDetails.specialRequirements} />
            </div>
        </div >
    )
};

export default ViewRepatriationDetails;
