import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../Screens/Settings/DASHBOARD_SETTINGS";
import { Input } from "../../Global/UI/Input";
import { Label } from "../../Global/UI/Label";
import { Button, buttonVariants } from "../../Global/UI/Button";
import { Select, SelectContent, SelectItem, SelectTrigger } from '../../Global/UI/Select';

const ReferrerDetails = ({ onChange }) => {
    const [referrerDetails, setReferrerDetails] = useState({
        organisation: '',
        emailAddress: '',
        phoneNumber: '',
        selectedContact: ''
    });
    const [contactsData, setContactsData] = useState([]);

    const getContacts = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/settings/repatriation/contacts`);
            if (response.data.success) {
                setContactsData(response.data.data);
            }
        } catch (error) {
            handleAxiosError(error);
        }
    };
    useEffect(() => {
        getContacts();
    }, []);

    const handleAxiosError = (error) => {
        if (error.response) {
            toast.error("An unknown error has occurred");
        } else if (error.request) {
            toast.error("An unknown error has occurred");
            console.error('No response received:', error.request);
        } else {
            toast.error("An unknown error has occurred");
            console.error('Error setting up the request:', error.message);
        }
    };

    const handleChange = (name, value) => {
        const updatedDetails = {
            ...referrerDetails,
            [name]: value,
        };

        setReferrerDetails(updatedDetails);
        onChange(updatedDetails);
    };

    const handleContactChange = (selectedContact) => {
        const contact = contactsData.find(contact => contact.organisation === selectedContact);

        const updatedDetails = contact ? {
            organisation: contact.organisation || '',
            emailAddress: contact.emailAddress || '',
            phoneNumber: contact.phoneNumber || '',
            selectedContact: selectedContact
        } : {
            organisation: '',
            emailAddress: '',
            phoneNumber: '',
            selectedContact: ''
        };

        setReferrerDetails(updatedDetails);
        onChange(updatedDetails);
    };

    const addToContacts = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/settings/repatriation/add-contact`, {
                organisation: referrerDetails.organisation,
                emailAddress: referrerDetails.emailAddress,
                phoneNumber: referrerDetails.phoneNumber,
            });

            if (response.data.success) {
                toast.success("Added to contacts successfully");
                setReferrerDetails({
                    organisation: '',
                    emailAddress: '',
                    phoneNumber: '',
                    selectedContact: ''  // Reset selected contact after adding
                });
                getContacts()
            } else {
                toast.error("Failed to add to contacts");
            }
        } catch (error) {
            toast.error("Something went wrong, please try again later");
        }
    };

    return (
        <div>
            <div className="flex flex-row items-center justify-between">
                <p className='font-bold text-2xl mb-4'>Referrer Details</p>
                <Button
                    onClick={addToContacts}
                    variant="default"
                    size="default"
                    className={buttonVariants({ variant: "default", size: "default" })}
                >
                    Add to Contacts
                </Button>
            </div>
            <div className='grid gap-4 grid-cols-1 md:grid-cols-3 mb-4'>
                <div>
                    <Label htmlFor="selectedContact" className="block font-bold mb-2">Select Contact:</Label>
                    <Select
                        value={referrerDetails.selectedContact}
                        onValueChange={handleContactChange}
                    >
                        <SelectTrigger>{referrerDetails.selectedContact ? referrerDetails.selectedContact : "Choose Contact"}</SelectTrigger>
                        <SelectContent>
                            {contactsData.map(contact => (
                                <SelectItem key={contact.organisation} value={contact.organisation}>
                                    {contact.organisation}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <Label htmlFor="organisation" className="block font-bold mb-2">Organisation name:</Label>
                    <Input
                        value={referrerDetails.organisation}
                        onChange={(e) => handleChange('organisation', e.target.value)}
                        type="text"
                        id="organisation"
                        name="organisation"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="emailAddress" className="block font-bold mb-2">Email Address:</Label>
                    <Input
                        value={referrerDetails.emailAddress}
                        onChange={(e) => handleChange('emailAddress', e.target.value)}
                        type="text"
                        id="emailAddress"
                        name="emailAddress"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="phoneNumber" className="block font-bold mb-2">Phone Number:</Label>
                    <Input
                        value={referrerDetails.phoneNumber}
                        onChange={(e) => handleChange('phoneNumber', e.target.value)}
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
            </div>
        </div>
    );
};

export default ReferrerDetails;