import React from "react";
import { Checkbox } from "../../Global/UI/checkbox";
import { Table, TableBody, TableHead, TableHeader, TableRow, TableCell } from "../../Global/UI/table";

const CleaningLogTable = ({ items, labels, checkedItems }) => {
    return (
        <div className="w-1/2">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className=""></TableHead>
                        {labels.map((label, index) => (
                            <TableHead key={index} className="">{label}</TableHead>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell className="font-medium">{item}</TableCell>
                            {labels.map((label, labelIndex) => (
                                <TableCell key={labelIndex}>
                                    <Checkbox checked={checkedItems.some(
                                        (checkedItem) => checkedItem.item === item && checkedItem.label === label
                                    )} />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default CleaningLogTable;
