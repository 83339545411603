const PersonelData = {
   "resourceRequirements" : [
    {
        "riskScoreTopRange": "18",
        "FirstAider": '2',
        "AmbAider": '0',
        "Doctor": '0',
        "Nurse": '0',
        "AmbManager": '0',
        "Vehicles":{
            "ambulance": '0',
            "Support": '0',
        }
    },
    {
        "riskScoreTopRange": "20",
        "FirstAider": '4',
        "AmbAider": '0',
        "Doctor": '0',
        "Nurse": '0',
        "AmbManager": '0',
        "Vehicles":{
            "ambulance": '0',
            "Support": '0',
        }
    },
    {
        "riskScoreTopRange": "25",
        "FirstAider": '6',
        "AmbAider": '2',
        "Doctor": '0',
        "Nurse": '0',
        "AmbManager": 'Visit',
        "Vehicles":{
            "ambulance": '1',
            "Support": '0',
        }
    },
    {
        "riskScoreTopRange": "30",
        "FirstAider": '8',
        "AmbAider": '2',
        "Doctor": '0',
        "Nurse": '0',
        "AmbManager": 'Visit',
        "Vehicles":{
            "ambulance": '1',
            "Support": '0',
        }
    },
    {
        "riskScoreTopRange": "35",
        "FirstAider": '12',
        "AmbAider": '8',
        "Doctor": '1',
        "Nurse": '2',
        "AmbManager": '1',
        "Vehicles":{
            "ambulance": '2',
            "Support": '0',
        }
    },
    {
        "riskScoreTopRange": "40",
        "FirstAider": '20',
        "AmbAider": '10',
        "Doctor": '2',
        "Nurse": '4',
        "AmbManager": '1',
        "Vehicles":{
            "ambulance": '3',
            "Support": '0',
        }
    },
    {
        "riskScoreTopRange": "50",
        "FirstAider": '40',
        "AmbAider": '12',
        "Doctor": '3',
        "Nurse": '6',
        "AmbManager": '2',
        "Vehicles":{
            "ambulance": '4',
            "Support": '1',
        }
    },
    {
        "riskScoreTopRange": "60",
        "FirstAider": '60',
        "AmbAider": '12',
        "Doctor": '4',
        "Nurse": '8',
        "AmbManager": '2',
        "Vehicles":{
            "ambulance": '4',
            "Support": '1',
        }
    },
    {
        "riskScoreTopRange": "65",
        "FirstAider": '80',
        "AmbAider": '14',
        "Doctor": '5',
        "Nurse": '10',
        "AmbManager": '3',
        "Vehicles":{
            "ambulance": '5',
            "Support": '1',
        }
    },
    {
        "riskScoreTopRange": "70",
        "FirstAider": '100',
        "AmbAider": '16',
        "Doctor": '6',
        "Nurse": '12',
        "AmbManager": '4',
        "Vehicles":{
            "ambulance": '6',
            "Support": '2',
        }
    },
    {
        "riskScoreTopRange": "75",
        "FirstAider": '150',
        "AmbAider": '24',
        "Doctor": '9',
        "Nurse": '18',
        "AmbManager": '6',
        "Vehicles":{
            "ambulance": '10',
            "Support": '3',
        }
    },
    {
        "riskScoreTopRange": "1000",
        "FirstAider": '200+',
        "AmbAider": '35+',
        "Doctor": '12+',
        "Nurse": '24+',
        "AmbManager": '8+',
        "Vehicles":{
            "ambulance": '15+',
            "Support": '3',
        }
    },
   ]
};

export default PersonelData;
