import React from 'react'
import Logo from "../../Assets/Images/JaceLOGO2.png";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/Global/UI/table"
import { Checkbox } from '../../Components/Global/UI/checkbox';

const InvoicingShift = ({ loading, invoices, onSelectedItemsChange }) => {

  const [selectedItems, setSelectedItems] = React.useState([]);


  const toggleRowSelection = (item) => {
    setSelectedItems(prevSelected => {
      const isSelected = prevSelected.some(selectedItem => selectedItem._id === item._id);

      if (isSelected) {
        const newSelected = prevSelected.filter(selectedItem => selectedItem._id !== item._id);
        console.log("New selected items:", newSelected);
        onSelectedItemsChange(newSelected); // Call onChanged with newSelected
        return newSelected;
      } else {
        const newSelected = [...prevSelected, item];
        console.log("New selected items:", newSelected);
        onSelectedItemsChange(newSelected); // Call onChanged with newSelected
        return newSelected;
      }
    });
  };

  React.useEffect(() => {
    if (invoices) {
      const defaultSelected = invoices.reduce((selected, item) => {
        if (!item.invoiced) {
          console.log('Use Effect Item:', item)
          return [...selected, item];
        }
        return selected;
      }, []);
      setSelectedItems(defaultSelected);
      onSelectedItemsChange(defaultSelected);
      console.log(defaultSelected);
    }
  }, [invoices]);

  const calculateTotalPrice = () => {
    if (!invoices) return '£0.00';
    const totalPrice = invoices.reduce((total, item) => total + parseFloat(item.jobDetails.price), 0);
    return '£' + totalPrice.toLocaleString('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div>
      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-[500px 1fr 500px 1fr] gap-4 mt-4">
          {[...Array(15)].map((_, index) => (
            <div
              key={index}
              className="overflow-hidden border border-gray-200 rounded-md animate-pulse"
            >
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Selected</TableHead>
                <TableHead>Trust</TableHead>
                <TableHead>Reference</TableHead>
                <TableHead>Log On</TableHead>
                <TableHead>Log Off</TableHead>
                <TableHead>Total Hours</TableHead>
                <TableHead>Total Miles</TableHead>
                <TableHead>Vehicle</TableHead>
                <TableHead>Start Date</TableHead>
                <TableHead>Price</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!loading ? invoices.map((item, index) => (
                <TableRow className='cursor-pointer' onClick={() => toggleRowSelection(item)} key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selectedItems.some(item => item._id === invoices[index]._id)}
                    />
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <img
                        src={Logo}
                        className="h-8 w-8 rounded-full"
                        alt=""
                      />
                      <span>{item.jobDetails ? item.jobDetails.trust : "N/A"}</span>
                    </div>
                  </TableCell>
                  <TableCell>{item.jobDetails.referenceCode.toUpperCase()}</TableCell>
                  <TableCell>{item.shiftDetails.logonTime}</TableCell>
                  <TableCell>{item.shiftDetails.logoffTime}</TableCell>
                  <TableCell>{item.shiftDetails.totalHours
                    ? item.shiftDetails.totalHours.toFixed(2)
                    : ""}</TableCell>
                  <TableCell>{item.jobs.totalMiles.toFixed(0)}</TableCell>
                  <TableCell>
                    {item.jobDetails.vehicle}
                  </TableCell>
                  <TableCell>
                    {new Date(
                      item.jobDetails.jobStartDate
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </TableCell>
                  <TableCell>
                    £{parseFloat(item.jobDetails.price).toLocaleString('en-GB', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                </TableRow>
              )) : 'Loading...'}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className="font-medium" colSpan={9}>Total</TableCell>
                <TableCell >{calculateTotalPrice()}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </>
      )}
    </div>
  );
};

export default InvoicingShift;
