import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Screens/Settings/DASHBOARD_SETTINGS';
import { Input } from '../../Global/UI/Input';
import { Button } from '../../Global/UI/Button';
import { Select, SelectContent, SelectItem, SelectTrigger } from '../../Global/UI/Select';
import { toast } from 'react-toastify';

const ManageStaff = () => {
    const [staffRoles, setStaffRoles] = useState([]);
    const [staffRoleData, setStaffRoleData] = useState({ name: '', salary: '' });
    const [selectedStaffRole, setSelectedStaffRole] = useState(null);

    const handleLoadStaffRoles = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/settings/repatriation/staff`);
            if (response.data.success === true) {
                setStaffRoles(response.data.data);
                toast.success('Staff roles have been successfully fetched!');
            } else {
                toast.error('Staff roles failed to load!');
            }
        } catch (error) {
            toast.error(error.msg);
        }
    };

    const handleEditStaffRole = async () => {
        if (!selectedStaffRole) return;
        try {
            const response = await axios.put(`${BASE_URL}/api/settings/repatriation/staff/${selectedStaffRole._id}`, staffRoleData);

            const data = response.data;

            if (data.success === true) {
                const updatedStaffRoles = staffRoles.map(role =>
                    role._id === selectedStaffRole._id ? data.data : role
                );
                setStaffRoles(updatedStaffRoles);
                setStaffRoleData({ name: '', salary: '' });
                toast.success(`${selectedStaffRole.name} has been successfully updated!`);
                setSelectedStaffRole(null);
            } else {
                toast.error('Failed to edit staff role!');
            }
        } catch (error) {
            toast.error(error.msg);
        }
    };

    useEffect(() => {
        handleLoadStaffRoles();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setStaffRoleData({
            ...staffRoleData,
            [name]: value
        });
    };

    const handleAddStaffRole = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/settings/repatriation/staff`, staffRoleData);
            if (response.data.success) {
                setStaffRoles(response.data.data);
                setStaffRoleData({ name: '', salary: '' });
                toast.success('Staff role has been successfully added!');
            } else {
                toast.error('Failed to add staff role!');
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleDeleteStaffRole = (id) => {
        axios.delete(`${BASE_URL}/api/settings/repatriation/staff/${id}`)
            .then(() => {
                setStaffRoles(staffRoles.filter(role => role._id !== id));
                if (selectedStaffRole && selectedStaffRole._id === id) {
                    setSelectedStaffRole(null);
                    setStaffRoleData({ name: '', salary: '' });
                }
                toast.success('Staff role has been successfully deleted!');
            })
            .catch(error => {
                toast.error('There was an error deleting the staff role!');
            });
    };

    const handleDeselectStaffRole = () => {
        setSelectedStaffRole(null);
        setStaffRoleData({ name: '', salary: '' });
    };

    useEffect(() => {
        if (selectedStaffRole) {
            setStaffRoleData({
                name: selectedStaffRole.name,
                salary: selectedStaffRole.salary
            });
        } else {
            setStaffRoleData({ name: '', salary: '' });
        }
    }, [selectedStaffRole]);

    return (
        <div className='border rounded px-4 py-4'>
            <p className='font-semibold text-lg'>Manage Staff</p>
            <div className='grid grid-cols-1 gap-2 py-2'>
                <Select value={selectedStaffRole ? selectedStaffRole._id : 'deselect'} onValueChange={(value) => {
                    if (value === 'deselect') {
                        handleDeselectStaffRole();
                    } else {
                        setSelectedStaffRole(staffRoles.find(role => role._id === value));
                    }
                }}>
                    <SelectTrigger>{selectedStaffRole ? selectedStaffRole.name : "Select Staff Role"}</SelectTrigger>
                    <SelectContent>
                        <SelectItem value="deselect">
                            Deselect
                        </SelectItem>
                        {staffRoles.map(role => (
                            <SelectItem key={role._id} value={role._id}>
                                {role.name}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <h2 className='font-thin'>{selectedStaffRole ? "Edit Staff Role" : "Add New Staff Role"}</h2>
            <div className='grid grid-cols-1 gap-2 py-2'>
                <Input
                    type="text"
                    name="name"
                    value={staffRoleData.name}
                    onChange={handleInputChange}
                    placeholder="Role"
                />
                <Input
                    type="text"
                    name="salary"
                    value={staffRoleData.salary}
                    onChange={handleInputChange}
                    placeholder="Salary"
                />
            </div>
            <div className='grid grid-cols-1 gap-2'>
                <Button className='w-full' onClick={selectedStaffRole ? handleEditStaffRole : handleAddStaffRole}>
                    {selectedStaffRole ? "Edit Staff Role" : "Add Staff Role"}
                </Button>
                {selectedStaffRole && (
                    <Button className='w-full bg-red-400 hover:bg-red-300' onClick={() => handleDeleteStaffRole(selectedStaffRole._id)}>Delete Staff Role</Button>
                )}
            </div>
        </div>
    );
};

export default ManageStaff;