import React from 'react'
import { Input } from "../../../Global/UI/Input"
import { Label } from "../../../Global/UI/Label"
import { Select, SelectContent, SelectItem, SelectTrigger } from "../../../Global/UI/Select"
import axios from 'axios'
import { BASE_URL } from '../../../../Screens/Settings/DASHBOARD_SETTINGS'
import { toast } from 'react-toastify';

const ViewTrustDetails = ({ onChange , data }) => {
    const [jobDetails, setJobDetails] = React.useState({
        trust: data.trust || '',
        costCenter: data.costCenter || '',
        jobStartDate: data.jobStartDate || '',
        price: data.price || '',
        callSign: data.callSign || '',
        lot: data.lot || '',
        vehicle: data.vehicle || '',
        referenceCode: data.referenceCode || ''
    });

    const [loading, setLoading] = React.useState(false)
    const [drivers, setDrivers] = React.useState([])
    const [trusts, setTrusts] = React.useState([])

    const getTrusts = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/settings/nias-renal/trusts`);
            if (response.data.success) {
                console.log(response.data.data)
                setTrusts(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching drivers:', error.message);
            toast.error('Failed to fetch drivers');
        }
    };

    React.useEffect(() => {
        const fetchDrivers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/drivers/getDrivers`);
                if (response.data.success) {
                    setDrivers(response.data.drivers);
                }
            } catch (error) {
                console.error('Error fetching drivers:', error.message);
                toast.error('Failed to fetch drivers');
            }
        };
        fetchDrivers()
        getTrusts()
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setJobDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
        onChange({ ...jobDetails, [name]: value });
    };

   
    const handleCostCenterSelect = (value) => {
        setJobDetails((prevDetails) => ({
            ...prevDetails,
            costCenter: value,
        }));
        onChange({ ...jobDetails, costCenter: value });
    };

    const handleVehicleSelect = (value) => {
        setJobDetails((prevDetails) => ({
            ...prevDetails,
            vehicle: value,
        }));
        onChange({ ...jobDetails, vehicle: value });
    };

    const handleSelectTrust = (value) => {
        const selectedTrust = trusts.find(trust => trust.name === value);
        setJobDetails((prevDetails) => ({
            ...prevDetails,
            trust: value,
            lot: selectedTrust.lot 
        }));
        onChange({ ...jobDetails, trust: value, lot: selectedTrust.lot });
    };

    return (
        <div className="grid w-full grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col gap-1.5">
                <Label htmlFor="price">Starting Price</Label>
                <Input
                    type="text"
                    id="price"
                    name="price"
                    value={jobDetails.price}
                    onChange={handleInputChange}
                />
            </div>
            <div className="flex flex-col gap-1.5">
                <Label htmlFor="costCenter">Trust</Label>
                <Select value={jobDetails.trust} onValueChange={handleSelectTrust}>
                    <SelectTrigger>{jobDetails.trust ? jobDetails.trust : 'Select Trust'}</SelectTrigger>
                    <SelectContent>
                        {trusts.map((trust) => (
                            <SelectItem key={trust.name} value={trust.name}>
                                {trust.name}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <div className="flex flex-col gap-1.5">
                <Label htmlFor="costCenter">Cost Center</Label>
                <Select value={jobDetails.costCenter} onValueChange={handleCostCenterSelect}>
                    <SelectTrigger>{jobDetails.costCenter ? jobDetails.costCenter : 'Select Cost Center'}</SelectTrigger>
                    <SelectContent>
                        <SelectItem value="FCAE01">EAC</SelectItem>
                        <SelectItem value="FCPE01">NEAC</SelectItem>
                    </SelectContent>
                </Select>
            </div>
            <div className="flex flex-col gap-1.5">
                <Label htmlFor="jobStartDate">Job Start Date</Label>
                <Input
                    type="date"
                    id="jobStartDate"
                    name="jobStartDate"
                    value={jobDetails.jobStartDate}
                    onChange={handleInputChange}
                />
            </div>
            <div className="flex flex-col gap-1.5">
                <Label htmlFor="vehicle">Vehicle</Label>
                <Select value={jobDetails.vehicle} onValueChange={handleVehicleSelect}>
                    <SelectTrigger>{jobDetails.vehicle ? jobDetails.vehicle : 'Select Vehicle'}</SelectTrigger>
                    <SelectContent>
                        {drivers.map((driver) => (
                            <SelectItem key={driver.username} value={driver.username}>
                                {driver.username}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <div className="flex flex-col gap-1.5">
                <Label htmlFor="callSign">Call Sign</Label>
                <Input
                    type="text"
                    id="callSign"
                    name="callSign"
                    value={jobDetails.callSign}
                    onChange={handleInputChange}
                />
            </div>
            <div className="flex flex-col gap-1.5">
                <Label htmlFor="lot">Lot Number</Label>
                <Input
                    type="text"
                    id="lot"
                    name="lot"
                    value={jobDetails.lot}
                    onChange={handleInputChange}
                    placeholder="1"
                />
            </div>
        </div>
    );
}

export default ViewTrustDetails;
