import React, { useState, useEffect } from "react";
import Logo from "../../../Assets/Images/JaceLOGO2.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Button, buttonVariants } from "../../Global/UI/Button";
import { toast } from "react-toastify";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../Components/Global/UI/table";
import { BASE_URL } from "../../../Screens/Settings/DASHBOARD_SETTINGS";

const RepatriationJobList = () => {

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}/api/repatriation/all-jobs`
        );
        console.log(response.data)
        if (response.data.success) {
          setLoading(false);
          setItems(response.data.results);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            setLoading(false);
            toast.error(error.response.data.message);
            console.error("Item not found:", error.response.data.message);
          }
        } else if (error.request) {
          setLoading(false);
          toast.error("An unknown error has occurred");
          console.error("No response received:", error.request);
        } else {
          setLoading(false);
          toast.error("An unknown error has occurred");
          console.error("Error setting up the request:", error.message);
        }
      }
    };

    fetchData();
  }, []);


  const history = useHistory();

  const handleViewClick = (jobID) => {
    history.push({
      pathname: `/view-repatriation-job/${jobID}`,
    });
  };

  const JobStatus = ({ status }) => {
    let statusText, textColor, bgColor;

    switch (status) {
      case 0:
        statusText = 'Unassigned';
        textColor = 'text-red-800';
        bgColor = 'bg-red-300';
        break;
      case 1:
        statusText = 'Assigned';
        textColor = 'text-yellow-800';
        bgColor = 'bg-yellow-300';
        break;
      case 2:
        statusText = 'Accepted';
        textColor = 'text-green-800';
        bgColor = 'bg-green-300';
        break;
      case 3:
        statusText = 'Started';
        textColor = 'text-orange-800';
        bgColor = 'bg-orange-300';
        break;
      case 4:
        statusText = 'On Scene';
        textColor = 'text-blue-800';
        bgColor = 'bg-blue-300';
        break;
      case 5:
        statusText = 'Left Scene';
        textColor = 'text-indigo-800';
        bgColor = 'bg-indigo-300';
        break;
      case 6:
        statusText = 'Completed';
        textColor = 'text-teal-800';
        bgColor = 'bg-teal-300';
        break;
      case 7:
        statusText = 'Cancelled';
        textColor = 'text-white';
        bgColor = 'bg-rose-500';
        break;
      case 8:
        statusText = 'Quote Pending';
        textColor = 'text-white';
        bgColor = 'bg-orange-500';
        break;
      case 9:
        statusText = 'Quote Accepted';
        textColor = 'text-white';
        bgColor = 'bg-green-600';
        break;
      case 10:
        statusText = 'Quote Declined';
        textColor = 'text-white';
        bgColor = 'bg-rose-500';
        break;

      default:
        statusText = 'Unknown';
        textColor = 'text-gray-800';
        bgColor = 'bg-gray-300';
    }

    return (
      <div className={`px-2 py-1 rounded text-center mr-3 text-bold ${bgColor} ${textColor}`}>
        {statusText}
      </div>
    );
  };



  return (
    <div className='w-full overflow-x-scroll md:overflow-x-hidden mt-4'>
      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-[500px 1fr 500px 1fr] gap-4 mt-4">
          {[...Array(15)].map((_, index) => (
            <div key={index} className="overflow-hidden border border-gray-200 rounded-md animate-pulse">
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>DOB</TableHead>
                <TableHead>Reference</TableHead>
                <TableHead>Collection Time</TableHead>
                <TableHead>From</TableHead>
                <TableHead>To</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!loading ? items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <div className="flex items-center">
                      <img
                        src={Logo}
                        className="h-8 w-8 rounded-full"
                        alt=""
                      />
                      <span>{item.patientDetails ? item.patientDetails.name : "N/A"}</span>
                    </div>
                  </TableCell>
                  <TableCell>{item.patientDetails ? item.patientDetails.dob : "N/A"}</TableCell>
                  <TableCell>{item.referenceCode.toUpperCase()}</TableCell>
                  <TableCell>{new Date(item.journeyDetails.journeys[0].dateTimePickup).toLocaleString()}</TableCell>
                  <TableCell>{item.journeyDetails.journeys[0].pickupLocation === "Other" ? item.journeyDetails.journeys[0].otherPickupLocation : item.journeyDetails.journeys[0].pickupLocation}</TableCell>
                  <TableCell>{item.journeyDetails.journeys[0].dropOffLocation === "Other" ? item.journeyDetails.journeys[0].otherDropOffLocation : item.journeyDetails.journeys[0].dropOffLocation}</TableCell>
                  <TableCell>
                    <JobStatus status={item.JobStatus} />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="default"
                      size="default"
                      className={buttonVariants({ variant: "default", size: "default" })}
                      onClick={() => handleViewClick(item._id)}>
                      View Job
                    </Button>
                  </TableCell>
                </TableRow>
              )) : 'Loading...'}
            </TableBody>
          </Table>
        </>
      )}
    </div>
  )
}

export default RepatriationJobList