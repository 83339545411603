import React from 'react';
import { Label } from "../../Global/UI/Label";
import { Input } from '../../Global/UI/Input';
import PersonelData from '../../../Data/VehicleAssessmentData';

const StaffDetails = ({ onChange, riskAssessmentScore }) => {
    const [staffDetails, setStaffDetails] = React.useState({
        FirstAider: '',
        AmbAider: '',
        Doctor: '',
        Nurse: '',
        AmbManager: '',
        Vehicles:{
            ambulance: '',
            Support: '',
        }
    });

    React.useEffect(() => {
        const getResourceRequirements = (score) => {
            return PersonelData.resourceRequirements.find(req => score <= req.riskScoreTopRange) || {};
        };

        const requirements = getResourceRequirements(riskAssessmentScore);
        const updatedDetails = {
            FirstAider: requirements.FirstAider || '',
            AmbAider: requirements.AmbAider || '',
            Doctor: requirements.Doctor || '',
            Nurse: requirements.Nurse || '',
            AmbManager: requirements.AmbManager || '',
            Vehicles: {
                ambulance: requirements.Vehicles?.ambulance || '',
                Support: requirements.Vehicles?.Support || '',
            }
        };
        
        setStaffDetails(updatedDetails);
        onChange(updatedDetails);
    }, [riskAssessmentScore]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedDetails = {
            ...staffDetails,
            [name]: value,
        };

        setStaffDetails(updatedDetails);
        onChange(updatedDetails);
    };

    console.log('Risk Assessment Score:' , riskAssessmentScore)

    return (
        <div>
            <p className='font-bold text-2xl mb-4'>Staff Details</p>
            <div className='grid gap-4 grid-cols-1 md:grid-cols-3 mb-4'>
                <div>
                    <Label htmlFor="FirstAider" className="block font-bold mb-2">First Aiders:</Label>
                    <Input
                        value={staffDetails.FirstAider}
                        onChange={handleChange}
                        type="number"
                        id="FirstAider"
                        name="FirstAider"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="AmbAider" className="block font-bold mb-2">Ambulance Aiders:</Label>
                    <Input
                        value={staffDetails.AmbAider}
                        onChange={handleChange}
                        type="number"
                        id="AmbAider"
                        name="AmbAider"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="Doctor" className="block font-bold mb-2">Doctors:</Label>
                    <Input
                        value={staffDetails.Doctor}
                        onChange={handleChange}
                        type="number"
                        id="Doctor"
                        name="Doctor"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="Nurse" className="block font-bold mb-2">Nurses:</Label>
                    <Input
                        value={staffDetails.Nurse}
                        onChange={handleChange}
                        type="number"
                        id="Nurse"
                        name="Nurse"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="AmbManager" className="block font-bold mb-2">Ambulance Managers:</Label>
                    <Input
                        value={staffDetails.AmbManager}
                        onChange={handleChange}
                        type="number"
                        id="AmbManager"
                        name="AmbManager"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="Ambulanaces" className="block font-bold mb-2">Ambulanace Vehicles:</Label>
                    <Input
                        value={staffDetails.Vehicles.ambulance}
                        onChange={handleChange}
                        type="number"
                        id="Ambulanaces"
                        name="Ambulanaces"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="Support" className="block font-bold mb-2">Ambulanaces Support Vehicles:</Label>
                    <Input
                        value={staffDetails.Vehicles.Support}
                        onChange={handleChange}
                        type="number"
                        id="Support"
                        name="Support"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
            </div>
        </div>
    );
};

export default StaffDetails;
