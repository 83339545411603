import React from 'react'
import { Label } from "@radix-ui/react-label";
import { Input } from "../Global/UI/Input";
import { Select, SelectContent, SelectItem, SelectTrigger } from '../Global/UI/Select';

const ShiftJobTypes = [
    {
        value: "Paramedic",
        label: "Paramedic",
    },
    {
        value: "EMT",
        label: "EMT",
    },
    {
        value: "ACA",
        label: "ACA",
    },
];

const StaffCard = ({ staff, onStaffNameChange, onRemoveStaff, onJobTypeChange }) => {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState("");

    return (
        <div className="border p-4 my-4 flex flex-col" >
            <div className="flex flex-row justify-between w-full">
                <div></div>
                <button
                    onClick={() => onRemoveStaff(staff.id)}
                >
                    X
                </button>
            </div>
            <div className="grid grid-cols-2 gap-4 items-center mb-4">
                <div className="grid w-full max-w-sm gap-1.5">
                    <Label htmlFor={`staffName${staff.id}`}>Name</Label>
                    <Input
                        type="text"
                        id={`staffName${staff.id}`}
                        value={staff.name}
                        onChange={(e) => onStaffNameChange(staff.id, e.target.value)}
                    />
                </div>
                <div className="grid w-full max-w-sm gap-1.5">
                    <Label htmlFor={`framework${staff.id}`}>Job Role</Label>
                    <Select value={staff.jobType} onValueChange={onJobTypeChange}>
                        <SelectTrigger>{staff.jobType ? staff.jobType : 'Select Role'}</SelectTrigger>
                        <SelectContent>
                            {ShiftJobTypes.map((item) => (
                                <SelectItem  key={item.label} value={item.value}>
                                    {item.label}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </div >
    );
};

export default StaffCard;