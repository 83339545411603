import { useState } from "react";
import { Label } from "../../../Global/UI/Label";
import { Input } from "../../../Global/UI/Input";

const ViewReferrerDetails = ({ onChange, data }) => {
    const [referrerDetails, setReferrerDetails] = useState({
        organisation: data.organisation || '',
        emailAddress: data.emailAddress || '',
        phoneNumber: data.phoneNumber || '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updates = { [name]: value };

        const updatedDetails = {
            ...referrerDetails,
            ...updates,
        };

        setReferrerDetails(updatedDetails);
        onChange(updatedDetails);
    };

    return (
        <div>
            <p className='font-bold text-2xl mb-4'>Referrer Details</p>
            <div className='grid gap-4 grid-cols-1 md:grid-cols-3 mb-4'>
                <div>
                    <Label htmlFor="organisation" className="block font-bold mb-2">Organisation name:</Label>
                    <Input
                        value={referrerDetails.organisation}
                        onChange={handleChange}
                        type="text"
                        id="organisation"
                        name="organisation"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="emailAddress" className="block font-bold mb-2">Email Address:</Label>
                    <Input
                        value={referrerDetails.emailAddress}
                        onChange={handleChange}
                        type="text"
                        id="emailAddress"
                        name="emailAddress"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="phoneNumber" className="block font-bold mb-2">Phone Number:</Label>
                    <Input
                        value={referrerDetails.phoneNumber}
                        onChange={handleChange}
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
            </div>
        </div>
    );
};

export default ViewReferrerDetails;
