import { useState } from 'react';
import Logo from '../Assets/Images/JaceLogo.png'
import { useAuth } from '../Context/AuthProvider';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Button } from '../Components/Global/UI/Button';
import { BASE_URL } from "../Screens/Settings/DASHBOARD_SETTINGS";

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let history = useHistory()
  const { createAuthData } = useAuth();
  const [loading , setLoading] = useState(false)

  const handleLogin = async () => {
    setLoading(true)
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/login`, {
        email: email.toLowerCase(),
        password
      });

      if (response.data.success === true) {
        setLoading(false)
        createAuthData(
          response.data.authtoken,
          response.data.userId,
          response.data.email,
          response.data.role,
          response.data.name,
          response.data.avatar,
        );
        toast.success('🔥 Successfully Logged In. Redirecting...');
        history.push('/')
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setLoading(false)
          toast.error(error.response.data.msg);
          console.error('Validation errors:', error.response.data.errors);
        }
      } else if (error.request) {
        setLoading(false)
        toast.error("An unknown error has occured");
        console.error('No response received:', error.request);
      } else {
        setLoading(false)
        toast.error("An unknown error has occured");
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  return (
    <div className="flex h-screen justify-center items-center flex-col px-2 lg:px-8">
      <div className="sm:w-full sm:max-w-sm">
        <img className="mx-auto h-20 w-auto" src={Logo} alt="Jace Medical Logo" />
      </div>

      <div className="mt-10 w-3/4 sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
            <div className="mt-2">
              <input onChange={(e) => setEmail(e.target.value)} id="email" name="email" type="email" autoComplete="email" required className="appearance-none block w-full rounded-md border-1 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-400 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
              <div className="text-sm">
                {/* <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</a> */}
              </div>
            </div>
            <div className="mt-2">
              <input onChange={(e) => setPassword(e.target.value)} id="password" name="password" type="password" autoComplete="current-password" required className="appearance-none block w-full rounded-md border-1 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-400 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div>
            <Button disabled={loading} onClick={() => handleLogin(email, password)} className={`w-full ${loading ? 'opacity-75' : ''}`}>{loading ? 'Logging in...' : 'Sign in'}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginScreen
