import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Screens/Settings/DASHBOARD_SETTINGS';
import { Input } from '../../Global/UI/Input';
import { Button } from '../../Global/UI/Button';
import { Select, SelectContent, SelectItem, SelectTrigger } from '../../Global/UI/Select';
import { toast } from 'react-toastify';

const AddRenalLocations = () => {
    const [locations, setLocations] = useState([]);
    const [locationData, setLocationData] = useState({
        name: '',
        address: ''
    });
    const [selectedLocation, setSelectedLocation] = useState(null);

    // Handle Loading Locations
    const handleLoadLocations = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/settings/nias-renal/locations`);
            if (response.data.success) {
                setLocations(response.data.data);
                toast.success('Locations have been successfully fetched!');
            } else {
                toast.error(response.data.msg);
            }
        } catch (error) {
            toast.error(error.response?.data?.msg || 'Error fetching locations');
        }
    };

    // Handle Edit Location
    const handleEditLocation = async () => {
        if (!selectedLocation) return;
        try {
            const response = await axios.put(`${BASE_URL}/api/settings/nias-renal/locations/${selectedLocation._id}`, locationData);
            if (response.data.success) {
                const updatedLocations = locations.map(location =>
                    location._id === selectedLocation._id ? response.data.data : location
                );
                setLocations(updatedLocations);
                resetLocationData();
                toast.success(`${selectedLocation.name} has been successfully updated!`);
                setSelectedLocation(null);
            } else {
                toast.error('Failed to edit location!');
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    // Handle Input Change for Locations
    const handleLocationInputChange = (e) => {
        const { name, value } = e.target;
        setLocationData({
            ...locationData,
            [name]: value
        });
    };

    // Handle Add Location
    const handleAddLocation = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/settings/nias-renal/locations`, locationData);
            if (response.data.success) {
                setLocations(response.data.data.locations);
                resetLocationData();
                toast.success('Location has been successfully added!');
            } else {
                toast.error('Failed to add location!');
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    // Handle Delete Location
    const handleDeleteLocation = async (id) => {
        try {
            const response = await axios.delete(`${BASE_URL}/api/settings/nias-renal/locations/${id}`);
            if (response.data.success) {
                setLocations(locations.filter(location => location._id !== id));
                if (selectedLocation && selectedLocation._id === id) {
                    setSelectedLocation(null);
                    resetLocationData();
                }
                toast.success('Location has been successfully deleted!');
            } else {
                toast.error('Failed to delete location!');
            }
        } catch (error) {
            toast.error('Failed to delete location!');
        }
    };

    // Handle Deselect Location
    const handleDeselectLocation = () => {
        setSelectedLocation(null);
        resetLocationData();
    };

    // Reset Location Data
    const resetLocationData = () => {
        setLocationData({
            name: '',
            address: ''
        });
    };

    // Load Locations on component mount
    useEffect(() => {
        handleLoadLocations();
    }, []);

    // Update Location Data when a location is selected
    useEffect(() => {
        if (selectedLocation) {
            setLocationData({
                name: selectedLocation.name,
                address: selectedLocation.address
            });
        } else {
            resetLocationData();
        }
    }, [selectedLocation]);

    return (
        <div className='border rounded px-4 py-4'>
            <p className='font-semibold text-lg'>Manage Locations</p>

            <div className='py-4'>
                <h2 className='font-thin'>Locations</h2>
                <div className='grid grid-cols-1 gap-2 py-2'>
                    <Select value={selectedLocation ? selectedLocation._id : 'deselect'} onValueChange={(value) => {
                        if (value === 'deselect') {
                            handleDeselectLocation();
                        } else {
                            setSelectedLocation(locations.find(location => location._id === value));
                        }
                    }}>
                        <SelectTrigger>{selectedLocation ? selectedLocation.name : "Select Location"}</SelectTrigger>
                        <SelectContent>
                            <SelectItem value="deselect">
                                Deselect
                            </SelectItem>
                            {locations.map(location => (
                                <SelectItem key={location._id} value={location._id}>
                                    {location.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <h2 className='font-thin'>{selectedLocation ? "Edit Location" : "Add New Location"}</h2>
                <div className='grid grid-cols-1 gap-2 py-2'>
                    <Input
                        type="text"
                        name="name"
                        value={locationData.name}
                        onChange={handleLocationInputChange}
                        placeholder="Name"
                    />
                    <Input
                        type="text"
                        name="address"
                        value={locationData.address}
                        onChange={handleLocationInputChange}
                        placeholder="Address"
                    />
                </div>
            </div>
            <div className='grid grid-cols-1 gap-2'>
                <Button className='w-full' onClick={selectedLocation ? handleEditLocation : handleAddLocation}>
                    {selectedLocation ? "Edit Location" : "Add Location"}
                </Button>
                {selectedLocation && (
                    <Button className='w-full bg-red-400 hover:bg-red-300' onClick={() => handleDeleteLocation(selectedLocation._id)}>Delete Location</Button>
                )}
            </div>
        </div>
    );
};

export default AddRenalLocations;
