import React from 'react';
import logo from '../../Assets/Images/image.png';
import { IoIosPerson, IoIosCalendar } from 'react-icons/io';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const BookingCard = ({
  item,
}) => {

  const history = useHistory();

  const handleViewClick = () => {
    history.push({
      pathname: '/full-overview',
      state: { item: item },
    });
  };


  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  return (
    <div className="test-card mb-2 rounded bg-slate-100 p-4 text-gray-900">
      <div className="flex items-center mb-4">
        <div className="bg-teal-400 py-2 px-2 rounded w-fit">
          <img src={logo} alt="Logo" width={20} />
        </div>

        <div className={`ml-2 flex flex-row w-full justify-between`}>
          <p className="font-semibold pr-4">
            {item.name} | <span style={{ whiteSpace: 'nowrap' }}>{item.dob} ({calculateAge(item.dob)} years old) </span>
          </p>
          <p className="font-semibold text-teal-400 pr-4">#{item.referenceCode.toUpperCase()}</p>
        </div>
      </div>

      <div>
        <p className="font-semibold">Overview</p>
        <div className="flex flex-wrap gap-4 mt-2">
          <div className="flex items-center">
            <IoIosCalendar className="mr-1" />
            {new Date(item.dateTimePickup).toLocaleString()}
          </div>
          <div className="flex items-center">
            <IoIosCalendar className="mr-1" />
            <p>{item.pickupLocation === 'Other' ? item.otherLocation : item.pickupLocation}</p>
          </div>
          <div className="flex items-center">
            <IoIosCalendar className="mr-1" />
            <p>{item.dropOffLocation === 'Other' ? item.otherDropOffLocation : item.dropOffLocation}</p>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-4">
        <button onClick={handleViewClick} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700">
          View
        </button>
        <button className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700">
          Delete
        </button>
      </div>
    </div>
  );
};

export default BookingCard;
