import React from 'react'
import Logo from "../../Assets/Images/JaceLOGO2.png";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/Global/UI/table"
import { Checkbox } from '../../Components/Global/UI/checkbox';

const InvoicingRenal = ({ loading, invoices, onSelectedItemsChange }) => {

  const [selectedItems, setSelectedItems] = React.useState([]);


  const toggleRowSelection = (item) => {
    setSelectedItems(prevSelected => {
      const isSelected = prevSelected.some(selectedItem => selectedItem._id === item._id);

      if (isSelected) {
        const newSelected = prevSelected.filter(selectedItem => selectedItem._id !== item._id);
        console.log("New selected items:", newSelected);
        onSelectedItemsChange(newSelected); // Call onChanged with newSelected
        return newSelected;
      } else {
        const newSelected = [...prevSelected, item];
        console.log("New selected items:", newSelected);
        onSelectedItemsChange(newSelected); // Call onChanged with newSelected
        return newSelected;
      }
    });
  };

  React.useEffect(() => {
    if (invoices) {
      const defaultSelected = invoices.reduce((selected, item) => {
        if (!item.invoiced) {
          console.log('Use Effect Item:', item)
          return [...selected, item];
        }
        return selected;
      }, []);
      setSelectedItems(defaultSelected);
      onSelectedItemsChange(defaultSelected);
      console.log(defaultSelected);
    }
  }, [invoices]);

  const calculateTotalPrice = () => {
    if (!invoices) return '£0.00';
    const totalPrice = invoices.reduce((total, item) => total + parseFloat(item.jobs.totalPrice), 0);
    return '£' + totalPrice.toLocaleString('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const JobStatus = ({ status }) => {
    let statusText, textColor, bgColor;

    switch (status) {
      case 0:
        statusText = 'Unassigned';
        textColor = 'text-red-800';
        bgColor = 'bg-red-300';
        break;
      case 1:
        statusText = 'Assigned';
        textColor = 'text-yellow-800';
        bgColor = 'bg-yellow-300';
        break;
      case 2:
        statusText = 'Accepted';
        textColor = 'text-green-800';
        bgColor = 'bg-green-300';
        break;
      case 3:
        statusText = 'Started';
        textColor = 'text-orange-800';
        bgColor = 'bg-orange-300';
        break;
      case 4:
        statusText = 'On Scene';
        textColor = 'text-blue-800';
        bgColor = 'bg-blue-300';
        break;
      case 5:
        statusText = 'Left Scene';
        textColor = 'text-indigo-800';
        bgColor = 'bg-indigo-300';
        break;
      case 6:
        statusText = 'Completed';
        textColor = 'text-teal-800';
        bgColor = 'bg-teal-300';
        break;
      case 7:
        statusText = 'Cancelled';
        textColor = 'text-white';
        bgColor = 'bg-rose-500';
        break;

      default:
        statusText = 'Unknown';
        textColor = 'text-gray-800';
        bgColor = 'bg-gray-300';
    }

    return (
      <div
        className={`px-2 py-1 rounded text-center mr-3 text-bold ${bgColor} ${textColor}`}
      >
        {statusText}
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-[500px 1fr 500px 1fr] gap-4 mt-4">
          {[...Array(15)].map((_, index) => (
            <div
              key={index}
              className="overflow-hidden border border-gray-200 rounded-md animate-pulse"
            >
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Selected</TableHead>
                <TableHead>Name</TableHead>
                <TableHead>DOB</TableHead>
                <TableHead>Reference</TableHead>
                <TableHead>Collection Time</TableHead>
                <TableHead>From</TableHead>
                <TableHead>To</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Price</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!loading ? invoices.map((item, index) => (
                <TableRow className='cursor-pointer' onClick={() => toggleRowSelection(item)} key={index}>
                  {console.log('NIAS Renal Item:', item)}
                  <TableCell>
                    <Checkbox
                      checked={selectedItems.some(item => item._id === invoices[index]._id)}
                    />
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <img
                        src={Logo}
                        className="h-8 w-8 rounded-full"
                        alt=""
                      />
                      <span>{item.jobDetails ? item.jobDetails.trust : "N/A"}</span>
                    </div>
                  </TableCell>
                  <TableCell>{item.patientDetails ? item.patientDetails.name : "N/A"}</TableCell>
                  <TableCell>{item.patientDetails ? item.patientDetails.dob : "N/A"}</TableCell>
                  <TableCell>{item.jobDetails.referenceCode.toUpperCase()}</TableCell>
                  <TableCell>{new Date(item.jobs.journeys[0].dateTimePickup).toLocaleString()}</TableCell>
                  <TableCell>{item.jobs.journeys[0].pickupLocation === "Other" ? item.jobs.journeys[0].otherPickupLocation : item.jobs.journeys[0].pickupLocation}</TableCell>
                  <TableCell>{item.jobs.journeys[0].dropOffLocation === "Other" ? item.jobs.journeys[0].otherDropOffLocation : item.jobs.journeys[0].dropOffLocation}</TableCell>
                  <TableCell>
                    <JobStatus status={item.JobStatus} />
                  </TableCell>
                  <TableCell>£{item.jobs.totalPrice}</TableCell>
                </TableRow>
              )) : 'Loading...'}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className="font-medium" colSpan={9}>Total</TableCell>
                <TableCell >{calculateTotalPrice()}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </>
      )}
    </div>
  );
};

export default InvoicingRenal;
