import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Screens/Settings/DASHBOARD_SETTINGS';
import { Input } from '../../Global/UI/Input';
import { Button } from '../../Global/UI/Button';
import { Select, SelectContent, SelectItem, SelectTrigger } from '../../Global/UI/Select';
import { toast } from 'react-toastify';

const AddNIASRenalTrusts = () => {
    const [trusts, setTrusts] = useState([]);
    const [trustData, setTrustData] = useState({
        name: '',
        lot: '',
        ranges: [],
        extraPricePerMile: ''
    });
    const [selectedTrust, setSelectedTrust] = useState(null);

    const handleLoadTrusts = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/settings/nias-renal/trusts`);
            if (response.data.success) {
                setTrusts(response.data.data);
                toast.success('Trusts have been successfully fetched!');
            } else {
                toast.error(response.data.msg);
            }
        } catch (error) {
            console.log('Error:', error)
            toast.error(error.response.data.msg);
        }
    };

    const handleEditTrust = async () => {
        if (!selectedTrust) return;
        try {
            const response = await axios.put(`${BASE_URL}/api/settings/nias-renal/trusts/${selectedTrust._id}`, trustData);
            const data = response.data;

            if (data.success) {
                const updatedTrusts = trusts.map(trust =>
                    trust._id === selectedTrust._id ? data.data : trust
                );
                setTrusts(updatedTrusts);
                resetTrustData();
                toast.success(`${selectedTrust.name} has been successfully updated!`);
                setSelectedTrust(null);
            } else {
                toast.error('Failed to edit trust!');
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTrustData({
            ...trustData,
            [name]: value
        });
    };

    const handleRangeChange = (e, index) => {
        const { name, value } = e.target;
        const newRanges = [...trustData.ranges];
        newRanges[index][name] = value;
        setTrustData({
            ...trustData,
            ranges: newRanges
        });
    };

    const handleAddTrust = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/settings/nias-renal/trusts`, trustData);
            if (response.data.success) {
                setTrusts(response.data.data.trusts);
                resetTrustData();
                toast.success('Trust has been successfully added!');
            } else {
                toast.error('Failed to add trust!');
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleDeleteTrust = async (id) => {
        try {
            const response = await axios.delete(`${BASE_URL}/api/settings/nias-renal/trusts/${id}`);
            if (response.data.success) {

                setTrusts(trusts.filter(trust => trust._id !== id));
                if (selectedTrust && selectedTrust._id === id) {
                    setSelectedTrust(null);
                    resetTrustData();
                }
                toast.success('Trust has been successfully deleted!');
            } else {
                toast.error('Failed to add trust!');
            }
        } catch (error) {
            toast.error('Failed to delete trust!');
        }
    };

    const handleAddRange = () => {
        setTrustData({
            ...trustData,
            ranges: [...trustData.ranges, { mileageRange: '', mileagePrice: '' }]
        });
    };

    const handleDeleteRange = (index) => {
        setTrustData({
            ...trustData,
            ranges: trustData.ranges.filter((_, i) => i !== index)
        });
    };

    const handleDeselectTrust = () => {
        setSelectedTrust(null);
        resetTrustData();
    };

    const resetTrustData = () => {
        setTrustData({
            name: '',
            lot: '',
            ranges: [],
            extraPricePerMile: ''
        });
    };

    useEffect(() => {
        handleLoadTrusts();
    }, []);

    useEffect(() => {
        if (selectedTrust) {
            setTrustData({
                name: selectedTrust.name,
                lot: selectedTrust.lot,
                ranges: selectedTrust.ranges,
                extraPricePerMile: selectedTrust.extraPricePerMile
            });
        } else {
            resetTrustData();
        }
    }, [selectedTrust]);

    return (
        <div className='border rounded px-4 py-4'>
            <p className='font-semibold text-lg'>Manage Trusts</p>
            <div className='grid grid-cols-1 gap-2 py-2'>
                <Select value={selectedTrust ? selectedTrust._id : 'deselect'} onValueChange={(value) => {
                    if (value === 'deselect') {
                        handleDeselectTrust();
                    } else {
                        setSelectedTrust(trusts.find(trust => trust._id === value));
                    }
                }}>
                    <SelectTrigger>{selectedTrust ? selectedTrust.name : "Select Trust"}</SelectTrigger>
                    <SelectContent>
                        <SelectItem value="deselect">
                            Deselect
                        </SelectItem>
                        {trusts.map(trust => (
                            <SelectItem key={trust._id} value={trust._id}>
                                {trust.name}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <h2 className='font-thin'>{selectedTrust ? "Edit Trust" : "Add New Trust"}</h2>
            <div className='grid grid-cols-1 gap-2 py-2'>
                <Input
                    type="text"
                    name="name"
                    value={trustData.name}
                    onChange={handleInputChange}
                    placeholder="Name"
                />
                <Input
                    type="text"
                    name="lot"
                    value={trustData.lot}
                    onChange={handleInputChange}
                    placeholder="Lot Number"
                />
                <Input
                    type="number"
                    name="extraPricePerMile"
                    value={trustData.extraPricePerMile}
                    onChange={handleInputChange}
                    placeholder="Extra Price Per Mile"
                />
                <div className='grid grid-cols-1 gap-2 py-2'>
                    <h3 className='font-thin'>Mileage Ranges</h3>
                    {trustData.ranges.map((range, index) => (
                        <div key={index} className='grid grid-cols-3 gap-2 items-center'>
                            <Input
                                type="number"
                                name="mileageRange"
                                value={range.mileageRange}
                                onChange={(e) => handleRangeChange(e, index)}
                                placeholder="Mileage Range"
                            />
                            <Input
                                type="number"
                                name="mileagePrice"
                                value={range.mileagePrice}
                                onChange={(e) => handleRangeChange(e, index)}
                                placeholder="Mileage Price"
                            />
                            <Button className='bg-red-400 hover:bg-red-300' onClick={() => handleDeleteRange(index)}>Delete</Button>
                        </div>
                    ))}
                    <Button onClick={handleAddRange}>Add Range</Button>
                </div>
            </div>
            <div className='grid grid-cols-1 gap-2'>
                <Button className='w-full' onClick={selectedTrust ? handleEditTrust : handleAddTrust}>
                    {selectedTrust ? "Edit Trust" : "Add Trust"}
                </Button>
                {selectedTrust && (
                    <Button className='w-full bg-red-400 hover:bg-red-300' onClick={() => handleDeleteTrust(selectedTrust._id)}>Delete Trust</Button>
                )}
            </div>
        </div>
    );
};

export default AddNIASRenalTrusts;