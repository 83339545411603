import React, { useState } from "react";
import { Checkbox } from "../../../Global/UI/checkbox";
import { Button } from "../../../Global/UI/Button";
import { Select, SelectContent, SelectItem, SelectTrigger } from '../../../Global/UI/Select';

const ViewSpecialRequirements = ({ onChange, data }) => {
    const [specialRequirementsData, setSpecialRequirementsData] = useState({
        returnJourney: data.returnJourney || false,
        medicalEscort: data.medicalEscort || false,
        ferryNeeded: data.ferryNeeded || false,
        ferryLocation: data.ferryLocation || '',
        ferryPrice: data.ferryPrice || '',
        mobility: data.mobility || '',
        isInfected: data.isInfected || '',
        infectionType: data.infectionType || { covid: false, mrsa: false, cdiff: false, other: false },
        otherInfection: data.otherInfection || '',
        hasSpecialRequirements: data.hasSpecialRequirements || '',
        specialRequirements: data.specialRequirements || [
            { name: 'O2', value: false },
            { name: 'Bariatric', value: false },
            { name: 'Learning Disability', value: false },
            { name: 'Dementia', value: false }
        ],
        hoursWaited: data.hoursWaited || '0',
        logOffTime: data.logOffTime || ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSpecialRequirementsData(prevState => ({
            ...prevState,
            [name]: value
        }));
        onChange({ ...specialRequirementsData, [name]: value });
    };

    const handleCheckboxChange = (field, type) => {
        let updatedData;
        setSpecialRequirementsData(prevState => {
            if (type === 'infectionType' || type === 'specialRequirements') {
                updatedData = {
                    ...prevState,
                    [type]: {
                        ...prevState[type],
                        [field]: !prevState[type][field],
                    },
                };
            } else {
                updatedData = { ...prevState, [field]: !prevState[field] };
            }
            return updatedData;
        });
        onChange(updatedData);
    };

    const handleAddRequirement = () => {
        const { additionalRequirement, specialRequirements } = specialRequirementsData;
        if (additionalRequirement.trim() === '') {
            return;
        }
        const updatedSpecialRequirements = [
            ...specialRequirements,
            { name: additionalRequirement, value: false }
        ];
        setSpecialRequirementsData(prevState => ({
            ...prevState,
            specialRequirements: updatedSpecialRequirements,
            additionalRequirement: '',
        }));
    };

    const handleSpecialRequirementsChange = (name) => {
        const updatedSpecialRequirements = specialRequirementsData.specialRequirements.map(requirement => {
            if (requirement.name === name) {
                return {
                    ...requirement,
                    value: !requirement.value
                };
            }
            return requirement;
        });

        const updatedData = {
            ...specialRequirementsData,
            specialRequirements: updatedSpecialRequirements
        };

        setSpecialRequirementsData(updatedData);
        onChange(updatedData);
    };

    const handleInfectionTypeChange = (type) => {
        handleCheckboxChange(type, 'infectionType');
    };

    return (
        <div>
            <p className='font-bold text-2xl mb-4'>Special Requirements</p>
            <div className='grid gap-4 grid-cols-1 md:grid-cols-3 mb-4'>
                <div>
                    <label className="block font-bold mb-2">Ferry Needed:</label>
                    <div className="flex flex-row gap-2">
                        <div className="flex flex-row items-center">
                            <Checkbox checked={specialRequirementsData.ferryNeeded} onClick={() => handleCheckboxChange('ferryNeeded')} />
                            <p className="ml-1">Yes</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox checked={!specialRequirementsData.ferryNeeded} onClick={() => handleCheckboxChange('ferryNeeded')} />
                            <p className="ml-1">No</p>
                        </div>
                    </div>
                </div>
                {specialRequirementsData.ferryNeeded === true && (
                    <div>
                        <label className="block font-bold mb-2">Ferry Location:</label>
                        <select
                            value={specialRequirementsData.ferryLocation}
                            onChange={handleChange}
                            id="ferryLocation"
                            name="ferryLocation"
                            className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                        >
                            <option value=""></option>
                            <option value="Larne to Cairnryan">Larne to Cairnryan</option>
                            <option value="Belfast to Stranraer">Belfast to Stranraer</option>
                            <option value="Dublin to Hollyhead">Dublin to Hollyhead</option>
                            <option value="Cork to Roscoff">Cork to Roscoff</option>
                        </select>
                    </div>
                )}
                {specialRequirementsData.ferryNeeded === true && (
                    <div>
                        <label className="block font-bold mb-2">Ferry Price:</label>
                        <input
                            type="number"
                            value={specialRequirementsData.ferryPrice}
                            onChange={handleChange}
                            id="ferryPrice"
                            name="ferryPrice"
                            className="border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                )}
                <div>
                    <label className="block font-bold mb-2">Return Journey Needed:</label>
                    <div className="flex flex-row gap-2">
                        <div className="flex flex-row items-center">
                            <Checkbox checked={specialRequirementsData.returnJourney} onClick={() => handleCheckboxChange('returnJourney')} />
                            <p className="ml-1">Yes</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox checked={!specialRequirementsData.returnJourney} onClick={() => handleCheckboxChange('returnJourney')} />
                            <p className="ml-1">No</p>
                        </div>
                    </div>
                </div>
                {specialRequirementsData.returnJourney === true && (
                    <div>
                        <label className="block font-bold mb-2">Hours Waited:</label>
                        <div className="flex flex-row gap-2">
                            <input
                                type="number"
                                value={specialRequirementsData.hoursWaited}
                                onChange={handleChange}
                                id="hoursWaited"
                                name="hoursWaited"
                                className="border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                            />
                        </div>
                    </div>
                )}


                <div>
                    <label className="block font-bold mb-2">Medical Escort Required:</label>
                    <div className="flex flex-row gap-2">
                        <div className="flex flex-row items-center">
                            <Checkbox checked={specialRequirementsData.medicalEscort} onClick={() => handleCheckboxChange('medicalEscort')} />
                            <p className="ml-1">Yes</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox checked={!specialRequirementsData.medicalEscort} onClick={() => handleCheckboxChange('medicalEscort')} />
                            <p className="ml-1">No</p>
                        </div>
                    </div>
                </div>


                {specialRequirementsData.medicalEscort === true && (
                    <div>
                        <label className="block font-bold mb-2">Log off time:</label>
                        <div className="flex flex-row gap-2">
                            <input
                                type="time"
                                value={specialRequirementsData.logOffTime}
                                onChange={handleChange}
                                id="logOffTime"
                                name="logOffTime"
                                className="border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                            />
                        </div>
                    </div>
                )}

                <div>
                    <label className="block font-bold mb-2">Mobility:</label>
                    <select
                        value={specialRequirementsData.mobility}
                        onChange={handleChange}
                        id="mobility"
                        name="mobility"
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    >
                        <option value=""></option>
                        <option value="Walking">Walking</option>
                        <option value="Chair to ambulance">Chair to ambulance</option>
                        <option value="Stretcher">Stretcher</option>
                    </select>
                </div>

                <div>
                    <label className="block font-bold mb-2">Is Patient Infected:</label>
                    <select
                        value={specialRequirementsData.isInfected}
                        onChange={handleChange}
                        id="isInfected"
                        name="isInfected"
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    >
                        <option value=""></option>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </select>
                </div>

                {specialRequirementsData.isInfected === 'yes' && (
                    <div>
                        <label className="block font-bold mb-2">Select Infection:</label>
                        <div className="flex flex-row gap-2">
                            {Object.entries(specialRequirementsData.infectionType).map(([type, checked]) => (
                                <div key={type} className="flex flex-row items-center">
                                    <Checkbox checked={checked} onClick={() => handleInfectionTypeChange(type)} />
                                    <p className="ml-1">{type.charAt(0).toUpperCase() + type.slice(1)}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {specialRequirementsData.infectionType.other && (
                    <div>
                        <label htmlFor="otherInfection" className="block font-bold mt-4 mb-2">Other Infection:</label>
                        <input
                            type="text"
                            value={specialRequirementsData.otherInfection}
                            onChange={handleChange}
                            id="otherInfection"
                            name="otherInfection"
                            className="border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                )}

                <div>
                    <label className="block font-bold mb-2">Special Requirements:</label>
                    <select
                        value={specialRequirementsData.hasSpecialRequirements}
                        onChange={handleChange}
                        id="hasSpecialRequirements"
                        name="hasSpecialRequirements"
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    >
                        <option value=""></option>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </select>
                </div>

                {specialRequirementsData.hasSpecialRequirements === 'yes' && (
                    <div>
                        <label className="block font-bold mb-2">Special Requirements:</label>
                        <div className="flex flex-row gap-2">
                            {Array.isArray(specialRequirementsData.specialRequirements) && specialRequirementsData.specialRequirements.map(requirement => (
                                <div key={requirement.name}>
                                    <div className="flex flex-row items-center">
                                        <Checkbox
                                            checked={requirement.value}
                                            onClick={() => handleSpecialRequirementsChange(requirement.name)}
                                        />
                                        <p className="ml-1">{requirement.name}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div>
                    <label className="block font-bold mb-2">Additional Requirement:</label>
                    <div className="flex flex-row items-center">
                        <input
                            type="text"
                            value={specialRequirementsData.additionalRequirement}
                            onChange={handleChange}
                            id="additionalRequirement"
                            name="additionalRequirement"
                            className="border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                        />
                        <Button className="ml-2 px-4 py-2 bg-blue-500 text-white rounded" onClick={handleAddRequirement}>Add</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewSpecialRequirements;
