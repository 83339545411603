import React from 'react'
import { useHistory } from "react-router-dom";
import ReferrerDetails from "../../Components/Add Job/Repatriation/ReferrerDeatils";
import PatientDetails from '../../Components/Add Job/Repatriation/PatientDetails';
import JourneyDetails from '../../Components/Add Job/Repatriation/JourneyDetails';
import SpecialRequirements from '../../Components/Add Job/Repatriation/SpecialRequirements';
import StaffDetails from '../../Components/Add Job/Repatriation/StaffDetails';
import { toast } from 'react-toastify';
import { BASE_URL } from '../Settings/DASHBOARD_SETTINGS';
import { Button } from '../../Components/Global/UI/Button';

const AddRepatriationJob = () => {

    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const [jobDetails, setJobDetails] = React.useState({
        referrerDetails: {},
        staffDetails: {},
        patientDetails: {},
        journeyDetails: {},
        specialRequirements: {},
    });

    const handleNavigation = () => {
        history.push('/');
    };

    const handleServiceRequest = async () => {
        setLoading(true)
        try {

            if (!jobDetails.journeyDetails || !jobDetails.journeyDetails.journeys || 
                !jobDetails.journeyDetails.journeys[0] || !jobDetails.journeyDetails.journeys[0].dateTimePickup) {
                setLoading(false);
                toast.error('Please provide pickup date and time.');
                throw new Error('Please provide pickup date and time.');
            }


            const response = await fetch(`${BASE_URL}/api/repatriation/create-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    referrerDetails: jobDetails.referrerDetails,
                    staffDetails: jobDetails.staffDetails,
                    patientDetails: jobDetails.patientDetails,
                    journeyDetails: jobDetails.journeyDetails,
                    specialRequirements: jobDetails.specialRequirements,
                }),
            });
    
            if (!response.ok) {
                setLoading(false)
                throw new Error('Failed to submit service request');
            }

            const data = await response.json();    

            if (data.success === true) {
                setLoading(false)
                toast.success(data.msg);
                handleNavigation()
            } else {
                setLoading(false)
                toast.error(data.msg);
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.msg);
            setError('Error submitting service request: ' + error.message);
        }
    };


    const handleReferrerDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            referrerDetails: data
        }));
    };

    const handlePatientDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            patientDetails: data
        }));
    };

    const handleJourneyDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            journeyDetails: data
        }));
    };

    const handleSpecialRequirementsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            specialRequirements: data
        }));
    };
    const handleStaffDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            staffDetails: data
        }));
    };

    return (
        <div className="px-6 my-8">
            <ReferrerDetails onChange={handleReferrerDetailsChange} />
            <StaffDetails onChange={handleStaffDetailsChange} />
            <PatientDetails onChange={handlePatientDetailsChange} />
            <JourneyDetails onChange={handleJourneyDetailsChange} />
            <SpecialRequirements onChange={handleSpecialRequirementsChange} />
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <Button
                onClick={() => handleServiceRequest()}
                disabled={loading}
                className='w-full py-3'
            >
                {loading ? 'Submitting...' : 'Submit Request'}
            </Button>
        </div>
    )
}

export default AddRepatriationJob