import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Logo from '../../Assets/Images/JaceLOGO2.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, buttonVariants } from '../Global/UI/Button';
import { BASE_URL } from '../../Screens/Settings/DASHBOARD_SETTINGS';

const DriverLogs = (selectedDriver) => {

    const [assignedJobs, setAssignedJobs] = useState(null);
    const [loading, setLoading] = useState(true);
    const driver = selectedDriver.driver;
    let history = useHistory();

    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/drivers/${driver.driverId}/logs`);
            setAssignedJobs(response.data.logs);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching drivers:', error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, [driver.driverId]);

    const handleDisplayLog = async (log) => {
        history.push({
            pathname: `/drivers/${driver.driverId}/log/${log.logId}`,
            state: { log: log, driverId: driver.driverId },
        });
    };

    const renderSkeleton = () => {
        return (
            <tr>
                <td className="py-3 text-sm" role="cell">
                    <div className="flex items-center gap-2">
                        <div className="h-[30px] w-[30px] rounded-full bg-gray-300 animate-pulse"></div>
                        <div className="h-[18px] w-[100px] bg-gray-300 animate-pulse"></div>
                    </div>
                </td>
                <td className="py-3 text-sm" role="cell">
                    <div className="h-[18px] w-[50px] bg-gray-300 animate-pulse"></div>
                </td>
                <td className="py-3 text-sm" role="cell">
                    <div className="h-[18px] w-[50px] bg-gray-300 animate-pulse"></div>
                </td>
                <td className="py-3 text-sm" role="cell">
                    <div className="h-[32px] w-[100px] bg-gray-300 animate-pulse"></div>
                </td>
                <td className="py-3 text-sm" role="cell">
                    <div className="h-[32px] w-[100px] bg-gray-300 animate-pulse"></div>
                </td>
            </tr>
        );
    };

    return (
        <div className="">
            <div className="w-full overflow-x-scroll px-4 md:overflow-x-hidden mt-4">
                <table role="table" className="w-full min-w-[500px] overflow-x-scroll">
                    <thead>
                        <tr role="row">
                            <th role="columnheader" title="Name">
                                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                                    Created By
                                </div>
                            </th>
                            <th role="columnheader" title="Artworks">
                                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                                    Log ID
                                </div>
                            </th>
                            <th role="columnheader" title="Assigned Jobs">
                                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                                    Log Name
                                </div>
                            </th>
                            <th role="columnheader" title="Assigned Jobs">
                                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                                    Creation Date
                                </div>
                            </th>

                            <th role="columnheader" title="Actions">
                                <div className="flex items-center justify-between pb-2 pt-4 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                                    Actions
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody role="rowgroup" className="px-4">
                        {loading ? (
                            Array.from({ length: 5 }).map((_, index) => renderSkeleton(index))
                        ) : (
                            assignedJobs.map((item, index) => (
                                <tr key={index} role="row">
                                    <td className="py-3 text-sm" role="cell">
                                        <div className="flex items-center gap-2">
                                            <div className="h-[30px] w-[30px] rounded-full">
                                                <img src={Logo} className="h-full w-full rounded-full" alt="" />
                                            </div>
                                            <p className="text-md font-semibold text-gray-600">{item.signature}</p>
                                        </div>
                                    </td>
                                    <td className="py-3 text-sm" role="cell">
                                        <p className="text-md font-semibold text-gray-600 ">{item.logId}</p>
                                    </td>
                                    <td className="py-3 text-sm" role="cell">
                                        <p className="text-md font-semibold text-gray-600">{item.name}</p>
                                    </td>
                                    <td className="py-3 text-sm" role="cell">
                                        <p className="text-md font-semibold text-gray-600">
                                            {new Date(item.timestamp).toLocaleDateString('en-GB')}
                                        </p>
                                    </td>
                                    <td className="py-3 text-sm" role="cell">
                                        <Button
                                            variant="default"
                                            size="lg"
                                            className={buttonVariants({ variant: "default", size: "lg" })}
                                            onClick={() => handleDisplayLog(item)}>
                                            View Log
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DriverLogs;
