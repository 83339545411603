import moment from 'moment/moment';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogClose, DialogDescription } from '../Global/UI/Dialog';
import { Label } from '../Global/UI/Label';


const ShowJobLogs = ({ item , isOpen, handleLog }) => {
  const formatTimestamp = (timestamp) => {
    return moment(timestamp, 'DD/MM/YYYY HH:mm:ss');
  };

  const startTime = formatTimestamp(item.jobTimeStamps.startedTimeStamp);
  const endTime = formatTimestamp(item.jobTimeStamps.finishedTimeStamp);
  const duration = moment.duration(endTime.diff(startTime));

  const formattedDuration = moment.utc(duration.asMilliseconds()).format('HH[h] mm[m] ss[s]');

  return (
    <Dialog open={isOpen} onOpenChange={handleLog}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Logs</DialogTitle>
          <DialogClose />
        </DialogHeader>
        <DialogDescription>
          These logs show things including.
        </DialogDescription>
        <div>
          <div className='my-1'>
            <Label className='font-semibold'>Assigned: </Label>
            <Label>{item.jobTimeStamps.assignedTimeStamp}</Label>
          </div>
          <div className='my-1'>
            <Label className='font-semibold'>Accepted: </Label>
            <Label>{item.jobTimeStamps.acceptedTimeStamp}</Label>
          </div>
          <div className='my-1'>
            <Label className='font-semibold'>Started: </Label>
            <Label>{item.jobTimeStamps.startedTimeStamp}</Label>
          </div>
          <div className='my-1'>
            <Label className='font-semibold'>Arrived On Scene: </Label>
            <Label>{item.jobTimeStamps.arrivedOnSceneTimeStamp}</Label>
          </div>
          <div className='my-1'>
            <Label className='font-semibold'>Leaving Scene: </Label>
            <Label>{item.jobTimeStamps.leavingSceneTimeStamp}</Label>
          </div>
          <div className='my-1'>
            <Label className='font-semibold'>Finished: </Label>
            <Label>{item.jobTimeStamps.finishedTimeStamp}</Label>
          </div>
          <div className='my-1'>
            <Label className='font-semibold'>Time Taken: </Label>
            <Label>{formattedDuration}</Label>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ShowJobLogs