import JobSettings from "../../Components/Settings/JobSettings"
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Components/Global/UI/tabs';
import RepatriationJobSettings from "../../Components/Settings/RepatriationJobSettings";
import NIASRenalJobSettings from "../../Components/Settings/NIAS Renal Settings/NIASRenalSettings";

const SettingsPage = () => {
    return (
        <div className="px-10 my-8">
            <p className="font-bold text-2xl">Job Settings</p>
            <Tabs className="py-6" defaultValue="repatriation">
            <TabsList className='mb-4'>
              <TabsTrigger className='font-semibold' value="General">Journeys</TabsTrigger>
              <TabsTrigger className='font-semibold' value="nias">NIAS</TabsTrigger>
              <TabsTrigger className='font-semibold' value="repatriation">Repatriation</TabsTrigger>
              <TabsTrigger className='font-semibold' value="NIASRenal">NIAS Renal</TabsTrigger>
            </TabsList>

            <TabsContent value="General">
              <JobSettings/>
            </TabsContent>
            <TabsContent value="nias">
              {/* <NiasJobList /> */}
            </TabsContent>
            <TabsContent value="repatriation">
              <RepatriationJobSettings />
            </TabsContent>
            <TabsContent value="NIASRenal">
              <NIASRenalJobSettings />
            </TabsContent>
          </Tabs>
        </div>
    )
}

export default SettingsPage