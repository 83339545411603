import axios from "axios";
import React from "react";
import CleaningLogTable from "./CleaningLogTable";
import Logo from '../../../Assets/Images/JaceLOGO2.png';
import { Checkbox } from "../../Global/UI/checkbox";
import { Input } from "../../Global/UI/Input";
import { Button } from "../../Global/UI/Button";
import { usePDF } from 'react-to-pdf';
import { BASE_URL } from "../../../Screens/Settings/DASHBOARD_SETTINGS";

const DailyCleaningLog = ({ log, driverId }) => {
    const [cleaningLog, setCleaningLog] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const doneOptions = ['Completed']
    const usedOptions = ['Used', 'Hosp', 'Jace Stock']

    const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});

    React.useEffect(() => {
        const fetchLog = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${BASE_URL}/api/drivers/${driverId}/logs/${log.logId}`);
                setCleaningLog(response.data.log);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching drivers:', error.message);
                setLoading(false);
            }
        };
        fetchLog();
    }, []);

    return (
        <>
            <div className="p-10 justify-center flex flex-row gap-4 items-center">
                <div className="flex flex-row items-center">
                    <img src={Logo} width={30} alt="Jace Logo" />
                    <p className="pl-1 font-semibold">Completed By: <span className="font-normal">{log.signature}</span></p>
                </div>
                <p className="font-semibold">Log ID: <span className="font-normal">{log.logId}</span></p>

                <p className="font-semibold">Completed: <span className="font-normal">{new Date(log.timestamp).toLocaleString('en-GB')}</span></p>
                <Button onClick={() => toPDF()}>Generate PDF</Button>
            </div>
            {cleaningLog ? (
                <div ref={targetRef} className="w-3/4 p-10 m-auto flex flex-col items-center">

                    <img className="mb-4" src={Logo} width={100} alt="Jace Logo" />

                    <p className="font-semibold text-2xl mb-6">Daily Cleaning Log</p>

                    <div className="flex flex-col w-1/2">
                        <p className="font-semibold mb-1 text-lg">To be Completed DURING each shift after each patient</p>
                        <p>Did the patient have a communicable disease?</p>
                        <div className="flex flex-row gap-2">
                            <div className="flex flex-row items-center">
                                <Checkbox checked={!cleaningLog.patientHadDisease} />
                                <p className="ml-1">No</p>
                            </div>
                            <div className="flex flex-row items-center">
                                <Checkbox checked={cleaningLog.patientHadDisease} />
                                <p className="ml-1">Yes</p>
                            </div>
                        </div>
                    </div>

                    <CleaningLogTable
                        items={cleaningLog.selectedItems.map((item) => item.item)}
                        labels={doneOptions}
                        checkedItems={cleaningLog.selectedItems}
                    />

                    <div className="flex flex-col w-1/2 mb-4 mt-6">
                        <p className="font-semibold mb-1 text-lg">To be Completed at END of shift.</p>
                        <p className="mb-1">Has the vehicle been washed at the end of the shift?*</p>
                        <div className="flex flex-row gap-2 mb-2">
                            <div className="flex flex-row items-center">
                                <Checkbox checked={!cleaningLog.vehicleWashed} />
                                <p className="ml-1">No</p>
                            </div>
                            <div className="flex flex-row items-center">
                                <Checkbox checked={cleaningLog.vehicleWashed} />
                                <p className="ml-1">Yes</p>
                            </div>
                        </div>

                        <p>If No, Please state reason</p>

                        <Input
                            value={cleaningLog.notWashedReason}
                            type="text"
                            id="vehicleWashedReason"
                            name="vehicleWashedReason"
                        />
                    </div>

                    <div className="w-1/2 mt-4">
                        <p className="font-semibold mb-1 text-lg">Completed at END of shift*.</p>
                    </div>

                    <CleaningLogTable
                        items={cleaningLog.vehicleSelectedItems.map((item) => item.item)}
                        labels={doneOptions}
                        checkedItems={cleaningLog.vehicleSelectedItems}
                    />

                    <div className="w-1/2 mt-4">
                        <p className="font-semibold mb-1 text-lg">Stock Replenishment</p>
                        <p>Please Note If Replenished From Hospital Or From Jace Medical Stock</p>
                        <p className="font-semibold mb-1 text-lg">Ambulance Stock*</p>
                    </div>

                    <CleaningLogTable
                        items={cleaningLog.ambulanceItems.map((item) => item.item)}
                        labels={usedOptions}
                        checkedItems={cleaningLog.ambulanceItems}
                    />

                    <div className="w-1/2 mt-4">
                        <p className="font-semibold mb-2 text-lg">Equipment Bags</p>
                        <p>Red PPE Bag*</p>
                    </div>

                    <CleaningLogTable
                        items={cleaningLog.RedPPEItems.map((item) => item.item)}
                        labels={usedOptions}
                        checkedItems={cleaningLog.RedPPEItems}
                    />
                    <div className="w-1/2 mt-4">
                        <p>Green Obs Kit*</p>
                    </div>

                    <CleaningLogTable
                        items={cleaningLog.GreenOBSItems.map((item) => item.item)}
                        labels={usedOptions}
                        checkedItems={cleaningLog.GreenOBSItems}
                    />
                    <div className="w-1/2 mt-4">
                        <p>Red Oxygen Bag*</p>
                    </div>

                    <CleaningLogTable
                        items={cleaningLog.RedOxygenItems.map((item) => item.item)}
                        labels={usedOptions}
                        checkedItems={cleaningLog.RedOxygenItems}
                    />
                    <div className="w-1/2 mt-4">
                        <p>Blue Entonox Bag*</p>
                    </div>

                    <CleaningLogTable
                        items={cleaningLog.BlueEntonoxItems.map((item) => item.item)}
                        labels={usedOptions}
                        checkedItems={cleaningLog.BlueEntonoxItems}
                    />
                    <div className="w-1/2 mt-4">
                        <p className="font-semibold mb-2 ">Any other Equipment used?</p>
                        <Input
                            value={cleaningLog.otherEquipment}
                            type="text"
                            id="otherEquipment"
                            name="otherEquipment"
                        />
                        <p className="font-semibold mb-2 mt-4">Signature:</p>
                        <Input
                            value={cleaningLog.signature}
                            type="text"
                            id="signature"
                            name="signature"
                        />
                    </div>

                </div>
            ) : (
                <div>Loading...</div>
            )}
        </>
    )
}

export default DailyCleaningLog;
