import React, { useEffect, useState } from 'react';
import { Label } from "../../Components/Global/UI/Label";
import { Button, buttonVariants } from "../../Components/Global/UI/Button";
import axios from 'axios';
import { BASE_URL } from '../Settings/DASHBOARD_SETTINGS';
import { Select, SelectContent, SelectItem, SelectTrigger } from "../../Components/Global/UI/Select";
import { toast } from "react-toastify";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "../../Components/Global/UI/table";
import Logo from "../../Assets/Images/JaceLOGO2.png";
import { ThreeDots } from 'react-loader-spinner';

const ReportingScreen = () => {
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedReportType, setSelectedReportType] = useState('');

    const fetchReportData = async () => {
        if (selectedReportType === '') {
            toast.error("Please ensure a Report Type has been selected");
            return;
        }
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/api/admin/generate-report/${selectedReportType}`);

            if (!response.data.success) {
                toast.error(response.data.error);
                setLoading(false);
            }

            if (response.data.success) {
                setReportData(response.data.results);
                setLoading(false);
            }

        } catch (error) {
            console.error('Error fetching report data:', error);
            setLoading(false);
        }
    };

    const handleReportTypeSelect = (value) => {
        setSelectedReportType(value);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <ThreeDots
                    height="80"
                    width="80"
                    color="#000000"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                />
            </div>
        );
    }

    const renderTable = () => {
        if (selectedReportType === 'NIAS') {
            return (
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Trust</TableHead>
                            <TableHead>Total Jobs</TableHead>
                            <TableHead>Total Value</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {reportData.map((item, index) => (
                            <React.Fragment key={index}>
                                <TableRow>
                                    <TableCell>
                                        <div className="flex items-center">
                                            <img
                                                src={Logo}
                                                className="h-8 w-8 rounded-full"
                                                alt=""
                                            />
                                            <span>{item.trust ? item.trust : 'Unknown'}</span>
                                        </div>
                                    </TableCell>
                                    <TableCell>{item.jobs ? item.jobs : '0'}</TableCell>
                                    <TableCell>{item.value ? item.value : '0'}</TableCell>
                                </TableRow>
                                {item.HCPJobs && item.HCPJobs.length > 0 && item.HCPJobs.map((hcpJob, hcpIndex) => (
                                    <TableRow key={`${index}-hcp-${hcpIndex}`} className="bg-gray-100">
                                        <TableCell>
                                            <div className="flex items-center pl-8">
                                                <img
                                                    src={Logo}
                                                    className="h-8 w-8 rounded-full"
                                                    alt=""
                                                />
                                                <span>HCP {hcpJob.trust ? hcpJob.trust : 'Unknown'}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>{hcpJob.jobs ? hcpJob.jobs : '0'}</TableCell>
                                        <TableCell>{hcpJob.value ? hcpJob.value : '0'}</TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            );
        } else {
            return (
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Trust</TableHead>
                            <TableHead>Total Jobs</TableHead>
                            <TableHead>Total Value</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {reportData.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <div className="flex items-center">
                                        <img
                                            src={Logo}
                                            className="h-8 w-8 rounded-full"
                                            alt=""
                                        />
                                        <span>{item.trust ? item.trust : 'Unknown'}</span>
                                    </div>
                                </TableCell>
                                <TableCell>{item.jobs ? item.jobs : '0'}</TableCell>
                                <TableCell>{item.value ? item.value : '0'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
        }
    };

    return (
        <div className="px-10 my-8">
            <div className="flex justify-between items-center mb-4">
                <Label className="font-bold text-2xl">{`Reporting`}</Label>
                <div className='flex flex-row gap-2 items-center'>
                    <Select value={selectedReportType} onValueChange={handleReportTypeSelect}>
                        <SelectTrigger>{selectedReportType ? selectedReportType : 'Select Report Type'}</SelectTrigger>
                        <SelectContent>
                            <SelectItem value="Journeys">Journeys</SelectItem>
                            <SelectItem value="NIAS">NIAS</SelectItem>
                            <SelectItem value="NIASRenal">NIAS Renal</SelectItem>
                        </SelectContent>
                    </Select>
                    <Button
                        variant="default"
                        size="default"
                        className={buttonVariants({ variant: "default", size: "default" })}
                        onClick={() => fetchReportData()}
                    >
                        Generate Report
                    </Button>
                </div>
            </div>

            {loading ? (
                <p>Loading...</p>
            ) : (
                renderTable()
            )}
        </div>
    );
};

export default ReportingScreen;
