import React, { useState } from "react";
import { Button, buttonVariants } from "../../Components/Global/UI/Button";
import { toast } from "react-toastify";
import axios from "axios";
import Papa from "papaparse";
import { DatePickerWithRange } from "../../Components/Global/UI/DateRangePicker";
import { format } from "date-fns";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../../Components/Global/UI/Select";
import InvoicingJourney from "./InvoicingJourney";
import InvoicingShift from "./InvoicingShift";
import { BASE_URL } from "../Settings/DASHBOARD_SETTINGS";
import moment from "moment";
import InvoicingRenal from "./InvoicingRenal";
import { useAuth } from "../../Context/AuthProvider";
import { ThreeDots } from 'react-loader-spinner'

const InvoicingPanel = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [activeTab, setActiveTab] = useState("Journey");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedShiftItems, setShiftItems] = useState([]);
  const [tokenExists, setTokenExists] = React.useState(true)
  const { authData } = useAuth();

  React.useEffect(() => {
    setLoading(true)
    checkTokenExists();
  }, []);

  const checkTokenExists = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/xero/check-token-exists/${authData.userId}`
      );
      setTokenExists(response.data.success);
      setLoading(false);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const connectXero = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/xero/connect/${authData.userId}`
      );
      redirectToXeroAuthorization(response.data.data);
      setLoading(false);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const redirectToXeroAuthorization = (authorizationUrl) => {
    window.location.href = authorizationUrl;
  };

  const handleErrorResponse = (error) => {
    setLoading(false);
    if (error.response) {
      toast.error(error.response.data.message || "An unknown error has occurred");
    } else {
      toast.error("An unknown error has occurred");
    }
  };

  if (!tokenExists) {
    return (
      <div className="flex flex-1 justify-center pt-40">
        <div className="flex flex-col max-w-sm">
          <p className="font-semibold text-2xl text-center">Connect your Xero Account</p>
          <p className="font-light text-sm max-w-sm text-center pt-1 pb-4">To continue using the invoicing feature please connect your Xero account to the JaceMedical Dashboard</p>
          <Button
            onClick={() => connectXero()}
            variant="default"
            size="default"
            className={buttonVariants({ variant: "default", size: "default" })}
          >
            Connect Xero Account
          </Button>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ThreeDots
          height="80"
          width="80"
          color="#000000"
          ariaLabel="tail-spin-loading"
          radius="1"
          visible={true}
        />
      </div>
    );
  }

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleSelectedItemsChange = (items) => {
    console.log('Items Changed:', items)
    setSelectedItems(items);
  };

  const handleSelectedShiftItemsChange = (items) => {
    console.log('Shift Items Changed:', items)
    setShiftItems(items);
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return "Invalid date";
    }
  };

  const getInvoices = () => {
    if (activeTab === 'Journey') {
      getJourneyInvoices();
    } else if (activeTab === 'Shift') {
      getShiftInvoices();
    } else if (activeTab === 'NIASRenal') {
      getNIASRenalInvoices();
    }else if (activeTab === 'Repatriation') {
      getNIASRenalInvoices();
    }
  };

  const getShiftInvoices = async () => {
    const formattedFromDate = format(selectedDate.from, "yyyy-MM-dd");
    const formattedToDate = format(selectedDate.to, "yyyy-MM-dd");

    try {
      setLoading(true);
      toast.info("🔥 Invoices are being Generated.");
      const response = await axios.get(
        `${BASE_URL}/api/admin/generate-invoices-shifts?from=${formattedFromDate}&to=${formattedToDate}`
      );
      if (response.data.success) {
        toast.success("🔥 Invoices have been successfully Generated.");
        setInvoices(response.data.results);
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setLoading(false);
          toast.error(error.response.data.message);
          console.error("Validation errors:", error.response.data.errors);
        }
      } else if (error.request) {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("No response received:", error.request);
      } else {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("Error setting up the request:", error.message);
      }
    }

  }

  const getJourneyInvoices = async () => {
    const formattedFromDate = format(selectedDate.from, "yyyy-MM-dd");
    const formattedToDate = format(selectedDate.to, "yyyy-MM-dd");
    try {
      setLoading(true);
      toast.info("🔥 Invoices are being Generated.");
      const response = await axios.get(
        `${BASE_URL}/api/admin/generate-invoices?from=${formattedFromDate}&to=${formattedToDate}`
      );
      if (response.data.success) {
        toast.success("🔥 Invoices have been successfully Generated.");
        setInvoices(response.data.results);
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setLoading(false);
          toast.error(error.response.data.message);
          console.error("Validation errors:", error.response.data.errors);
        }
      } else if (error.request) {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("No response received:", error.request);
      } else {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  const getNIASRenalInvoices = async () => {
    const formattedFromDate = format(selectedDate.from, "yyyy-MM-dd");
    const formattedToDate = format(selectedDate.to, "yyyy-MM-dd");
    try {
      setLoading(true);
      toast.info("🔥 Invoices are being Generated.");
      const response = await axios.get(
        `${BASE_URL}/api/admin/generate-invoices-nias-renal?from=${formattedFromDate}&to=${formattedToDate}`
      );
      if (response.data.success) {
        toast.success("🔥 Invoices have been successfully Generated.");
        setInvoices(response.data.results);
        console.log('Items:', response.data.results)
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setLoading(false);
          toast.error(error.response.data.message);
          console.error("Validation errors:", error.response.data.errors);
        }
      } else if (error.request) {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("No response received:", error.request);
      } else {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  let headers = []

  const sendNIASInvoicesToXero = async () => {
    const invoices = {
      invoices: selectedShiftItems.map((invoice) => {
        console.log('Invoice: ', invoice)
        const visibleBreaksCount = Object.values(invoice.breakDetails).filter((breakItem) => breakItem.visible).length;
        const referenceNumbers = invoice.jobs && invoice.jobs.jobList
          ? invoice.jobs.jobList.reduce((acc, job) => {
            if (acc.length > 0) acc += ', ';
            acc += job.referenceNumber;
            return acc;
          }, '')
          : 'N/A';
        const description = [
          `NIAS Shift`,
          `TRUST: ${invoice.jobDetails.trust}`,
          `Cost Code: ${invoice.jobDetails.costCenter}`,
          `Call Sign: ${invoice.jobDetails.callSign}`,
          `DATE: ${formatDateTime(invoice.jobDetails.jobStartDate)}`,
          `Log on time: ${invoice.shiftDetails.logonTime}`,
          `Log off time: ${invoice.shiftDetails.logoffTime}`,
          `Total Hour: ${invoice.shiftDetails.totalHours}`,
          `Total Miles: ${invoice.jobs.totalMiles.toFixed(2)}`,
          `Total Jobs: ${invoice.jobs.totalJobs}`,
          `Job Reference Numbers: ${referenceNumbers}`,
          `Breaks: ${visibleBreaksCount}x 30 Min Breaks`,
        ].join("\n");

        const unitAmount = parseFloat(invoice.jobDetails.price);
        const lineAmount = parseFloat(invoice.jobDetails.price);

        if (isNaN(unitAmount) || isNaN(lineAmount)) {
          console.error('Invalid amount detected in invoice:', invoice);
          return null;
        }

        return {
          type: 'ACCREC',
          invoiceNumber: invoice.jobDetails.referenceCode,
          contact: {
            contactID: '950e422a-4163-4ee9-b32d-b97649175a53'
          },
          lineItems: [
            {
              description: description,
              quantity: 1.0,
              unitAmount: unitAmount,
              accountCode: "201",
              taxType: "NONE",
              lineAmount: lineAmount,
            }
          ],
          date: moment(invoice.jobDetails.jobStartDate).format('YYYY-MM-DD'),
          dueDate: moment(invoice.jobDetails.jobStartDate).add(30, 'days').format('YYYY-MM-DD'),
          reference: invoice.jobDetails.referenceCode,
          status: 'DRAFT'
        };
      }).filter(invoice => invoice !== null) // Filter out any invalid invoices
    };

    try {
      const response = await axios.post(`${BASE_URL}/api/xero/create-invoice/${authData.userId}`, invoices);
      if (response.data.success) {
        toast.success("🔥 Invoices have been successfully sent to xero.");
        setLoading(false);
      }
      console.log('Invoice creation response:', response.data);
    } catch (err) {
      console.error('Error sending invoices:', err);
    }
  }

  const downloadNIASRenalInvoices = () => {
    const csvData = selectedShiftItems.map((invoice) => {
      let contactInfo = {};



      contactInfo = {
        ContactName: "NIAS Business Services Organisation",
        EmailAddress: "NIAS.NONPOP@hscni.net",
        POAddressLine1: "NIAS",
        POAddressLine2: "",
        POAddressLine3: "",
        POAddressLine4: "",
        POCity: "30 Knockbracken Healthcare Park",
        PORegion: "Belfast",
        POPostalCode: "BT8 8SG",
        POCountry: "Northern Ireland",
      };

      const description = [
        `NIAS Renal Transfer`,
        `TRUST: ${invoice.jobDetails.trust}`,
        `Cost Code: ${invoice.jobDetails.costCenter}`,
        `Call Sign: ${invoice.jobDetails.callSign}`,
        `DATE: ${formatDateTime(invoice.jobDetails.jobStartDate)}`,
        `Total Miles: ${invoice.jobs.totalMiles.toFixed(2)}`,
        `NIAS Reference Number: ${invoice.jobs.journeys[0].niasReference}`,
        `Job Reference Number: ${invoice.jobDetails.referenceCode.toUpperCase()}`,
      ].join("\n");

      return {
        ...contactInfo,
        SAAddressLine1: "",
        SAAddressLine2: "",
        SAAddressLine3: "",
        SAAddressLine4: "",
        SACity: "",
        SARegion: "",
        SAPostalCode: "",
        SACountry: "",
        InvoiceNumber: invoice.jobDetails.referenceCode,
        Reference: invoice.jobDetails.referenceCode,
        InvoiceDate: moment(invoice.jobDetails.jobStartDate).format('DD/MM/YYYY'),
        DueDate: moment(invoice.jobDetails.jobStartDate).add(30, 'days').format('DD/MM/YYYY'),
        PlannedDate: "",
        Total: invoice.jobDetails.price,
        TaxTotal: "0",
        InvoiceAmountPaid: "0",
        InvoiceAmountDue: invoice.jobDetails.price,
        InventoryItemCode: "Patient Transfer Service",
        Description: description,
        Quantity: "1",
        UnitAmount: invoice.jobDetails.price,
        Discount: "",
        LineAmount: invoice.jobDetails.price,
        AccountCode: "201",
        TaxType: "No VAT",
        TaxAmount: "0",
        TrackingName1: "",
        TrackingOption1: "",
        TrackingName2: "",
        TrackingOption2: "",
        Currency: "GBP",
        Type: "Sales invoice",
        Sent: "Sent",
        Status: "Awaiting Payment",
      };
    });

    const csv = Papa.unparse({
      fields: headers.map((header) => header.label),
      data: csvData,
    });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "invoices.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const downloadCSV = () => {
    if (activeTab === 'Journey') {
      SendInvoicesToXero();
    } else if (activeTab === 'Shift') {
      sendNIASInvoicesToXero();
    } else if (activeTab === 'NIASRenal') {
      downloadNIASRenalInvoices();
    }
  };


  const clearInvoices = () => {
    setInvoices([]);
  };


  const SendInvoicesToXero = async () => {
    const invoices = {
      invoices: selectedItems.map((invoice) => {
        const pickupLocation =
          invoice.job.journeyDetails.journeys[0].pickupLocation === "Other"
            ? invoice.job.journeyDetails.journeys[0].otherPickupLocation
            : invoice.job.journeyDetails.journeys[0].pickupLocation;
        const dropOffLocation =
          invoice.job.journeyDetails.journeys[0].dropOffLocation === "Other"
            ? invoice.job.journeyDetails.journeys[0].otherDropOffLocation
            : invoice.job.journeyDetails.journeys[0].dropOffLocation;

        const nameTrimmed = invoice.job.patientDetails.name.trim();
        const initials = nameTrimmed
          .split(" ")
          .map((namePart) => namePart[0])
          .join("");

        const dob = invoice.job.patientDetails.dob
          ? new Date(invoice.job.patientDetails.dob).toLocaleDateString("en-GB")
          : "NO DOB";

        const description = [
          `${invoice.job.JobStatus === 7 ? 'Cancelled On Ward (COW)' : 'Ambulance Transfer'}`,
          `TRUST: ${invoice.job.referrerDetails.trustLocation}`,
          `FROM: ${pickupLocation} , ${invoice.job.journeyDetails.journeys[0].pickUpLocationArea}`,
          `TO: ${dropOffLocation} , ${invoice.job.journeyDetails.journeys[0].dropOffLocationArea}`,
          `DATE: ${formatDateTime(invoice.job.journeyDetails.journeys[0].dateTimePickup)}`,
          `MILEAGE: ${invoice.job.journeyDetails.distance}`,
          `DOB: ${dob}`,
          `INITIALS: ${initials}`,
          `Assigned to: ${invoice.assignedDriversUsername.toUpperCase()}`,
        ].join("\n");

        const unitAmount = parseFloat(invoice.job.journeyDetails.price);
        const lineAmount = parseFloat(invoice.job.journeyDetails.price);

        if (isNaN(unitAmount) || isNaN(lineAmount)) {
          console.error('Invalid amount detected in invoice:', invoice);
          return null;
        }

        let contactID = ''

        if (invoice.job.referrerDetails.trustLocation.includes("Northern")) {
          contactID = 'e7d5bed1-86e7-4690-b130-3b5f7ed3c83f'
        } else if (
          invoice.job.referrerDetails.trustLocation.includes("Western")
        ) {
          contactID = 'e246a269-38d1-46a4-b04e-c5e58cd1a241'
        }

        return {
          type: 'ACCREC',
          invoiceNumber: invoice.job.referenceCode.toUpperCase(),
          contact: {
            contactID: contactID
          },
          lineItems: [
            {
              description: description,
              quantity: 1.0,
              unitAmount: unitAmount,
              accountCode: "201",
              taxType: "NONE",
              lineAmount: lineAmount,
              inventoryItemCode: 'Patient Transfer Services'
            }
          ],
          date: moment(invoice.job.journeyDetails.journeys[0].dateTimePickup).format('YYYY-MM-DD'),
          dueDate: moment(invoice.job.journeyDetails.journeys[0].dateTimePickup).add(30, 'days').format('YYYY-MM-DD'),
          reference: invoice.job.referenceCode.toUpperCase(),
          status: 'DRAFT'
        };
      }).filter(invoice => invoice !== null) // Filter out any invalid invoices
    };

    try {
      const response = await axios.post(`${BASE_URL}/api/xero/create-invoice/${authData.userId}`, invoices);
      if (response.data.success) {
        toast.success("🔥 Invoices have been successfully sent to xero.");
        setLoading(false);
      }
      console.log('Invoice creation response:', response.data);
    } catch (err) {
      console.error('Error sending invoices:', err);
    }
  };

  return (
    <div className="px-10 my-8">
      <div>
        <div className="flex flex-row justify-between">
          <p className="font-bold text-2xl">{`Invoicing (${invoices ? invoices.length : ""
            })`}</p>
          <div className="flex flex-row items-center gap-4">
            <Select value={activeTab} onValueChange={(value) => {
              clearInvoices();
              setActiveTab(value);
            }}>
              <SelectTrigger>{activeTab}</SelectTrigger>
              <SelectContent>
                <SelectItem value="Journey">Journey</SelectItem>
                <SelectItem value="Shift">Shift</SelectItem>
                <SelectItem value="NIASRenal">NIAS Renal</SelectItem>
              </SelectContent>
            </Select>
            <DatePickerWithRange
              className="my-datepicker"
              onChange={handleDateChange}
            />
            <Button
              onClick={() => getInvoices()}
              className="font-bold text-2xl"
            >
              Get Invoices
            </Button>
            <Button
              onClick={() => downloadCSV()}
              className="font-bold text-2xl"
            >
              Import to Xero
            </Button>
          </div>
        </div>
        <div className="w-full overflow-x-scroll md:overflow-x-hidden mt-4">
          <div className="mb-4 flex">
            <input
              type="text"
              placeholder="Search by name or reference code"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
            />
          </div>
          {activeTab === 'Journey' && (
            <InvoicingJourney
              loading={loading}
              invoices={invoices}
              onSelectedItemsChange={handleSelectedItemsChange}
            />
          )}
          {activeTab === 'Shift' && (
            <InvoicingShift
              loading={loading}
              invoices={invoices}
              onSelectedItemsChange={handleSelectedShiftItemsChange}
            />
          )}
          {activeTab === 'NIASRenal' && (
            <InvoicingRenal
              loading={loading}
              invoices={invoices}
              onSelectedItemsChange={handleSelectedShiftItemsChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoicingPanel;
