import AddContacts from "./Repatriation Settings Components/AddContacts"
import AddFerryLocation from "./Repatriation Settings Components/AddFerryLocations"
import AddLocation from "./Repatriation Settings Components/AddLocation"
import ManageStaff from "./Repatriation Settings Components/ManageStaffing"

const RepatriationJobSettings = () => {
    return(
        <div className="py-6 grid grid-cols-3 justify-between gap-4">
            <AddLocation/>
            <ManageStaff/>
            <AddFerryLocation/>
            <AddContacts/>
        </div>
    )
}

export default RepatriationJobSettings