import React, { useState, useEffect } from "react";
import Logo from "../../Assets/Images/JaceLOGO2.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Button, buttonVariants } from "../Global/UI/Button";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../Screens/Settings/DASHBOARD_SETTINGS";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/Global/UI/table";


const NiasJobList = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}/api/nias/job/get-all`
        );
        if (response.data.success) {
          setLoading(false);
          setItems(response.data.results);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            setLoading(false);
            toast.error(error.response.data.message);
            console.error("Item not found:", error.response.data.message);
          }
        } else if (error.request) {
          setLoading(false);
          toast.error("An unknown error has occurred");
          console.error("No response received:", error.request);
        } else {
          setLoading(false);
          toast.error("An unknown error has occurred");
          console.error("Error setting up the request:", error.message);
        }
      }
    };

    fetchData();
  }, []);

  const history = useHistory();

  const handleViewClick = (item) => {
    history.push({
      pathname: "/view-nias-job",
      state: { item: item },
    });
  };

  const getShortAddress = (address) => {
    switch (address) {
      case "4 Newbridge Rd, Coleraine BT52 1HS":
        return "Causeway Hospital";
      case "Causeway Hospital, Newbridge Road, Coleraine, UK":
        return "Causeway Hospital";
      case "Causeway Hospital: Emergency Room, Newbridge Road, Coleraine, UK":
        return "Causeway Hospital";
      case "Bush Rd, Antrim BT41 2RL":
        return "Antrim Hospital";
      case "Antrim Area Hospital, Bush Road, Antrim, UK":
        return "Antrim Hospital";
      case "Antrim Area Hospital Emergency Department, Bush Road, Antrim, UK":
        return "Antrim Hospital";
      case "Glenshane Rd, Londonderry BT47 6SB":
        return "Altnagelvin Hospital";
      case "Altnagelvin Hospital, Altnagelvin, Londonderry, UK":
        return "Altnagelvin Hospital";
      case "South West Acute Hospital, Irvinestown Road, Enniskillen, UK":
        return "South West Acute";
      case "274 Grosvenor Rd, Belfast BT12 6BA":
        return "Royal Hospital";
      case "Upper Newtownards Rd, Dundonald, Belfast BT16 1RH":
        return "Ulster Hospital";
      case "Lisburn Rd, Belfast BT9 7AB":
        return "Belfast City Hospital";
      case "Mater Hospital, Crumlin Road, Belfast, UK":
        return "Mater Hospital";
    }
  };


  return (
    <div className="w-full overflow-x-scroll md:overflow-x-hidden mt-4">
      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-[500px 1fr 500px 1fr] gap-4 mt-4">
          {[...Array(15)].map((_, index) => (
            <div
              key={index}
              className="overflow-hidden border border-gray-200 rounded-md animate-pulse"
            >
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="w-full">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Trust</TableHead>
                  <TableHead>Start Date</TableHead>
                  <TableHead>Starting Location</TableHead>
                  <TableHead>Start Time</TableHead>
                  <TableHead>Staff</TableHead>
                  <TableHead>Total Miles</TableHead>
                  <TableHead>Vehicle</TableHead>
                  <TableHead>Price</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {!loading ? items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="flex items-center">
                        <img
                          src={Logo}
                          className="h-8 w-8 rounded-full"
                          alt=""
                        />
                        <span>{item.jobDetails.trust ? item.jobDetails.trust : "N/A"}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      {new Date(
                        item.jobDetails.jobStartDate
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </TableCell>
                    <TableCell>{item.jobs.jobList ? item.jobs.jobList[0].startingAddress : "N/A"}</TableCell>
                    <TableCell>{item.shiftDetails.logonTime ? item.shiftDetails.logonTime : "N/A"}</TableCell>
                    <TableCell>
                      {item.staffDetails.staffList.map((staff, index) => (
                        <div key={index}>{staff.name}</div>
                      ))}
                    </TableCell>                    <TableCell>{item.jobs.totalMiles.toFixed(0)}</TableCell>
                    <TableCell>{item.jobDetails.vehicle}</TableCell>
                    <TableCell>
                      {item.jobDetails.price}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="default"
                        size="default"
                        className={buttonVariants({
                          variant: "default",
                          size: "default",
                        })}
                        onClick={() => handleViewClick(item)}
                      >
                        View Job
                      </Button>
                    </TableCell>
                  </TableRow>
                )) : 'Loading...'}
              </TableBody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
};
export default NiasJobList;
