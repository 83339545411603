import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../Screens/Settings/DASHBOARD_SETTINGS";
import { Input } from "../../Global/UI/Input";
import { Label } from "../../Global/UI/Label";

const ReferrerDetails = ({ onChange }) => {
    const [referrerDetails, setReferrerDetails] = useState({
        organisation: '',
        emailAddress: '',
        phoneNumber: '',
    });
    const [locationData, setLocationData] = useState([]);

    useEffect(() => {
        const getLocations = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/settings/locations/trusts`);
                if (response.data.success) {
                    setLocationData(response.data.results);
                }
            } catch (error) {
                if (error.response) {
                    toast.error("An unknown error has occurred");
                } else if (error.request) {
                    toast.error("An unknown error has occurred");
                    console.error('No response received:', error.request);
                } else {
                    toast.error("An unknown error has occurred");
                    console.error('Error setting up the request:', error.message);
                }
            }
        };

        getLocations();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updates = { [name]: value };

        const updatedDetails = {
            ...referrerDetails,
            ...updates,
        };

        setReferrerDetails(updatedDetails);
        onChange(updatedDetails);
    };

    return (
        <div>
            <p className='font-bold text-2xl mb-4'>Referrer Details</p>
            <div className='grid gap-4 grid-cols-1 md:grid-cols-3 mb-4'>
                <div>
                    <Label htmlFor="organisation" className="block font-bold mb-2">Organisation name:</Label>
                    <Input
                        value={referrerDetails.organisation}
                        onChange={handleChange}
                        type="text"
                        id="organisation"
                        name="organisation"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="emailAddress" className="block font-bold mb-2">Email Address:</Label>
                    <Input
                        value={referrerDetails.emailAddress}
                        onChange={handleChange}
                        type="text"
                        id="emailAddress"
                        name="emailAddress"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
                <div>
                    <Label htmlFor="phoneNumber" className="block font-bold mb-2">Phone Number:</Label>
                    <Input
                        value={referrerDetails.phoneNumber}
                        onChange={handleChange}
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>
            </div>
        </div>
    );
};

export default ReferrerDetails;
