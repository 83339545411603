
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Check, ChevronsUpDown } from "lucide-react"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogClose, DialogDescription } from '../Components/Global/UI/Dialog';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "../Components/Global/UI/Command"
import { Popover, PopoverContent, PopoverTrigger } from "../Components/Global/UI/Popover"
import { Button, buttonVariants } from '../Components/Global/UI/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ViewJourneyDetails from '../Components/View Job/ViewJourneyDetails';
import ViewReferrerDetails from '../Components/View Job/ViewReferrerDetails';
import ViewSpecialRequirements from '../Components/View Job/ViewSpecialRequirements';
import ViewPatientDetails from '../Components/View Job/ViewPatientDetails';
import { BASE_URL } from './Settings/DASHBOARD_SETTINGS';
import ShowJobLogs from '../Components/Job Components/Show_Logs';
import { useAuth } from '../Context/AuthProvider';

const FullOverviewScreen = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const { item } = location.state || {};
    const [drivers, setDrivers] = useState(null);
    const [referenceNumber] = useState(item.referenceCode);
    const [selectedDriverUsername, setSelectedDriverUsername] = React.useState("");
    const history = useHistory();
    const [jobDetails, setJobDetails] = useState({
        referrerDetails: item.referrerDetails || {},
        patientDetails: item.patientDetails || {},
        journeyDetails: item.journeyDetails || {},
        specialRequirements: item.specialRequirements || {},
        notes: item.notes || ''
    });

    const [isLogsOpen, setIsLogsOpen] = useState(false)
    const { authData } = useAuth();

    const getInitials = (name) => {
        if (!name) return "";
        name = name.trim();
        const nameArray = name.split(' ');
        const initials = nameArray.map((word) => word[0].toUpperCase()).join('');
        return initials;
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleUpdateJob = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/Services/${item._id}/updateRequest`, jobDetails);
            if (response.data.success) {
                toast.success(`Sucessfully updated Job: ${item._id}.`);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                    console.error('Item not found:', error.response.data.message);
                }
            } else if (error.request) {
                toast.error("An unknown error has occured");
                console.error('No response received:', error.request);
            } else {
                toast.error("An unknown error has occured");
                console.error('Error setting up the request:', error.message);
            }
        }
    };

    const handleAssignJob = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/Services/assignJob/${selectedDriverUsername.toUpperCase()}/${item._id}`);
            if (response.data.success) {
                toast.success(`Sucessfully Assigned Job: ${item._id}.`);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    toast.error(error.response.data.message);
                    console.error('Item not found:', error.response.data.message);
                }
            } else if (error.request) {
                toast.error("An unknown error has occured");
                console.error('No response received:', error.request);
            } else {
                toast.error("An unknown error has occured");
                console.error('Error setting up the request:', error.message);
            }
        }
    };

    const handleJobDelete = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/Services/${item._id}/remove-request`);
            if (response.data.success) {
                toast.success(`Sucessfully deleted Job: ${item._id}.`);
                history.push('/')
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                    console.error('Validation errors:', error.response.data.errors);
                }
            } else if (error.request) {
                toast.error("An unknown error has occured");
                console.error('No response received:', error.request);
            } else {
                toast.error("An unknown error has occured");
                console.error('Error setting up the request:', error.message);
            }
        }
    }

    const cancelJob = async (status) => {
        try {
            const response = await axios.get(`${BASE_URL}/api/Services/${item._id}/cancel-job`);
            if (response.data.success) {
                toast.success(`Sucessfully Cancelled job: ${item._id}.`);
                history.push('/')
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                    console.error('Validation errors:', error.response.data.errors);
                }
            } else if (error.request) {
                toast.error("An unknown error has occured");
                console.error('No response received:', error.request);
            } else {
                toast.error("An unknown error has occured");
                console.error('Error setting up the request:', error.message);
            }
        }
    }

    const fetchDrivers = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/drivers/getDrivers`);
            if (response.data.success) {
                setDrivers(response.data.drivers);
            }
        } catch (error) {
            console.error('Error fetching drivers:', error.message);
            toast.error('Failed to fetch drivers');
        }
    };

    const handleLogClick = () => {
        console.log('Logs button clicked')
        setIsLogsOpen(!isLogsOpen);
    };


    // const getPricing = async () => {
    //     try {
    //         const response = await axios.post(`${BASE_URL}/api/settings/locations/calculateDistance` ,{
    //             pickupAddress: jobDetails.journeyDetails.pickupLocation !== 'Other' ? jobDetails.journeyDetails.pickupLocation : jobDetails.journeyDetails.otherPickupLocation,
    //             dropOffAddress: jobDetails.journeyDetails.dropOffLocation !== 'Other' ? jobDetails.journeyDetails.dropOffLocation : jobDetails.journeyDetails.otherDropOffLocation,
    //             trustName: jobDetails.referrerDetails.trustLocation
    //         });
    //         if (response.data.success) {
    //             const { distance, totalPrice } = response.data;
    //             setPricing({ distance, price: totalPrice });
    //         }
    //     } catch (error) {
    //         console.error('Error fetching trusts:', error.message);
    //         toast.error('Failed to fetch trusts');
    //     }
    // };

    useEffect(() => {
        fetchDrivers();
    }, [jobDetails.journeyDetails]);

    const handleJourneyDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            journeyDetails: data
        }));
    };

    const handleReferrerDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            referrerDetails: data
        }));
    };

    const handlePatientDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            patientDetails: data
        }));
    };

    const handleSpecialRequirementsChange = (data) => {
        console.log(data)
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            specialRequirements: data
        }));
    };

    const handleNotesChange = (e) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            notes: e.target.value
        }));
    };


    return (
        <div className="full-overview m-10">

            <Dialog open={isDialogOpen} onOpenChange={handleCloseDialog}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Assign an ambulance</DialogTitle>
                        <DialogClose />
                    </DialogHeader>
                    <DialogDescription>
                        Assign an ambulance to this job.
                    </DialogDescription>
                    {drivers && (
                        <Popover open={open} onOpenChange={setOpen} >
                            <PopoverTrigger asChild>
                                <Button
                                    variant="outline"
                                    role="combobox"
                                    aria-expanded={open}
                                    className="w-auto justify-between"
                                >
                                    {selectedDriverUsername ? selectedDriverUsername.toUpperCase() : "Select Driver..."}
                                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-full p-0">
                                <Command>
                                    <CommandInput placeholder="Search Driver..." />
                                    <CommandEmpty>No Driver found.</CommandEmpty>
                                    <CommandGroup>
                                        {drivers.map((framework) => (
                                            <CommandItem
                                                key={framework.username}
                                                value={framework.username}
                                                onSelect={(currentValue) => {
                                                    const newSelectedDriver = currentValue === selectedDriverUsername ? "" : currentValue;
                                                    setSelectedDriverUsername(newSelectedDriver);
                                                    setOpen(false);
                                                }}
                                            >
                                                <Check
                                                    className={`mr-2 h-4 w-4 ${selectedDriverUsername.toUpperCase() === framework.username ? "opacity-100" : "opacity-0"}`}
                                                />
                                                {framework.username}
                                            </CommandItem>
                                        ))}
                                    </CommandGroup>
                                </Command>
                            </PopoverContent>
                        </Popover>
                    )}
                    <Button
                        variant="default"
                        size="lg"
                        className={buttonVariants({ variant: "default", size: "lg" })}
                        onClick={() => handleAssignJob()}>
                        Assign Job
                    </Button>
                </DialogContent>
            </Dialog>



            <div className='flex flex-row justify-between'>
                <h2 className='font-semibold text-3xl'>Booking Overview - {referenceNumber.toUpperCase()}</h2>
                <div>
                    {item.jobTimeStamps && (
                        <Button
                            variant="default"
                            size="lg"
                            className={`${buttonVariants({ variant: "default", size: "lg" })} mr-2`}
                            onClick={() => handleLogClick()}>
                            View Log
                        </Button>
                    )}

                    <Button
                        variant="default"
                        size="lg"
                        className={buttonVariants({ variant: "default", size: "lg" })}
                        onClick={() => handleUpdateJob()}>
                        Save Changes
                    </Button>
                    <Button
                        variant="default"
                        size="lg"
                        className={`${buttonVariants({ variant: "default", size: "lg" })} ml-2`}
                        onClick={() => setIsDialogOpen(true)}>
                        Assign Job
                    </Button>
                    <Button
                        variant="default"
                        size="lg"
                        className={`${buttonVariants({ variant: "default", size: "lg" })} ml-2`}
                        onClick={() => cancelJob()}>
                        Cancel Job
                    </Button>
                    <Button
                        variant="default"
                        size="lg"
                        className={`${buttonVariants({ variant: "default", size: "lg" })} ml-2`}
                        onClick={() => handleJobDelete()}>
                        Delete Job
                    </Button>
                </div>

            </div>

            {/* Referrer Details */}
            <div className='mt-6'>
                <ViewReferrerDetails onChange={handleReferrerDetailsChange} data={item} />
                <ViewJourneyDetails onChange={handleJourneyDetailsChange} data={item} />
                <ViewPatientDetails onChange={handlePatientDetailsChange} data={item} />
                <ViewSpecialRequirements onChange={handleSpecialRequirementsChange} data={item} />
                <div className='w-full mt-10'>
                    <label className='font-bold text-lg' htmlFor="notes">Operator Notes:</label>
                    <textarea
                        id="notes"
                        className="w-full mt-2 p-2 border rounded-md"
                        rows="4"
                        value={jobDetails.notes}
                        onChange={handleNotesChange}
                        placeholder="Enter any additional notes or observations..."
                    />
                </div>
            </div>


            <p className='font-semibold text-3xl'>Invoice Details</p>
            <p>Ambulance Transfer</p>
            <p>TRUST: {jobDetails.referrerDetails.trustLocation}</p>
            <p>FROM: {jobDetails.journeyDetails.journeys[0].pickupLocation !== 'Other' ? jobDetails.journeyDetails.journeys[0].pickupLocation : jobDetails.journeyDetails.journeys[0].otherPickupLocation}</p>
            <p>TO: {jobDetails.journeyDetails.journeys[0].dropOffLocation !== 'Other' ? jobDetails.journeyDetails.journeys[0].dropOffLocation : jobDetails.journeyDetails.journeys[0].otherDropOffLocation}</p>
            <p className='text-black'>MILEAGE: {jobDetails.journeyDetails.distance}</p>
            <p className='text-black'>DOB: {jobDetails.patientDetails.dob}</p>
            <p className='text-black'>INITALS: {getInitials(jobDetails.patientDetails.name)}</p>
            <p className='text-black'>Assigned to: {item.assignedDriverId && item.assignedDriverId.username}</p>
            {authData.role === '1' && (
                <p className='text-black'>Total Price: £{jobDetails.journeyDetails.price}</p>
            )}

            {isLogsOpen ? (
                <ShowJobLogs item={item} isOpen={isLogsOpen} handleLog={handleLogClick} />
            ) : <div></div>}
        </div>
    );
};

export default FullOverviewScreen;
