import React from 'react'
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { BASE_URL } from '../Settings/DASHBOARD_SETTINGS';
import { Button } from '../../Components/Global/UI/Button';
import TrustDetails from '../../Components/Add Job/Renal/TrustDetails';
import JourneyDetails from '../../Components/Add Job/Renal/JourneyDetails';
import PatientDetails from '../../Components/Add Job/Renal/PatientDetails';

const AddNIASRenalJob = () => {

    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const [jobDetails, setJobDetails] = React.useState({
        jobDetails: {},
        patientDetails: {},
        jobs: {},
    });

    const handleNavigation = () => {
        history.push('/');
    };

    const handleServiceRequest = async () => {
        setLoading(true)
        console.log('Jobs:', JSON.stringify({
            jobDetails: jobDetails.jobDetails,
            patientDetails: jobDetails.patientDetails,
            jobs: jobDetails.jobs,
        }))
        try {

            // if (!jobDetails.journeyDetails || !jobDetails.journeyDetails.journeys || 
            //     !jobDetails.journeyDetails.journeys[0] || !jobDetails.journeyDetails.journeys[0].dateTimePickup) {
            //     setLoading(false);
            //     toast.error('Please provide pickup date and time.');
            //     throw new Error('Please provide pickup date and time.');
            // }


            const response = await fetch(`${BASE_URL}/api/nias-renal/create-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jobDetails: jobDetails.jobDetails,
                    patientDetails: jobDetails.patientDetails,
                    jobs: jobDetails.jobs,
                }),
            });

            //let response
            if (!response.ok) {
                setLoading(false)
                throw new Error('Failed to submit service request');
            }

            const data = await response.json();

            if (data.success === true) {
                setLoading(false)
                toast.success(data.msg);
                handleNavigation()
            } else {
                setLoading(false)
                toast.error(data.msg);
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.msg);
            setError('Error submitting service request: ' + error.message);
        }
    };


    const handleJobDetailsChange = (data) => {
        console.log('Data Sent Back:', data)
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            jobDetails: data
        }));
    };

    const handlePatientDetailsChange = (data) => {
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            patientDetails: data
        }));
    };

    const handleJourneyDetailsChange = (data) => {
        console.log('Data Sent Back From Journeys:', data)
        setJobDetails(prevJobDetails => ({
            ...prevJobDetails,
            jobs: data
        }));
    };



    return (
        <div className="px-6 my-8">
            <TrustDetails onChange={handleJobDetailsChange} />
            <PatientDetails onChange={handlePatientDetailsChange} />
            <JourneyDetails onChange={handleJourneyDetailsChange} />
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <Button
                onClick={() => handleServiceRequest()}
                disabled={loading}
                className='w-full py-3'
            >
                {loading ? 'Submitting...' : 'Submit Request'}
            </Button>
        </div>
    )
}

export default AddNIASRenalJob