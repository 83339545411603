import { useEffect, useState } from "react"
import { Button } from "../Global/UI/Button"
import { Input } from "../Global/UI/Input"
import axios from "axios"
import { toast } from "react-toastify";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../Global/UI/Select";
import { BASE_URL } from "../../Screens/Settings/DASHBOARD_SETTINGS";

const JobSettings = () => {

    const [trustsData, setTrustsData] = useState('')

    const [newLocation, setNewLocation] = useState('')
    const [selectedTrust, setSelectedTrust] = useState()

    const [newTrust, setNewTrust] = useState('')
    const [trusts, setTrusts] = useState([])

    const [locations, setLocations] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [departmentName, setDepartmentName] = useState();
    const [departmentMobile, setDepartmentMobile] = useState();


    const [operatorTrust, setoperatorTrust] = useState()
    const [operatorLocation, setoperatorLocation] = useState()
    const [operatorName, setoperatorName] = useState()

    const [pricing, setPricing] = useState([]);
    const [maxMiles, setMaxMiles] = useState('');
    const [price, setPrice] = useState('');


    useEffect(() => {
        if (selectedTrust) {
            setPricing(selectedTrust.pricing);
        }
    }, [selectedTrust]);

    const addPricingOption = () => {
        const newOption = { maxMiles: maxMiles, price: price };
        setPricing([...pricing, newOption]);
        setMaxMiles('');
        setPrice('');
    };

    const updatePricingOption = (index) => {
        const updatedPricing = [...pricing];
        updatedPricing[index] = { maxMiles: maxMiles, price: price };
        setPricing(updatedPricing);
        setMaxMiles('');
        setPrice('');
    };

    const deletePricingOption = (index) => {
        const updatedPricing = pricing.filter((option, i) => i !== index);
        setPricing(updatedPricing);
    };

    const updatePricing = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/settings/locations/trusts/${selectedTrust._id}/pricing`, pricing);
            if (response.data.success) {
                toast.success(`Pricing updated successfully.`);
            }
        } catch (error) {
            console.error('Error updating pricing:', error);
            toast.error("An unknown error has occurred");
        }
    };

    const addTrust = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/settings/locations/add-trust`, {
                name: newTrust
            });
            if (response.data.success) {
                toast.success(`Trust ${newTrust} has been successfully added.`);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                    console.error('Validation errors:', error.response.data.errors);
                }
            } else if (error.request) {
                toast.error("An unknown error has occured");
                console.error('No response received:', error.request);
            } else {
                toast.error("An unknown error has occured");
                console.error('Error setting up the request:', error.message);
            }
        }
    };
    const addLocation = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/settings/locations/trusts/${selectedTrust._id}/locations`, {
                name: newLocation,
            });
            if (response.data.success) {
                toast.success(`Location ${newLocation} has been successfully added.`);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                    console.error('Validation errors:', error.response.data.errors);
                }
            } else if (error.request) {
                toast.error("An unknown error has occured");
                console.error('No response received:', error.request);
            } else {
                toast.error("An unknown error has occured");
                console.error('Error setting up the request:', error.message);
            }
        }
    };
    const addDepartment = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/settings/locations/trusts/${selectedTrust._id}/locations/${selectedLocation._id}/departments`, {
                name: departmentName,
                mobileNumber: departmentMobile
            });
            if (response.data.success) {
                toast.success(`Department added to ${selectedLocation} successfully.`);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                    console.error('Validation errors:', error.response.data.errors);
                }
            } else if (error.request) {
                toast.error("An unknown error has occured");
                console.error('No response received:', error.request);
            } else {
                toast.error("An unknown error has occured");
                console.error('Error setting up the request:', error.message);
            }
        }
    };
    const addOperator = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/settings/locations/trusts/${operatorTrust._id}/locations/${operatorLocation._id}/operators`, {
                name: operatorName,
            });
            if (response.data.success) {
                toast.success(`Operator added to ${operatorLocation} successfully.`);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                    console.error('Validation errors:', error.response.data.errors);
                }
            } else if (error.request) {
                toast.error("An unknown error has occured");
                console.error('No response received:', error.request);
            } else {
                toast.error("An unknown error has occured");
                console.error('Error setting up the request:', error.message);
            }
        }
    };

    useEffect(() => {
        const getTrusts = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/settings/locations/trusts`);
                if (response.data.success) {
                    setTrustsData(response.data.results)
                }
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 400) {

                    }
                } else if (error.request) {
                    toast.error("An unknown error has occured");
                    console.error('No response received:', error.request);
                } else {
                    toast.error("An unknown error has occured");
                    console.error('Error setting up the request:', error.message);
                }
            }
        }

        getTrusts()
    }, [])

    return (
        <div>
            <div className="grid grid-cols-3 gap-4">
                <div className="border p-4 my-4 flex flex-col" >
                    <p className="text-center font-semibold text-lg mb-4">Add a new Trust</p>
                    <input
                        value={newTrust}
                        onChange={(e) => setNewTrust(e.target.value)}
                        className="w-full p-2 mb-4 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                    <Button onClick={() => addTrust()}>
                        Add Location
                    </Button>
                </div>
                <div className="border p-4 my-4 flex flex-col" >
                    <p className="text-center font-semibold text-lg mb-4">Add a new Location</p>
                    <Select value={selectedTrust} onValueChange={setSelectedTrust}>
                        <SelectTrigger>{selectedTrust ? selectedTrust.name : "Select Trust"}</SelectTrigger>
                        <SelectContent>
                            {trustsData && trustsData.map((trust) => (
                                <SelectItem key={trust._id} value={trust}>
                                    {trust.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>

                    <input
                        onChange={(e) => setNewLocation(e.target.value)}
                        value={newLocation}
                        type="text"
                        id="newLocation"
                        name="newLocation"
                        className="w-full p-2 mb-4 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                    <Button onClick={() => addLocation()}>
                        Add Location
                    </Button>
                </div>

                <div className="border p-4 my-4 flex flex-col">
                    <p className="text-center font-semibold text-lg mb-4">Add a new department</p>

                    {/* Select component for selecting trust */}
                    <Select value={selectedTrust} onValueChange={setSelectedTrust}>
                        <SelectTrigger>{selectedTrust ? selectedTrust.name : "Select Trust"}</SelectTrigger>
                        <SelectContent>
                            {trustsData && trustsData.map((trust) => (
                                <SelectItem key={trust._id} value={trust}>
                                    {trust.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>

                    {/* Select component for selecting location within the selected trust */}
                    {selectedTrust && (
                        <Select value={selectedLocation} onValueChange={setSelectedLocation}>
                            <SelectTrigger>{selectedLocation ? selectedLocation.name : "Select Location"}</SelectTrigger>
                            <SelectContent>
                                {selectedTrust.locations.map((location) => (
                                    <SelectItem key={location._id} value={location}>
                                        {location.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    )}

                    <input
                        placeholder="Department Name"
                        onChange={(e) => setDepartmentName(e.target.value)}
                        value={departmentName}
                        type="text"
                        id="departmentName"
                        name="departmentName"
                        className="w-full p-2 my-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />

                    <input
                        placeholder="Department Mobile"
                        onChange={(e) => setDepartmentMobile(e.target.value)}
                        value={departmentMobile}
                        type="text"
                        id="departmentNumber"
                        name="departmentNumber"
                        className="w-full p-2 my-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                    <Button onClick={() => addDepartment()}>
                        Add Department
                    </Button>
                </div>
                <div className="border p-4 my-4 flex flex-col">
                    <p className="text-center font-semibold text-lg mb-4">Add Operator</p>

                    <Select value={operatorTrust} onValueChange={setoperatorTrust}>
                        <SelectTrigger>{operatorTrust ? operatorTrust.name : "Select Trust"}</SelectTrigger>
                        <SelectContent>
                            {trustsData && trustsData.map((trust) => (
                                <SelectItem key={trust._id} value={trust}>
                                    {trust.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>

                    {operatorTrust && (
                        <Select value={operatorLocation} onValueChange={setoperatorLocation}>
                            <SelectTrigger>{operatorLocation ? operatorLocation.name : "Select Location"}</SelectTrigger>
                            <SelectContent>
                                {operatorTrust.locations.map((location) => (
                                    <SelectItem key={location._id} value={location}>
                                        {location.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    )}

                    <input
                        placeholder="Operator Name"
                        onChange={(e) => setoperatorName(e.target.value)}
                        value={operatorName}
                        type="text"
                        id="departmentName"
                        name="departmentName"
                        className="w-full p-2 my-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />


                    <Button onClick={() => addOperator()}>
                        Add Operator
                    </Button>
                </div>
                <div className="border p-4 my-4 flex flex-col" >
                    <p className="text-center font-semibold text-lg mb-4">Select Trust and View/Add Pricing</p>
                    <Select value={selectedTrust} onValueChange={(value) => setSelectedTrust(value)}>
                        <SelectTrigger>{selectedTrust ? selectedTrust.name : "Select Trust"}</SelectTrigger>
                        <SelectContent>
                            {trustsData && trustsData.map((trust) => (
                                <SelectItem key={trust._id} value={trust}>
                                    {trust.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                    {selectedTrust && (
                        <div>
                            <p className="font-semibold mb-2">Pricing Options:</p>
                            {pricing.map((option, index) => (
                                <div key={index} className="flex items-center mb-4">
                                    <Input
                                        value={option.maxMiles}
                                        onChange={(e) => {
                                            const updatedPricing = [...pricing];
                                            updatedPricing[index].maxMiles = e.target.value;
                                            setPricing(updatedPricing);
                                        }}
                                        placeholder="Max Miles"
                                        className="mr-2"
                                    />
                                    <Input
                                        value={option.price}
                                        onChange={(e) => {
                                            const updatedPricing = [...pricing];
                                            updatedPricing[index].price = e.target.value;
                                            setPricing(updatedPricing);
                                        }}
                                        placeholder="Price"
                                        className="mr-2"
                                    />
                                    <Button onClick={() => updatePricingOption(index)}>
                                        Update
                                    </Button>
                                    <Button onClick={() => deletePricingOption(index)}>
                                        Delete
                                    </Button>
                                </div>
                            ))}
                            <p className="font-semibold mt-4">Add Pricing Option:</p>
                            <div className="flex items-center">
                                <Input
                                    value={maxMiles}
                                    onChange={(e) => setMaxMiles(e.target.value)}
                                    placeholder="Max Miles"
                                    className="mr-2"
                                />
                                <Input
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                    placeholder="Price"
                                    className="mr-2"
                                />
                                <Button onClick={() => addPricingOption()}>
                                    Add Pricing
                                </Button>
                                <Button onClick={() => updatePricing()}>
                                    Update Pricing
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default JobSettings