import axios from "axios";
import React from "react";
import CleaningLogTable from "./CleaningLogTable";
import Logo from '../../../Assets/Images/JaceLOGO2.png';
import { Checkbox } from "../../Global/UI/checkbox";
import { Input } from "../../Global/UI/Input";
import { Button } from "../../Global/UI/Button";
import { usePDF } from 'react-to-pdf';
import { BASE_URL } from "../../../Screens/Settings/DASHBOARD_SETTINGS";

const PomLogs = ({ log, driverId }) => {
    const [PomLog, setPomLog] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

    React.useEffect(() => {
        const fetchLog = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${BASE_URL}/api/drivers/${driverId}/logs/${log.logId}`);
                setPomLog(response.data.log);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching drivers:', error.message);
                setLoading(false);
            }
        };
        fetchLog();
    }, []);

    return (
        <>
            <div className="p-10 justify-center flex flex-row gap-4 items-center">
                <div className="flex flex-row items-center">
                    <img src={Logo} width={30} alt="Jace Logo" />
                    <p className="pl-1 font-semibold">Completed By: <span className="font-normal">{log.signature}</span></p>
                </div>
                <p className="font-semibold">Log ID: <span className="font-normal">{log.logId}</span></p>

                <p className="font-semibold">Completed: <span className="font-normal">{new Date(log.timestamp).toLocaleString('en-GB')}</span></p>
                <Button onClick={() => toPDF()}>Generate PDF</Button>
            </div>
            {PomLog ? (
                <div ref={targetRef} className="w-full p-10 m-auto flex flex-col items-center">

                    <img className="mb-4" src={Logo} width={100} alt="Jace Logo" />

                    <p className="font-semibold text-2xl mb-6">{`POMs Log (${log.logSubText})`}</p>

                    <div className="flex flex-col w-1/2">
                        <p className="font-semibold text-lg mb-8">To be completed at the END of each shift.</p>
                        {Object.entries(PomLog.items).map(([label, value], index) => (
                            <div key={index} className="flex flex-row items-center mb-2">
                                <p className="font-semibold w-1/2">{label}: </p>
                                <Input
                                    type="text"
                                    value={value}
                                    readOnly
                                />
                            </div>
                        ))}

                    </div>
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </>
    )
}

export default PomLogs;
