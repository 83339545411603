import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Tabs, TabsList, TabsTrigger } from '../Global/UI/tabs';
import { TabsContent } from '@radix-ui/react-tabs';
import DriverJobs from './DriverJobs';
import DriverLogs from './DriverLogs';

const DriverFullDetails = () => {

    const location = useLocation();
    const { driver } = location.state || {};

    return (
        <div className='p-10'>

            <p className="font-semibold text-2xl mb-4">
                Driver: <span className="font-normal">{driver.username}</span>
            </p>

            <Tabs defaultValue="jobs">
                <TabsList className='mb-4'>
                    <TabsTrigger className='font-semibold' value="jobs">Driver Jobs</TabsTrigger>
                    <TabsTrigger className='font-semibold' value="logs">Driver Logs</TabsTrigger>
                </TabsList>

                <TabsContent value="jobs">
                    <DriverJobs driver={driver} />
                </TabsContent>
                <TabsContent value="logs">
                    <DriverLogs driver={driver}/>
                </TabsContent>
            </Tabs>
        </div>
    )
};

export default DriverFullDetails;
