import React, { useState, useEffect } from 'react';
import fetchTests from '../../Controllers/fetchTests';
import BookingCard from './BookingCard';
import Logo from '../../Assets/Images/JaceLOGO2.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Button, buttonVariants } from '../Global/UI/Button';
import { toast } from 'react-toastify';
import { DatePickerWithRange } from '../Global/UI/DateRangePicker';
import axios from 'axios';
import { Pagination } from '../Global/UI/Pagination';
import { format } from 'date-fns';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/Global/UI/table"
import { Select, SelectContent, SelectItem, SelectTrigger } from "../../Components/Global/UI/Select";
import { BASE_URL } from '../../Screens/Settings/DASHBOARD_SETTINGS';


const JobList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = sessionStorage.getItem('currentPage');
    return storedPage ? Number(storedPage) : 1;
  });
  const [itemsPerPage] = useState(15);
  const [selectedRequirements, setSelectedRequirements] = useState()


  const fetchData = async () => {
    const formattedFromDate = selectedDate && selectedDate.from ? format(selectedDate.from, 'yyyy-MM-dd') : null;
    const formattedToDate = selectedDate && selectedDate.to ? format(selectedDate.to, 'yyyy-MM-dd') : null;
    try {
      setLoading(true);
      let query = `page=${currentPage}&limit=10${formattedFromDate ? `&startDate=${formattedFromDate}` : ''}${formattedToDate ? `&endDate=${formattedToDate}` : ''}${searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : ''}`;

      if (selectedRequirements) {
        query += `&selectedOptions=${encodeURIComponent(selectedRequirements)}`;
      }

      const response = await axios.get(`${BASE_URL}/api/services/getRequests?${query}`);
      if (response.data.success) {
        setItems(response.data.results);
        setFilteredItems(response.data.results);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setLoading(false);
          toast.error(error.response.data.message);
          console.error('Validation errors:', error.response.data.errors);
        }
      } else if (error.request) {
        setLoading(false);
        toast.error("An unknown error has occurred");
        console.error('No response received:', error.request);
      } else {
        setLoading(false);
        toast.error("An unknown error has occurred");
        console.error('Error setting up the request:', error.message);
      }
    }
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };


  useEffect(() => {
    sessionStorage.setItem('currentPage', currentPage);
    fetchData();
  }, [currentPage, selectedDate, searchTerm, selectedRequirements]);


  const history = useHistory();

  const handleViewClick = (item) => {
    history.push({
      pathname: '/full-overview',
      state: { item: item },
    });
  };

  const formatDateTime = (dateTimePickup, timePickup) => {
    const date = new Date(dateTimePickup);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
  
    const formattedDate = `${day}/${month}/${year}`; // Format as DD/MM/YYYY
    return `${formattedDate} ${timePickup}`; // Combine with time
  }

  const JobStatus = ({ status }) => {
    let statusText, textColor, bgColor;

    switch (status) {
      case 0:
        statusText = 'Unassigned';
        textColor = 'text-red-800';
        bgColor = 'bg-red-300';
        break;
      case 1:
        statusText = 'Assigned';
        textColor = 'text-yellow-800';
        bgColor = 'bg-yellow-300';
        break;
      case 2:
        statusText = 'Accepted';
        textColor = 'text-green-800';
        bgColor = 'bg-green-300';
        break;
      case 3:
        statusText = 'Started';
        textColor = 'text-orange-800';
        bgColor = 'bg-orange-300';
        break;
      case 4:
        statusText = 'On Scene';
        textColor = 'text-blue-800';
        bgColor = 'bg-blue-300';
        break;
      case 5:
        statusText = 'Left Scene';
        textColor = 'text-indigo-800';
        bgColor = 'bg-indigo-300';
        break;
      case 6:
        statusText = 'Completed';
        textColor = 'text-teal-800';
        bgColor = 'bg-teal-300';
        break;
      case 7:
        statusText = 'Cancelled';
        textColor = 'text-white';
        bgColor = 'bg-rose-500';
        break;

      default:
        statusText = 'Unknown';
        textColor = 'text-gray-800';
        bgColor = 'bg-gray-300';
    }

    return (
      <div className={`px-2 py-1 rounded text-center mr-3 text-bold ${bgColor} ${textColor}`}>
        {statusText}
      </div>
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className='w-full overflow-x-scroll md:overflow-x-hidden mt-4'>
      {/* <p className='font-semibold text-2xl'>{`Journey Requests (${filteredItems && filteredItems.length})`}</p> */}
      <div className="grid grid-cols-5 gap-4 my-4">
        <div>
          <label htmlFor="pickupLocation" className="block font-bold mb-2">Reference Search:</label>
          <input
            type="text"
            placeholder="Search by name or reference code"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
          />
        </div>
        <div>
          <label htmlFor="pickupLocation" className="block font-bold mb-2">Date Range Search:</label>
          <DatePickerWithRange onChange={handleDateChange} />
        </div>
        <div>
          <label htmlFor="selectItem" className="block font-bold mb-2">Select an Item:</label>
          <div className="relative">
            <Select value={selectedRequirements} onValueChange={setSelectedRequirements}>
              <SelectTrigger>{selectedRequirements ? selectedRequirements : "Select an Item"}</SelectTrigger>
              <SelectContent>
                <SelectItem key="paramedic-shifts" value="paramedic-shifts">
                  Paramedic Shifts
                </SelectItem>
                <SelectItem key="return-journeys" value="return-journeys">
                  Return Journeys
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-[500px 1fr 500px 1fr] gap-4 mt-4">
          {[...Array(15)].map((_, index) => (
            <div key={index} className="overflow-hidden border border-gray-200 rounded-md animate-pulse">
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>DOB</TableHead>
                <TableHead>Reference</TableHead>
                <TableHead>Collection Time</TableHead>
                <TableHead>From</TableHead>
                <TableHead>To</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!loading ? filteredItems.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <div className="flex items-center">
                      <img
                        src={Logo}
                        className="h-8 w-8 rounded-full"
                        alt=""
                      />
                      <span>{item.patientDetails ? item.patientDetails.name : "N/A"}</span>
                    </div>
                  </TableCell>
                  <TableCell>{item.patientDetails ? item.patientDetails.dob : "N/A"}</TableCell>
                  <TableCell>{item.referenceCode}</TableCell>
                  <TableCell>{formatDateTime(item.journeyDetails.journeys[0].dateTimePickup, item.journeyDetails.journeys[0].timePickup)}</TableCell>
                  <TableCell>{item.journeyDetails.journeys[0].pickupLocation === "Other" ? item.journeyDetails.journeys[0].otherPickupLocation : item.journeyDetails.journeys[0].pickupLocation}</TableCell>
                  <TableCell>{item.journeyDetails.journeys[0].dropOffLocation === "Other" ? item.journeyDetails.journeys[0].otherDropOffLocation : item.journeyDetails.journeys[0].dropOffLocation}</TableCell>
                  <TableCell>
                    <JobStatus status={item.JobStatus} />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="default"
                      size="default"
                      className={buttonVariants({ variant: "default", size: "default" })}
                      onClick={() => handleViewClick(item)}>
                      View Job
                    </Button>
                  </TableCell>
                </TableRow>
              )) : 'Loading...'}
            </TableBody>
          </Table>
          <div className="py-4 flex justify-center">
            <Pagination
              className="py-4"
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={paginate}
            />
          </div>
        </>
      )}
    </div>
  )

}
export default JobList;
