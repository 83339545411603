import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Label } from '../Components/Global/UI/Label';
import { Button } from '../Components/Global/UI/Button';
import { MultiSelect } from '../Components/Global/UI/MultiSelect';
import { BASE_URL } from './Settings/DASHBOARD_SETTINGS';

const StaffList = () => {
    const [staffList, setStaffList] = useState([]);
    const [error, setError] = useState();
    const [selected, setSelected] = useState([]);

    const roleOptions = [
        { value: -1, display: "No Role" },
        { value: 0, display: "Admin" },
        { value: 1, display: "Driver" },
        { value: 2, display: "Paramedic" },
        { value: 3, display: "EMT" },
        { value: 4, display: "ACA" },
    ];

    const SetUserRoles = async (userId, roles) => {
        try {
            const response = await axios.post(`${BASE_URL}/api/staff/policies/staff/${userId}/update-roles`, {
                newRoles: roles,            
            });
    
            if (response.data.success) {
            } else {
                console.error('Failed to update user roles:', response.data.message);
            }
        } catch (error) {
            console.error('Error updating user roles:', error.message);
        }
    };

    useEffect(() => {
        const getStaffList = async () => {
            try {
                const response = await axios.get("${BASE_URL}/api/staff/policies/staff/getStaff");

                if (response.data.success) {
                    setStaffList(response.data.staffDetails);

                    const initialSelected = response.data.staffDetails.map((staff) => staff.role ? staff.role.flat() : []).flat();                    setSelected(initialSelected);
                } else {
                    setError("Something went wrong. Please try again.");
                }
            } catch (error) {
                console.error('Error fetching staff list:', error.message);
                setError("Something went wrong. Please try again.");
            }
        };

        getStaffList();
    }, []);

    const StaffStatus = ({ status }) => {
        let statusText, textColor, bgColor;

        switch (status) {
            case -1:
                statusText = 'Registered';
                textColor = 'text-blue-800';
                bgColor = 'bg-blue-200';
                break;
            case 0:
                statusText = 'Approved';
                textColor = 'text-green-800';
                bgColor = 'bg-green-200';
                break;
            case 1:
                statusText = 'Revoked';
                textColor = 'text-red-800';
                bgColor = 'bg-red-200';
                break;
            default:
                statusText = 'Unknown';
                textColor = 'text-gray-800';
                bgColor = 'bg-gray-200';
        }

        return (
            <div className={`px-2 py-1 rounded text-center mr-3 font-normal ${bgColor} ${textColor}`}>
                {statusText}
            </div>
        );
    };

    return (
        <div className="px-10 py-10">
            <h3 className="text-2xl font-semiBold">{`Staff List (${staffList.length})`}</h3>

            <div className="grid grid-cols-3 gap-4 mt-6">
                {staffList.map((staff) => (
                    <div className="border p-4 my-4 flex flex-col" >
                        <div className='flex flex-row items-center justify-between'>
                            <Label className="font-semibold">{staff.name}</Label>
                            <StaffStatus status={staff.status} />
                        </div>
                        <div>
                            <Label>Email : {staff.email}</Label>
                        </div>

                        <div className='gap-2 flex flex-col mt-4'>
                        <MultiSelect
                            options={roleOptions}
                            selected={selected}
                            onChange={setSelected}
                        />
                            <Button>
                                {staff.status === -1 ? 'Approve Staff' : 'Revoke Staff'}
                            </Button>
                            <Button onClick={() => SetUserRoles(staff.userId, selected)}>
                                Set Roles
                            </Button>
                        </div>
                    </div >
                ))}

            </div>
        </div>
    );
};

export default StaffList;
