import React, { useState } from 'react';
import { Label } from "../../../Global/UI/Label";
import { Input } from '../../../Global/UI/Input';

const StaffDetails = ({ onChange, data }) => {

    const [staffDetails, setStaffDetails] = useState({
        ACA: data.ACA || '',
        EMT: data.EMT || '',
        Paramedic: data.Paramedic || '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedDetails = {
            ...staffDetails,
            [name]: value,
        };

        setStaffDetails(updatedDetails);
        onChange(updatedDetails);
    };

    return (
        <div>
            <p className='font-bold text-2xl mb-4'>Staff Details</p>
            <div className='grid gap-4 grid-cols-1 md:grid-cols-3 mb-4'>
                    <div className='flex flex-col'>
                        <Label htmlFor="ACA" className="block font-bold mb-2">ACA:</Label>
                        <Input
                            value={staffDetails.ACA}
                            onChange={handleChange}
                            type="number"
                            id="ACA"
                            name="ACA"
                            className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                        />
                    </div>
                    <div className='flex flex-col'>
                        <Label htmlFor="EMT" className="block font-bold mb-2">EMT:</Label>
                        <Input
                            value={staffDetails.EMT}
                            onChange={handleChange}
                            type="number"
                            id="EMT"
                            name="EMT"
                            className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                        />
                    </div>
                    <div className='flex flex-col'>
                        <Label htmlFor="Paramedic" className="block font-bold mb-2">Paramedic:</Label>
                        <Input
                            value={staffDetails.Paramedic}
                            onChange={handleChange}
                            type="number"
                            id="Paramedic"
                            name="Paramedic"
                            className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                        />
                    </div>
                </div>
        </div>
    );
};

export default StaffDetails;
